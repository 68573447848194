export const sessionsTypes = {
  GET_SESSIONS: "sessions/GET_SESSIONS",
  GET_SESSIONS_SUCCESS: "sessions/GET_SESSIONS_SUCCESS",

  GET_SESSION: "sessions/GET_SESSION",
  GET_SESSION_SUCCESS: "sessions/GET_SESSION_SUCCESS",

  CHECK_ALLOWED_TO_ADD_SESSIOIN: "sessions/CHECK_ALLOWED_TO_ADD_SESSIOIN",

  CHECK_SESSION: "sessions/CHECK_SESSION",
  CREATE_SESSIONS: "sessions/CREATE_SESSIONS",
  EDIT_SESSION: "session/EDIT_SESSION",
  DELETE_SESSION: "session/DELETE_SESSION",

  GET_STUDENTS_FOR_SESSION: "sessions/GET_STUDENTS_FOR_SESSION",
  GET_INSTRUCTORS_FOR_SESSION: "sessions/GET_INSTRUCTORS_FOR_SESSION",
  GET_VEHICLES_FOR_SESSION: "sessions/GET_VEHICLES_FOR_SESSION",

  CONFIRM_SESSION: "sessions/CONFIRM_SESSION",

  UPDATE_SESSION_COMPLETED: 'session/UPDATE_SESSION_COMPLETED',

  SET_SESSION_FIELDS: 'session/SET_SESSION_FIELDS'
}

export const getSessions = (payload) => ({
  type: sessionsTypes.GET_SESSIONS,
  payload,
})

export const getSession = (payload) => ({
  type: sessionsTypes.GET_SESSION,
  payload,
})

export const editSession = (payload) => ({
  type: sessionsTypes.EDIT_SESSION,
  payload,
})

export const deleteSession = (payload) => ({
  type: sessionsTypes.DELETE_SESSION,
  payload,
})

export const getStudentsForSession = (payload) => ({
  type: sessionsTypes.GET_STUDENTS_FOR_SESSION,
  payload,
})

export const getInstructorsForSession = (payload) => ({
  type: sessionsTypes.GET_INSTRUCTORS_FOR_SESSION,
  payload,
})

export const getVehiclesForSession = (payload) => ({
  type: sessionsTypes.GET_VEHICLES_FOR_SESSION,
  payload,
})

export const checkAllowedToAddSession = (payload) => ({
  type: sessionsTypes.CHECK_ALLOWED_TO_ADD_SESSIOIN,
  ...payload,
})

export const checkSession = (payload) => ({
  type: sessionsTypes.CHECK_SESSION,
  payload,
})

export const createSessions = (payload) => ({
  type: sessionsTypes.CREATE_SESSIONS,
  payload,
})

export const confirmSession = (payload) => ({
  type: sessionsTypes.CONFIRM_SESSION,
  payload,
})

export const updateSessionCompleted = (payload) => ({
  type: sessionsTypes.UPDATE_SESSION_COMPLETED,
  ...payload,
})

export const setSessionFields = (payload) => ({
  type: sessionsTypes.SET_SESSION_FIELDS,
  payload,
})