import React, { useState, useEffect } from "react"
import { Input } from "@makedonski/admin-ui"
import "./styles.scss"

const TextEdit = ({ value = "", handleChange, children, placeholder }) => {
  const [showEdit, setShowEdit] = useState(false)
  const [data, setData] = useState(false)

  return (
    <div className="inputs-text-edit-container row">
      {showEdit ? (
        <>
          <Input.Text
            value={data || ""}
            onChange={({ target: { value } }) => setData(value)}
            placeholder={placeholder || ""}
          />
          <div className="row row-buttons">
            <div
              className={`icon icon-check-white ${!data && "disabled"} `}
              onClick={() => {
                if (!data) return
                if (handleChange) handleChange(data)
                setShowEdit(false)
                setData("")
              }}
            />
            <div
              className="icon icon-delete"
              onClick={() => {
                setShowEdit(false)
                setData("")
              }}
            />
          </div>
        </>
      ) : (
        <div
          className="children-container"
          onClick={() => setShowEdit(!showEdit)}
        >
          {children}
        </div>
      )}
    </div>
  )
}

export default TextEdit
