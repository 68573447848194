import React, { useState, useEffect, useRef } from 'react'
import Swal from 'sweetalert2'
import { Button, Input } from '@makedonski/admin-ui'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  startLoading,
  stopLoading,
  getCourses,
  getVehicles,
  createInstructor,
  updateInstructor,
  updateInstructorDetails,
  deleteInstructor,
} from '../../../actions'
import { uploadFile } from '../../../utilities'
import {translate} from "../../../translations/localization"
import './styles.scss'

const Form = ({ isCreate, setEditable, instructor }) => {
  const [data, setData] = useState(isCreate ? { phoneNumber: '+359' } : {})
  const [details, setDetails] = useState({})
  const handleChange = (field, value) => setData({ ...data, [field]: value })
  const handleDetailsChange = (field, value) => setDetails({ ...details, [field]: value })
  const handleSwitchesChange = (field, value) =>
    handleDetailsChange('qualifications', {
      ...instructor?.details?.qualifications,
      ...details?.qualifications,
      [field]: value,
    })

  const history = useHistory()

  const dispatch = useDispatch()
  const uploadBtnRef = useRef()
  const handleImage = (files) => {
    if (isEmpty(files)) return
    dispatch(startLoading())
    uploadFile(files[0]).then((data) => {
      handleChange('avatar', data?.location)
      dispatch(stopLoading())
    })
  }

  const {
    vehicles: { vehicles },
    courses: { courses },
  } = useSelector((state) => state)
  useEffect(() => {
    dispatch(getVehicles())
    dispatch(getCourses())
    return () => setEditable(false)
  }, [])

  const getSwitchValue = (_id) => details?.qualifications?.includes(_id)
  useEffect(() => {
    if (instructor?.details?.qualifications)
      handleDetailsChange(
        'qualifications',
        instructor?.details?.qualifications?.map(({ _id }) => _id)
      )
  }, [])

  const [showRequired, setShowRequired] = useState(false)
  //prettier-ignore
  const isRequred = (field) => showRequired && !data?.[field] && !details?.[field] && !instructor?.[field] && !instructor?.details?.[field] && "required"
  const isRequredPhoneNumber = () => {
    const temp = data?.phoneNumber || instructor?.phoneNumber
    return showRequired && (!temp?.includes('+359') || temp.length !== 13) && 'required'
  }
  //prettier-ignore
  const validate = () => {
    console.log(details);
    if (isEmpty(data) && isEmpty(details)) return false
    if (!isEmpty(data) && ["firstName", "lastName", "phoneNumber"].some((field) => !data?.[field] && !instructor?.[field])) return false
    if (!(data?.phoneNumber || instructor?.phoneNumber)?.includes('+359')) return false
    if ((data?.phoneNumber || instructor?.phoneNumber)?.length !== 13) return false
    if ((isCreate && isEmpty(details) )|| ["defaultCar"].some((field) => !details?.[field] && !instructor?.details?.[field])) return false
    return true
  }

  return (
    <div className="instructor-form-container col">
      <div className="instructor-form-main-container">
        <div className="row">
          {data?.avatar || instructor?.avatar ? (
            <div
              className="avatar-container-full row"
              style={{
                backgroundImage: `url(${
                  data?.avatar || instructor?.avatar || require('../../../assets/images/avatar.png').default
                })`,
              }}>
              <div className="icon icon-camera ripple" onClick={() => uploadBtnRef?.current?.click()} />
            </div>
          ) : (
            <Input.DragAndDrop handleDrop={(files) => handleImage(files)}>
              <div className="avatar-container row">
                <div className="icon icon-image ripple" onClick={() => uploadBtnRef?.current?.click()} />
              </div>
            </Input.DragAndDrop>
          )}
          <input
            type="file"
            ref={uploadBtnRef}
            className="btn-upload"
            onChange={({ target: { files } }) => handleImage(files)}
            style={{ display: 'none' }}
          />
          <div className="col col-big col-instructor">
            <div className="row">
              <p className="no-bg input-label">
                {translate("Instructor")} <span className="icon icon-required" />
              </p>
            </div>
            <div className="row">
              <Input.Text
                value={data?.firstName ?? instructor?.firstName ?? ''}
                onChange={({ target: { value } }) => handleChange('firstName', value)}
                inputClassName={`${isRequred('firstName')}`}
              />
              <Input.Text
                value={data?.lastName ?? instructor?.lastName ?? ''}
                onChange={({ target: { value } }) => handleChange('lastName', value)}
                inputClassName={`${isRequred('lastName')}`}
              />
            </div>
          </div>

          <div className="col">
            <p className="input-label">
              {translate("sessionCar").slice(0,-1)}
              <span className="icon icon-required" />
            </p>
            <Input.Dropdown
              options={vehicles?.map(({ _id, model, license }) => ({
                value: _id,
                label: `${model} - ${license}`,
              }))}
              value={details?.defaultCar || instructor?.details?.defaultCar?._id}
              onChange={({ value }) => handleDetailsChange('defaultCar', value)}
              className={`${isRequred('defaultCar')}`}
            />
          </div>
          <div className="col">
            <p className="input-label">
              {translate("WorkPhone")}
              <span className="icon icon-required" />
            </p>
            <Input.Text
              value={data?.phoneNumber ?? instructor?.phoneNumber ?? ''}
              onChange={({ target: { value } }) => {
                if (value.length < 4 || value.length > 13) return
                if (!/^[+]?$|(^[+]{1}[0-9]{1,12}$)/.test(value)) return
                handleChange('phoneNumber', value)
              }}
              inputClassName={`${isRequredPhoneNumber()}`}
            />
          </div>
          {/*<div className="col">
            <p>Личен телефон</p>
            <Input.Text
              value={data?.personalPhoneNumber ?? instructor?.personalPhoneNumber ?? ''}
              onChange={({ target: { value } }) => handleChange('personalPhoneNumber', value)}
            />
          </div>*/}
          <div className="col">
            <p>{translate("Email")}</p>
            <Input.Text
              value={data?.email ?? instructor?.email ?? ''}
              onChange={({ target: { value } }) => handleChange('email', value)}
            />
          </div>
          <div className="col">
            <p>{translate("formEmployeeOf")}</p>
            <Input.Datepicker
              minDate={moment().subtract(80, 'years').toDate()}
              maxDate={moment().add(1, 'years').toDate()}
              pickDate={
                details?.employeeFrom ||
                (instructor?.details?.employeeFrom && new Date(instructor?.details?.employeeFrom)) ||
                null
              }
              onChange={(value) => handleDetailsChange('employeeFrom', value)}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
        </div>
        <div className="row">
          {/*<div className="col col-big">
            <p>Добави като потребител в системата</p>
            <Button.Switch
              isOn={data?.isActiveUser !== undefined ? data?.isActiveUser : instructor?.isActiveUser}
              onChange={() => {
                const value = data?.isActiveUser !== undefined ? !data?.isActiveUser : !instructor?.isActiveUser
                handleChange('isActiveUser', value)
              }}
            />
          </div>*/}
          <div className="col">
            <p>{translate("formAllHolidays")}</p>
            <Input.Text
              value={details?.totalDaysOff ?? instructor?.details?.totalDaysOff ?? ''}
              onChange={({ target: { value } }) => {
                if (!/^[0-9]{0,3}$/.test(value)) return
                if (
                  (details?.takenDaysOff ?? instructor?.details?.takenDaysOff) &&
                  Number(value) > Number(details?.takenDaysOff ?? instructor?.details?.takenDaysOff)
                )
                  return
                handleDetailsChange('totalDaysOff', value)
              }}
            />
          </div>
          <div className="col">
            <p>{translate("formTakenHolidays")}</p>
            <Input.Text
              value={details?.takenDaysOff ?? instructor?.details?.takenDaysOff ?? ''}
              onChange={({ target: { value } }) => {
                if (!/^[0-9]{0,3}$/.test(value)) return
                if (
                  (details?.totalDaysOff ?? instructor?.details?.totalDaysOff) &&
                  Number(value) > Number(details?.totalDaysOff ?? instructor?.details?.totalDaysOff)
                )
                  return
                handleDetailsChange('takenDaysOff', value)
              }}
            />
          </div>
          <div className="col">
            <p>{translate("gross")}</p>
            <div className="price-input-container">
              <Input.Text
                value={details?.salaryGross ?? instructor?.details?.salaryGross ?? ''}
                onChange={({ target: { value } }) => {
                  if (!/^[0-9]*$/.test(value)) return
                  if (
                    (details?.salaryNet ?? instructor?.details?.salaryNet) &&
                    Number(value) > Number(details?.salaryNet ?? instructor?.details?.salaryNet)
                  )
                    return
                  handleDetailsChange('salaryGross', value)
                }}
              />
              <span>лв.</span>
            </div>
          </div>
          <div className="col">
            <p>{translate("net")}</p>
            <div className="price-input-container">
              <Input.Text
                value={details?.salaryNet ?? instructor?.details?.salaryNet ?? ''}
                onChange={({ target: { value } }) => {
                  if (!/^[0-9]*$/.test(value)) return
                  if (
                    (details?.salaryGross ?? instructor?.details?.salaryGross) &&
                    Number(value) > Number(details?.salaryGross ?? instructor?.details?.salaryGross)
                  )
                    return
                  handleDetailsChange('salaryNet', value)
                }}
              />
              <span>лв.</span>
            </div>
          </div>
        </div>
      </div>

      <div className="instructor-form-qualifications-container">
        <h2>{translate("Qualifications")}</h2>
        <div className="row row-qualifications">
          {courses?.map(({ _id, name }) => (
            <div className="qualification-container" key={`qualification-${_id}`}>
              <div className="row">
                <p>{name}</p>
                <Button.Switch
                  isOn={getSwitchValue(_id)}
                  onChange={() => {
                    if (getSwitchValue(_id))
                      handleDetailsChange(
                        'qualifications',
                        details?.qualifications?.filter((qualification) => qualification !== _id)
                      )
                    else handleDetailsChange('qualifications', [...(details?.qualifications || []), _id])
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="row">
        {isCreate && (
          <div className="col col-comment">
            <p>{translate("Note")}</p>
            <Input.Text
              value={data?.comment ?? ''}
              onChange={({ target: { value } }) => handleChange('comment', value)}
            />
          </div>
        )}
      </div>
      <div className="row row-buttons">
        {!isCreate && (
          <Button.Raised
            text="Изтрий"
            className="red"
            onClick={() => {
              Swal.fire({
                title: 'ИЗТРИВАНЕ НА ПОТРЕБИТЕЛ',
                text:
                  'Вие сте на път да изтриете този потренител от системата. Ако потвърдите действието, информацията му ще бъде премахната от системата,  но курсисте му ще бъдат запазени.',
                imageUrl: require('../../../assets/images/delete.png').default,
                imageWidth: 240,
                imageHeight: 240,
                imageAlt: 'Custom image',
                width: '50vw',
                showCancelButton: true,
                confirmButtonText: `ИЗТРИЙ`,
                cancelButtonText: `ОТКАЖИ`,
                reverseButtons: true,
                confirmButtonColor: '#ed1d5d',
                cancelButtonColor: '#7e7e7e',
              }).then(({ isConfirmed }) => {
                if (isConfirmed) {
                  dispatch(startLoading())
                  dispatch(
                    deleteInstructor({
                      data: instructor?._id,
                      onSuccess: () => {
                        Swal.fire({
                          title: 'ИЗТРИТ ПОТРЕБИТЕЛ',
                          text: `${instructor?.fullName} беше премахнат от системата и вече няма да има достъп.`,
                          imageUrl: require('../../../assets/images/deleted.png').default,
                          imageWidth: 200,
                          imageHeight: 200,
                          imageAlt: 'Custom image',
                          width: '50vw',
                          confirmButtonText: `ПРОДЪЛЖИ`,
                          confirmButtonColor: 'black',
                        })
                        history.push('/managment/instructors')
                      },
                    })
                  )
                }
              })
            }}
          />
        )}
        <Button.Raised
          text={isCreate ? translate("Add") : translate("Save")}
          className="blue"
          onClick={() => {
            if (!validate()) {
              setShowRequired(true)
              return
            }
            dispatch(startLoading())
            if (isCreate) {
              dispatch(createInstructor({ ...data, ...details }))
              return
            }
            if (!isEmpty(data))
              dispatch(
                updateInstructor({
                  data: {
                    _id: instructor?._id,
                    firstName: instructor?.firstName,
                    lastName: instructor?.lastName,
                    ...data,
                  },
                  onSuccess: () => !isCreate && setEditable(false),
                })
              )
            if (!isEmpty(details))
              dispatch(
                updateInstructorDetails({
                  data: { _id: instructor?._id, ...details },
                  onSuccess: () => !isCreate && setEditable(false),
                })
              )
          }}
        />
      </div>
    </div>
  )
}

export default Form
