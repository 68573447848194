import { ofType, ActionsObservable } from "redux-observable"
import { switchMap, catchError, debounceTime } from "rxjs/operators"
import { Api, Alerts } from "@makedonski/socourt-utilities"
import { sessionsTypes, stopLoading } from "../actions"
import { URL } from "../config/settings"
import { history } from "../config/store"

export const getSessions = (action$) =>
  action$.pipe(
    ofType(sessionsTypes.GET_SESSIONS),
    switchMap(({ payload }) =>
      Api.post(`${URL}/sessions/schedule`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: sessionsTypes.GET_SESSIONS_SUCCESS,
              payload: response.sessions,
            })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const getSession = (action$) =>
  action$.pipe(
    ofType(sessionsTypes.GET_SESSION),
    switchMap(({ payload }) =>
      Api.get(`${URL}/sessions/single/${payload}`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: sessionsTypes.GET_SESSION_SUCCESS,
              payload: response.sessions,
            })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const editSession = (action$) =>
  action$.pipe(
    ofType(sessionsTypes.EDIT_SESSION),
    switchMap(({ payload: { data, onSuccess } }) =>
      Api.put(`${URL}/sessions`, JSON.stringify(data)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            onSuccess && onSuccess()
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.complete()
          })
        )
      )
    )
  )

export const deleteSession = (action$) =>
  action$.pipe(
    ofType(sessionsTypes.DELETE_SESSION),
    switchMap(({ payload: { data, onSuccess } }) =>
      Api.delete(`${URL}/sessions`, JSON.stringify(data)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            onSuccess && onSuccess()
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const getStudentsForSession = (action$) =>
  action$.pipe(
    ofType(sessionsTypes.GET_STUDENTS_FOR_SESSION),
    debounceTime(200),
    switchMap(({ payload: { query, onSuccess } }) =>
      Api.post(
        `${URL}/students/search`,
        JSON.stringify({ search: query })
      ).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            onSuccess(response.students)
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.complete()
          })
        )
      )
    )
  )

export const getInstructorsForSession = (action$) =>
  action$.pipe(
    ofType(sessionsTypes.GET_INSTRUCTORS_FOR_SESSION),
    debounceTime(200),
    switchMap(({ payload: { query, onSuccess } }) =>
      Api.post(`${URL}/user/search`, JSON.stringify({ search: query })).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            onSuccess(response.instructors)
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.complete()
          })
        )
      )
    )
  )

export const getVehiclesForSession = (action$) =>
  action$.pipe(
    ofType(sessionsTypes.GET_VEHICLES_FOR_SESSION),
    debounceTime(200),
    switchMap(({ payload: { query, onSuccess } }) =>
      Api.post(`${URL}/cars/search`, JSON.stringify({ search: query })).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            onSuccess(response.schools)
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.complete()
          })
        )
      )
    )
  )

export const checkAllowedToAddSession = (action$) =>
  action$.pipe(
    ofType(sessionsTypes.CHECK_ALLOWED_TO_ADD_SESSIOIN),
    switchMap(({ onSuccess }) =>
      Api.get(`${URL}/sessions/allow`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            onSuccess && onSuccess(response?.data || {})
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.complete()
          })
        )
      )
    )
  )

export const checkSession = (action$) =>
  action$.pipe(
    ofType(sessionsTypes.CHECK_SESSION),
    switchMap(({ payload: { session, onSuccess, onFail } }) =>
      Api.post(
        `${URL}/sessions/check`,
        JSON.stringify({ sessions: [session] })
      ).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            onSuccess()
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            onFail()
            obs.complete()
          })
        )
      )
    )
  )

export const createSessions = (action$) =>
  action$.pipe(
    ofType(sessionsTypes.CREATE_SESSIONS),
    switchMap(({ payload }) =>
      Api.post(
        `${URL}/sessions/multi`,
        JSON.stringify({ sessions: payload })
      ).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            history.push("/")
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const confirmSession = (action$) =>
  action$.pipe(
    ofType(sessionsTypes.CONFIRM_SESSION),
    switchMap(({ payload: { data, onSuccess } }) =>
      Api.post(`${URL}/sessions/confirm`, JSON.stringify(data)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            onSuccess && onSuccess()
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )


export const updateSessionCompleted = (action$) => action$.pipe(
  ofType(sessionsTypes.UPDATE_SESSION_COMPLETED),
  switchMap(({ payload, onSuccess }) =>
    Api.put(`${URL}/sessions/completed`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        onSuccess && onSuccess()
        obs.next({ type: sessionsTypes.GET_SESSION, payload: payload._id })
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error("Грешка!", err?.response?.error?.message || "")
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)