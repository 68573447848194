import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { uniqBy } from 'lodash'
import { translate } from '../../../translations/localization'
import './styles.scss'

const Profile = ({ instructor, showDocuments, setEditable }) => {
  const { currentUser } = useSelector((state) => state.general)
  const history = useHistory()
  return (
    <div className="instructor-profile-container col">
      <div className="instructor-profile-main-container display">
        <div className="row">
          <div
            className="avatar-container-full row"
            style={{
              backgroundImage: `url(${instructor?.avatar || require('../../../assets/images/avatar.png').default})`,
            }}
          />
          <div className="col col-instructor">
            <p className="no-bg">{translate("Instructor")}</p>
            <p className="header">{instructor?.fullName}</p>
          </div>

          <div className="row row-buttons">
            <div className="icon icon-details" onClick={() => showDocuments(true)} />
            {currentUser?.role?.map(({ permissions }) => permissions)?.includes('admin') && (
              <div className="icon icon-edit" onClick={() => setEditable(true)} />
            )}
          </div>
        </div>
        <div className="row row-info">
          <p>
            МПС:{' '}
            <span>
              {instructor?.details?.defaultCar?.model} - {instructor?.details?.defaultCar?.license}
            </span>
          </p>
          {/*<p>
            ОС: <span>{instructor?.os ?? '—'}</span>
          </p>*/}
          <p>
            {translate("workPhone")}: <span>{instructor?.phoneNumber ?? '—'}</span>
          </p>
          {/*<p>
            Личен: <span>{'—'}</span>
          </p>*/}
          <p>
            {translate("Email")}: <span>{instructor?.email ?? '—'}</span>
          </p>
          <p>
            {translate("User")}: <span>{'—'}</span>
          </p>
          <p>
            {translate("formEmployeeOf")}:{' '}
            <span>
              {instructor?.details?.employeeFrom ? moment(instructor?.details?.employeeFrom).format('DD.MM.YYYY') : '—'}
            </span>
          </p>
          <p>
            {translate("salary")}:{' '}
            <span>
              {instructor?.details?.salaryGross ?? '—'}/{instructor?.details?.salaryNet ?? '—'} лв.
            </span>
          </p>
          <p>
            {translate("formTakenHolidays")}:{' '}
            <span>
              {instructor?.details?.takenDaysOff ?? '—'}/{instructor?.details?.totalDaysOff ?? '—'} дни
            </span>
          </p>
          <p>
            {translate("formLeftHolidays")}:{' '}
            <span>
              {(instructor?.details?.totalDaysOff ?? 0) - (instructor?.details?.takenDaysOff ?? 0) || '—'} дни
            </span>
          </p>
        </div>
      </div>
      <div className="instructor-profile-activity-container display">
        <div className="row">
          <h2>{translate("Activity")}</h2>
        </div>
        <div className="row">
          <p className='row row-active-students' onClick={() => history.push(`/managment/students?searchBy=instructorPractice&search=${instructor.fullName}`)}>
            {translate("ActiveStudents")}:{' '}
            <span>
              {
                uniqBy([...(instructor?.students || []), ...(instructor?.studentsTheory || [])], '_id').filter(
                  ({ status }) => status !== 'finished'
                )?.length
              }
            </span>
            <span className='icon icon-arrow-right'></span>
          </p>
          <p>
            {translate("ActigGroups")}: <span>{instructor?.groups?.filter(({ end }) => moment(end).isAfter(moment())).length}</span>
          </p>
        </div>
      </div>
      <div className="instructor-profile-qualifications-container display">
        <h2>{translate("Qualifications")}</h2>
        <div className="row row-qualifications">
          {instructor.details?.qualifications?.map(({ name, _id }, i) => (
            <div className="qualification-container" key={`qualification-${_id}`}>
              <div className="row">
                <p>{name}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Profile
