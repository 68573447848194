import { default as InstructorsFilter } from "./InstructorsFilter"
import { default as Documents } from "./Documents"
import { default as Settings } from "./Settings"
import { default as Notifications } from "./Notifications"
import { default as InstructorEdit } from "./InstructorEdit"
import { default as Overlay } from "./Overlay"
import { default as InstructorsAdd } from "./InstructorsAdd"

const Popup = {
  InstructorsFilter,
  Documents,
  Settings,
  Notifications,
  InstructorEdit,
  Overlay,
  InstructorsAdd,
}

export default Popup
