import React from 'react'
import { Section } from '@makedonski/admin-ui'
import { Auth } from '@makedonski/socourt-utilities'
import { withRouter, Route, Switch, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  Login,
  Home,
  Session,
  SessionCreate,
  Managment,
  Student,
  Instructor,
  School,
  SchoolInvite,
  Settings,
  Group,
  Vehicle,
  Firm,
  Courses,
  References,
  SchoolEdit,
  Balance,
  BalancePayment,
  Training,
  Support
} from '../../screens'
import { Header } from '..'

const AuthRoute = ({ children, ...rest }) => {
  return (
    <Route
      render={({ location }) =>
        !Auth.isAuthenticated ? <Route {...rest} /> : <Redirect to={{ pathname: '/', state: { from: location } }} />
      }
    />
  )
}

const PrivateRoute = ({ children, ...rest }) => {
  return (
    <Route
      render={({ location }) =>
        Auth.isAuthenticated ? <Route {...rest} /> : <Redirect to={{ pathname: '/login', state: { from: location } }} />
      }
    />
  )
}

const AdminRoute = ({ children, ...rest }) => {
  const { currentUser } = useSelector((state) => state.general)
  return (
    <Route
      render={({ location }) =>
        currentUser?.role?.map(({ permissions }) => permissions)?.includes('admin') ? (
          <Route {...rest} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: location } }} />
        )
      }
    />
  )
}

const Routes = () => {
  const { loading, loadingText } = useSelector((state) => state.general)
  return (
    <>
      {Auth.isAuthenticated && <Header />}
      <Switch>
        <AuthRoute exact path="/login" component={Login} />
        <AuthRoute exact path="/register" component={Login} />
        <PrivateRoute exact path="/" component={Home} />
        <AdminRoute exact path="/session_create" component={SessionCreate} />
        <AdminRoute exact path="/session_:id/edit" component={SessionCreate} />
        <PrivateRoute exact path="/session_:id" component={Session} />
        <PrivateRoute exact path="/managment" component={Managment} />
        <PrivateRoute exact path="/managment/:type" component={Managment} />
        <PrivateRoute exact path="/student/:id" component={Student} />
        <PrivateRoute exact path="/instructor/:id" component={Instructor} />
        <PrivateRoute exact path="/group/:id" component={Group} />
        <PrivateRoute exact path="/vehicle/:id" component={Vehicle} />
        <PrivateRoute exact path="/firm/:id" component={Firm} />
        <PrivateRoute exact path="/training/:student/:id" component={Training} />
        <PrivateRoute exact path="/references" component={References} />
        <PrivateRoute exact path="/references/:type" component={References} />
        <PrivateRoute exact path="/references/:type/:subtype" component={References} />
        <PrivateRoute exact path="/support" component={Support} />
        <AdminRoute exact path="/school/invite" component={SchoolInvite} />
        <AdminRoute exact path="/school/courses" component={Courses} />
        <AdminRoute exact path="/school/edit" component={SchoolEdit} />
        <AdminRoute exact path="/school" component={School} />
        <AdminRoute exact path="/settings" component={Settings} />
        <AdminRoute exact path="/balance" component={Balance} />
        <AdminRoute exact path="/balance/payment" component={BalancePayment} />
      </Switch>
      <Section.Loader loading={loading} text={loadingText} />
    </>
  )
}
export default withRouter(Routes)
