import { vehiclesTypes } from "../actions"

const initialState = {
  vehicles: [],
  vehicle: {},
}

const vehicles = (state = initialState, { type, payload }) => {
  switch (type) {
    case vehiclesTypes.GET_VEHICLES_SUCCESS:
      return { ...state, vehicles: payload }
    case vehiclesTypes.GET_VEHICLE_SUCCESS:
      return { ...state, vehicle: payload }
    default:
      return state
  }
}

export default vehicles
