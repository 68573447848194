import React from "react"
import { isString } from "lodash"
import "./styles.scss"

const RadioButtons = ({
  buttons,
  value,
  onClick,
  disabled,
  col,
  reversed,
  hideLabels,
}) => {
  return (
    <div
      className={`radio-buttons-container ${col ? "col" : "row"} ${
        disabled && "disabled"
      }`}
    >
      {buttons?.map((button, i) => (
        <div
          key={`single-radio-button-${i}`}
          className={`single-radio-button row ${
            value === (button?.value ?? button) && "selected"
          } ${reversed && "reversed"}`}
          onClick={() => {
            if (disabled || !onClick || value === (button?.value || button))
              return
            onClick(button?.value || button)
          }}
        >
          <div
            className="radio-button-outer"
            style={{
              borderColor: button?.color,
            }}
          >
            <div
              className={`radio-button-inner `}
              style={{
                backgroundColor: value === button.value && button?.color,
              }}
            />
          </div>
          <div className="label-container row">
            {button?.icon && (
              <div
                className="button-image"
                style={{ backgroundImage: button?.icon }}
              />
            )}
            {!hideLabels && (
              <p>
                {isString(button) ? button : button?.label || button?.value}
              </p>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

export default RadioButtons
