import React, { useState, useEffect } from "react"
import { Button, Input } from "@makedonski/admin-ui"
import { isEmpty } from "lodash"
import { Inputs } from "../../"
import { translate } from "../../../translations/localization"
import "./styles.scss"

const InstructorsFilter = ({
  hide,
  handleApply,
  option: optionProp = 'all',
  selected: selectedProp,
  instructors: optionsProp,
}) => {
  const [option, setOption] = useState(optionProp)
  const [query, setQuery] = useState("")
  const [selected, setSelected] = useState(selectedProp)
  const options = optionsProp?.filter(({ fullName }) =>
    fullName?.toLowerCase().includes(query?.toLowerCase())
  )

  useEffect(() => {
    setSelected(selectedProp)
    setOption(optionProp)
  }, [selectedProp])


  return (
    <div className="instructors-filter-popup-container col">
      <div className="instructors-filter-popup-header row">
        <h2>{translate("Instructors")}</h2>
        <Button.Icon name="plus" onClick={() => hide()} size="20px" />
      </div>
      <div className="instructors-filter-popup-content col">
        <h2>Сесии</h2>
        <Inputs.RadioButtons
          buttons={[
            { label: 'Всички', value: 'all', color: "#0bd8de" },
            { label: 'Предстоящи', value: 'upcomming', color: "#0bd8de" },
            { label: 'Изминали', value: 'expired', color: "#0bd8de" },
            { label: 'С мобилно приложение', value: 'withApp', color: "#0bd8de" },
            { label: 'Без мобилно приложение', value: 'withoutApp', color: "#0bd8de" },
          ]}
          value={option}
          onClick={(value) => setOption(value)}
          col
        />
      </div>

      <div className="instructors-filter-popup-content col">
        <h2>Инструктори</h2>
        <Input.Text
          placeholder="Търси"
          value={query || ""}
          onChange={({ target: { value } }) => setQuery(value)}
        />
        <div className="scroll-container">
          {!isEmpty(options) && (
            <Inputs.CheckBoxes
              buttons={[
                { label: "Избери всички", value: options?.length || 0 },
              ]}
              value={[selected?.length]}
              onClick={() => {
                if (isEmpty(selected)) setSelected(options.map(({ _id }) => _id))
                else setSelected(null)
              }}
              reversed
            />
          )}
          <Inputs.CheckBoxes
            buttons={options
              ?.map(({ _id, fullName }) => ({
                value: _id,
                label: fullName,
              }))
              ?.sort((a, b) => (a.label < b.label ? -1 : 1))
              ?.sort(({ value }) => (selected?.includes(value) ? -1 : 1))}
            value={selected}
            onClick={(value) => setSelected(value)}
            col
            reversed
          />
        </div>
      </div>
      <div className="instructors–filter-popup-footer row">
        <p
          className="clear"
          onClick={() => {
            setSelected(null)
            setOption(null)
            setQuery("")
          }}
        >
          {translate("clearFilters")}
        </p>
        <Button.Raised
          text="Задай"
          className="btn-apply"
          onClick={() => {
            handleApply && handleApply({
              selected: selected === null ? undefined : selected,
              option: option === "all" ? undefined : option
            })
            hide()
          }}
        />
      </div>
    </div>
  )
}

export default InstructorsFilter
