export const vehiclesTypes = {
  GET_VEHICLES: "vehicles/GET_VEHICLES",
  GET_VEHICLES_SUCCESS: "vehicles/GET_VEHICLES_SUCCESS",

  GET_VEHICLE: "vehicles/GET_VEHICLE",
  GET_VEHICLE_SUCCESS: "vehicles/GET_VEHICLE_SUCCESS",

  CREATE_VEHICLE: "vehicles/CREATE_VEHICLE",
  UPDATE_VEHICLE: "vehicles/UPDATE_VEHICLE",
  DELETE_VEHICLE: "vehicles/DELETE_VEHICLE",
}

export const getVehicles = (payload) => ({
  type: vehiclesTypes.GET_VEHICLES,
  payload,
})

export const getVehicle = (payload) => ({
  type: vehiclesTypes.GET_VEHICLE,
  payload,
})

export const createVehicle = (payload) => ({
  type: vehiclesTypes.CREATE_VEHICLE,
  payload,
})

export const updateVehicle = (payload) => ({
  type: vehiclesTypes.UPDATE_VEHICLE,
  payload,
})

export const deleteVehicle = (payload) => ({
  type: vehiclesTypes.DELETE_VEHICLE,
  payload,
})

export const clearVehicles = (payload) => ({
  type: vehiclesTypes.GET_VEHICLE_SUCCESS,
  payload: {},
})
