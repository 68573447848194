export const coursesTypes = {
  GET_COURSES: "courses/GET_COURSES",
  GET_COURSES_SUCCESS: "courses/GET_COURSES_SUCCESS",

  GET_COURSES_TYPES: "courses/GET_COURSES_TYPES",
  GET_COURSES_TYPES_SUCCESS: "courses/GET_COURSES_TYPES_SUCCESS",

  CREATE_COURSE: "courses/CREATE_COURSE",

  UPDATE_COURSES: "courses/UPDATE_COURSES",

  CREATE_PERMIT: "courses/CREATE_PERMIT",
  UPDATE_PERMIT: "courses/UPDATE_PERMIT",
  DELETE_PERMIT: "courses/DELETE_PERMIT",
}

export const getCourses = (payload) => ({
  type: coursesTypes.GET_COURSES,
  payload,
})

export const getCoursesTypes = (payload) => ({
  type: coursesTypes.GET_COURSES_TYPES,
  payload,
})

export const createCourse = (payload) => ({
  type: coursesTypes.CREATE_COURSE,
  payload,
})

export const updateCourses = (payload) => ({
  type: coursesTypes.UPDATE_COURSES,
  payload,
})

export const createPermit = (payload) => ({
  type: coursesTypes.CREATE_PERMIT,
  payload,
})

export const updatePermit = (payload) => ({
  type: coursesTypes.UPDATE_PERMIT,
  payload,
})

export const deletePermit = (payload) => ({
  type: coursesTypes.DELETE_PERMIT,
  payload,
})
