import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { isEmpty } from "lodash"
import { getTransactions } from "../../actions"
import { Transaction } from "../../components"
import { translate } from "../../translations/localization"
import "./styles.scss"

const Balance = ({}) => {
  const {
    general: { currentUser },
    balance: { transactions },
  } = useSelector((state) => state)

  const history = useHistory()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getTransactions())
  }, [])

  return (
    <div className="screen-balance-container row">
      <div className="screen-balance-inner-container col">
        <div className="screen-balance-header row">
          <div className="col col-text">
            <h2>{translate("balanceHeader")}</h2>
            <p>
              {translate("balanceFollowTokens")}
            </p>
          </div>
          <div className="col col-buy">
            <div className="row row-credits">
              <p>{translate("balanceLeft")}</p>
              <p className="row">
                {currentUser?.drivingSchool?.credits} {translate("balanceCredits")}{" "}
                <span
                  style={{ display: "block" }}
                  className="icon icon-coins"
                />
              </p>
            </div>
            <div
              className="row row-buy"
              onClick={() => history.push("/balance/payment")}
            >
              <p>{translate("balanceBuyCredits")}</p>
            </div>
          </div>
        </div>
        <div className="screen-balance-content">
          <h2>{translate("balanceHistory")}</h2>
          {isEmpty(transactions) ? (
            <div className="empty-state col">
              <div className="image" />
              <p>
                {translate("balanceHistoryInnerText")}
              </p>
            </div>
          ) : (
            <div className="scroll-container">
              {transactions?.map((t) => (
                <Transaction
                  key={`transaction-${t}`}
                  transaction={t}
                  school={currentUser?.drivingSchool?.school?.name}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Balance
