import React, { useMemo } from "react"
import { uniqBy } from 'lodash'
import { managmentFields } from 'config/constants'
import { Shared } from 'components'
import * as renderField from 'screens/Managment/renderField'
import * as sortingFunction from 'screens/Managment/sortingFunction'
import "./styles.scss"

const ActiveStudents = ({ instructor }) => {
    const { students, studentsTheory } = instructor || {}
    const activeStudents = useMemo(() => {
        return uniqBy([...(students?.map(({ student }) => student) || []), ...(studentsTheory?.map(({ student }) => student) || [])], '_id').filter(({ status }) => status !== 'finished')
    }, [instructor])
    console.log(activeStudents);

    const props = {
        className: 'students',
        fields: managmentFields['students'],
        renderField: renderField['students'],
        sortingFunction: sortingFunction['students'],
    }


    return <div className="instructor-active-students-container">
        <h2>Курсисти към инструктор</h2>
        <Shared.List {...props} data={activeStudents} />
    </div>
}

export default ActiveStudents