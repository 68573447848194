export const schoolTypes = {
  GET_SCHOOL: "school/GET_SCHOOL",
  GET_SCHOOL_SUCCESS: "school/GET_SCHOOL_SUCCESS",

  UPDATE_SCHOOL: "school/UPDATE_SCHOOL",
}

export const getSchool = (payload) => ({
  type: schoolTypes.GET_SCHOOL,
  payload,
})

export const updateSchool = (payload) => ({
  type: schoolTypes.UPDATE_SCHOOL,
  payload,
})
