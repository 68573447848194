import React, { useState, useEffect, useMemo } from 'react'
import Tippy from '@tippyjs/react/headless'
import { Button, Input } from '@makedonski/admin-ui'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  getVehicle,
  clearVehicles,
  createVehicle,
  updateVehicle,
  deleteVehicle,
  getInstructors,
  startLoading,
} from '../../actions'
import { Popup } from '../../components'
import { translate } from '../../translations/localization'
import './styles.scss'

const Vehicle = ({}) => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const {
    vehicles: { vehicle },
    instructors: { instructors },
  } = useSelector((state) => state)

  const [data, setData] = useState({})
  const handleChange = (field, value) => setData({ ...data, [field]: value })
  useEffect(() => {
    dispatch(getInstructors())
    id !== 'create' && dispatch(getVehicle(id))
    return () => dispatch(clearVehicles())
  }, [])

  const selectedInstructors = useMemo(
    () =>
      data?.instructors ? data?.instructors : vehicle?.instructors?.filter(({ user }) => user)?.map(({ _id }) => _id),
    [data.instructors, vehicle]
  )

  const [showRequired, setShowRequired] = useState(false)
  //prettier-ignore
  const isRequred = (field) => showRequired && !data?.[field] && !vehicle?.[field] && "required"
  //prettier-ignore
  const validate = () => !["model", "license", "makeYear"].some((field) => !data?.[field] && !vehicle?.[field])

  const [showPopup, setShowPopup] = useState(false)

  return (
    <div className="screen-vehicle-container">
      <h2>{translate("vehicleMPS")}</h2>
      <div className="row">
        <div className="col">
          <span className="input-label">
          {translate("vehichleModel")}
            <span className="icon icon-required" />
          </span>
          <Input.Text
            value={data?.model ?? vehicle?.model ?? ''}
            onChange={({ target: { value } }) => handleChange('model', value)}
            inputClassName={`${isRequred('model')}`}
          />
        </div>
        <div className="col">
          <span className="input-label">
          {translate("vehicleNumber")} <span className="icon icon-required" />
          </span>
          <Input.Text
            value={data?.license ?? vehicle?.license ?? ''}
            onChange={({ target: { value } }) => handleChange('license', value)}
            inputClassName={`${isRequred('license')}`}
          />
        </div>
        <div className="col">
          <span className="input-label">
          {translate("vehicleYear")} <span className="icon icon-required" />
          </span>
          <Input.Text
            value={data?.makeYear ?? vehicle?.makeYear ?? ''}
            onChange={({ target: { value } }) => handleChange('makeYear', value)}
            inputClassName={`${isRequred('makeYear')}`}
          />
        </div>
      </div>
      <div className="row">
        <div className="col col-big">
          <div className="row row-no-margin">
            <div className="col">
              <span>{translate("Instructors")}</span>
              <Tippy
                placement="auto-end"
                interactive
                animation
                onHide={(instance) => requestAnimationFrame(instance.unmount)}
                visible={showPopup}
                onClickOutside={() => setShowPopup(false)}
                render={(attrs) => (
                  <Popup.InstructorsAdd
                    {...attrs}
                    hide={() => setShowPopup(false)}
                    handleApply={(value) => handleChange('instructors', value)}
                    selected={selectedInstructors}
                    options={instructors
                      ?.filter(({ details }) => details?._id)
                      ?.map(({ details, fullName }) => ({
                        _id: details?._id,
                        fullName: fullName,
                      }))}
                  />
                )}>
                <span tabIndex="0">
                  <Button.Raised text={translate("Add")} className="blue" onClick={() => setShowPopup(!showPopup)} />
                </span>
              </Tippy>
            </div>
            <div className="row row-instructors row-no-margin">
              {selectedInstructors?.map((_id, i) => {
                return (
                  <div key={`instructor-${_id}`} className="single-instructor row row-no-margin">
                    <Button.Icon
                      name="plus"
                      color="#ed1d5d"
                      onClick={() => {
                        const newInstructors = selectedInstructors?.slice()
                        newInstructors.splice(i, 1)
                        handleChange('instructors', newInstructors)
                      }}
                    />
                    <p>{instructors?.find((inst) => inst.details?._id === _id)?.fullName}</p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className="row row-no-margin row-buttons">
          <>
            {id !== 'create' && (
              <Button.Raised
                text="Изтрий"
                className="red"
                onClick={() => {
                  dispatch(startLoading())
                  dispatch(deleteVehicle({ _id: id }))
                }}
              />
            )}
            <Button.Raised
              text={id === 'create' ? 'Добави' : 'Запази'}
              className="blue"
              onClick={() => {
                if (!validate()) {
                  setShowRequired(true)
                  return
                }
                dispatch(startLoading())
                if (id === 'create') dispatch(createVehicle({ instructors: [], ...data }))
                else dispatch(updateVehicle({ _id: id, ...data }))
              }}
            />
          </>
        </div>
      </div>
    </div>
  )
}

export default Vehicle
