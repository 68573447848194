import { ofType, ActionsObservable } from "redux-observable"
import { switchMap, catchError, debounceTime } from "rxjs/operators"
import { Api, Alerts } from "@makedonski/socourt-utilities"
import { coursesTypes, stopLoading } from "../actions"
import { URL } from "../config/settings"
import { history } from "../config/store"

export const getCourses = (action$) =>
  action$.pipe(
    ofType(coursesTypes.GET_COURSES),
    switchMap(({ payload }) =>
      Api.get(`${URL}/courses`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: coursesTypes.GET_COURSES_SUCCESS,
              payload: response.courses,
            })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.complete()
          })
        )
      )
    )
  )

export const getCoursesTypes = (action$) =>
  action$.pipe(
    ofType(coursesTypes.GET_COURSES_TYPES),
    switchMap(({ payload }) =>
      Api.get(`${URL}/courses/types`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: coursesTypes.GET_COURSES_TYPES_SUCCESS,
              payload: response.types,
            })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.complete()
          })
        )
      )
    )
  )

export const createCourse = (action$) =>
  action$.pipe(
    ofType(coursesTypes.CREATE_COURSE),
    switchMap(({ payload }) =>
      Api.post(`${URL}/courses/create-type`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({ type: coursesTypes.GET_COURSES_TYPES })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.complete()
          })
        )
      )
    )
  )

export const updateCourses = (action$) =>
  action$.pipe(
    ofType(coursesTypes.UPDATE_COURSES),
    switchMap(({ payload }) =>
      Api.post(`${URL}/courses`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            history.push("/school")
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.complete()
          })
        )
      )
    )
  )

export const createPermit = (action$) =>
  action$.pipe(
    ofType(coursesTypes.CREATE_PERMIT),
    switchMap(({ payload }) =>
      Api.post(`${URL}/courses/permits`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({ type: coursesTypes.GET_COURSES })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.complete()
          })
        )
      )
    )
  )

export const updatePermit = (action$) =>
  action$.pipe(
    ofType(coursesTypes.UPDATE_PERMIT),
    switchMap(({ payload }) =>
      Api.put(`${URL}/courses/permits`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({ type: coursesTypes.GET_COURSES })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.complete()
          })
        )
      )
    )
  )

export const deletePermit = (action$) =>
  action$.pipe(
    ofType(coursesTypes.DELETE_PERMIT),
    switchMap(({ payload }) =>
      Api.delete(`${URL}/courses/permits`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({ type: coursesTypes.GET_COURSES })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.complete()
          })
        )
      )
    )
  )
