import React, { useState, useEffect, useMemo, useRef } from "react"
import moment from "moment"
import { useHistory } from "react-router-dom"
import { isEmpty, times } from "lodash"
import { scale } from "../../../utilities"
import { handleMissedSession } from "../handleMissedSession"
import { translate } from "../../../translations/localization"
import { sessionTypes } from "../../../config/constants";
import "./styles.scss"

const Daily = ({ sessions, date = new Date(), setDate, fetchData }) => {
  const history = useHistory()
  const widthRef = useRef()
  const [containerWidth, setContainerWidth] = useState(0)
  useEffect(() => {
    if (widthRef.current) setContainerWidth(widthRef.current.scrollWidth)
  }, [widthRef])

  const getCurrentTime = () =>
    moment().hours() * 90 + scale(moment().minutes(), 0, 59, 0, 90) + 50
  const [currentTime, setCurrentTime] = useState(getCurrentTime())

  useEffect(() => {
    const currentTimeInterval = setInterval(() => {
      setCurrentTime(getCurrentTime())
    }, 1000)
    return () => {
      clearInterval(currentTimeInterval)
    }
  }, [])

  const currentTimeRef = useRef()
  useEffect(() => {
    if (currentTimeRef?.current)
      currentTimeRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      })
  }, [currentTimeRef.current])

  const mapTypes = useMemo(() => {
    return { ...sessionTypes.reduce((acc, { value, label }) => ({ ...acc, [value]: label }), {}), initial: '', }
  }, [])

  return (
    <div className="calendar-content-daily-view">
      <div
        className="current-time"
        ref={currentTimeRef}
        style={{
          top: currentTime,
          backgroundColor: isEmpty(sessions) ? "transparent" : "#0bd8de",
        }}
      />
      <div className="calendar-navigation">
        <div className="cell cell-arrows row">
          <div
            className="icon icon-arrow-left"
            onClick={() => setDate(moment(date).subtract(1, "days").toDate())}
          />
          <div
            className="icon icon-arrow-right"
            onClick={() => setDate(moment(date).add(1, "days").toDate())}
          />
        </div>
        {times(24).map((i) => (
          <div key={`cell-date-${i}`} className={`cell col cell-date`}>
            {i !== 0 && (
              <p style={{ fontWeight: i === moment().hours() ? "700" : "400" }}>
                {i < 10 ? `0${i}` : i}:00
              </p>
            )}
          </div>
        ))}
      </div>
      {isEmpty(sessions) ? (
        <div className="empty-state col" style={{ top: currentTime }}>
          <div className="image" />
          <p>{translate("dailyNoInfo")}</p>
        </div>
      ) : (
        <div className="scroll-container row" ref={widthRef}>
          {Object?.keys(sessions).map((instructor, i) => (
            <div className="col" key={`instructor-${i}`}>
              <div className=" cell-instructor row">
                <p>{instructor}</p>
                <div
                  className="icon icon-profile"
                  onClick={() => history.push(`/instructor/${sessions[instructor][0]?.user?._id}`)}
                />
              </div>
              {times(24).map((i) => (
                <div key={`cell-day-${i}`} className="cell cell-day">
                  {sessions[instructor].filter(({ start, startedAt }) => moment(startedAt || start).isSame(moment(date).startOf("day").add(i, "hours"), "hour"))
                    .map(({ _id, student, start, startedAt, end, endedAt, user, status, training, type }) => (
                      <div
                        key={`cell-session-${_id}`}
                        className={`cell cell-session ${moment(endedAt || end).isBefore(moment())
                          ? status === "pending" ? "expired missed" : "expired"
                          : moment(startedAt || start).isAfter(moment()) ? "upcomming" : "current"
                          } ${Math.abs(moment(startedAt || start).diff(moment(endedAt || end)) / 60000) < 44 && "collapse"}`}
                        style={{
                          top: moment(startedAt || start).minutes() * 2,
                          height: Math.abs(moment(startedAt || start).diff(moment(endedAt || end || moment().add(2, "minutes"))) / 60000) * 1.5,
                        }}
                        onClick={() => moment(endedAt || end).isBefore(moment()) && status === "pending"
                          ? handleMissedSession(student, _id, endedAt || end, fetchData, training, type)
                          : history.push(`/session_${_id}`)
                        }
                      >
                        <div className="cell cell-session-inner row">
                          <div
                            className="image"
                            style={{ backgroundImage: `url(${student?.avatar || require("../../../assets/images/avatar.png").default})`, }}
                          />
                          <div className="text-container">
                            <p className="bold type">{mapTypes[type]}</p>
                            <p className="bold">{student?.fullName}</p>
                            <p>Остават: {~~(training?.remainingMinutes / 50)} часа</p>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Daily
