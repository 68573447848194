import React from "react"
import { Calendar } from "../../components"
import "./styles.scss"

const Home = ({}) => {
  return (
    <div className="home-container">
      <div className="home-inner-container">
        <Calendar />
      </div>
    </div>
  )
}

export default Home
