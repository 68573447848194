export const trainingTypes = {
  GET_TRAINING: "training/GET_TRAINING",
  CREATE_TRAINING: "training/CREATE_TRAINING",
  UPDATE_TRAINING: "training/UPDATE_TRAINING",
  DELETE_TRAINING: "training/DELETE_TRAINING",
}

export const getTraining = (payload) => ({
  type: trainingTypes.GET_TRAINING,
  ...payload,
})

export const createTraining = (payload) => ({
  type: trainingTypes.CREATE_TRAINING,
  ...payload,
})

export const updateTraining = (payload) => ({
  type: trainingTypes.UPDATE_TRAINING,
  ...payload,
})

export const deleteTraining = (payload) => ({
  type: trainingTypes.DELETE_TRAINING,
  ...payload,
})
