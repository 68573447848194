import React, { useState, useEffect, useMemo, useRef } from 'react'
import Swal from 'sweetalert2'
// import Lottie from "lottie-react"
import { Input, Button } from '@makedonski/admin-ui'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import moment from 'moment'
// import withReactContent from "sweetalert2-react-content"
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import {
  getStudentsForSession,
  getInstructorsForSession,
  getVehiclesForSession,
  checkSession,
  createSessions,
  getSession,
  editSession,
  deleteSession,
  startLoading,
} from '../../actions'
import { isEmpty, isNumber, flatten, omit } from 'lodash'
import { combineDateAndTime } from '../../utilities'
import { translate } from '../../translations/localization'
import { sessionTypes } from '../../config/constants'
// import successAnimation from "../../assets/lottie/success.json"
import './styles.scss'

const SessionCreate = ({ }) => {
  const emptyQuery = { students: '', instructors: '', vehicles: '' }
  const [showError, setShowError] = useState(false)
  const [showNewSession, setShowNewSession] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [selectedTab, setSelectedTab] = useState('students')
  const [data, setData] = useState({})
  const [selectedData, setSelectedData] = useState({})
  const [sessions, setSessions] = useState([])
  const [query, setQuery] = useState(emptyQuery)

  const allowTabsActive = isEmpty(sessions) || showNewSession || showDelete

  const dispatch = useDispatch()
  let prevQuery = useRef({})
  //prettier-ignore
  useEffect(() => {
    [{ value: "students", handler: getStudentsForSession }, { value: "instructors", handler: getInstructorsForSession }, { value: "vehicles", handler: getVehiclesForSession }].forEach(({ value, handler }) => {
      if (prevQuery.current?.[value] !== query?.[value]) {
        dispatch(handler({
          query: query?.[value], onSuccess: (payload) => setData((data) => {
            if (value === 'students') return {
              ...data, [value]: flatten(
                payload?.reverse()?.map((student) =>
                  student?._trainings?.filter(({ status }) => status !== 'finished')?.map((training) => ({
                    ...student,
                    ...omit(training, ["_id", "__v", "updatedAt", "id", "createdAt"]),
                    training: training?._id
                  }))
                )
              )
            }
            if (value === 'instructors') return { ...data, [value]: payload?.filter(({ role }) => role.map(({ permissions }) => permissions).includes('instructor')) }
            else return ({ ...data, [value]: payload })
          })
        }))
        prevQuery.current[value] = query?.[value]
      }
    })
  }, [query])

  const renderTab = (el, type) => {
    switch (type) {
      case 'students':
        return (
          <>
            <p className="bold" style={{ position: 'relative' }}>
              {el.fullName} <span className="training">{el?.type?.name}</span>
            </p>
            <div className="col">
              <p>
                {translate("Taken")} <span>{el.totalHours - ~~(el.remainingMinutes / 50)}ч</span>
              </p>
              <p>
              {translate("Left")} <span>{~~(el.remainingMinutes / 50)}ч</span>
              </p>
            </div>
          </>
        )
      case 'instructors':
        return (
          <>
            <div className="col">
              <p className="bold">{el.fullName}</p>
              <p>
              {translate("sessionCar")}{' '}
                <span>
                  {el?.details?.defaultCar?.model} {el?.details?.defaultCar?.license}
                </span>
              </p>
            </div>
          </>
        )
      case 'vehicles':
        return (
          <>
            <p className="bold">
              {el?.model} | {el?.license}
            </p>
          </>
        )
      default:
        return null
    }
  }

  const handleDrag = (source, destination) => {
    if (destination?.droppableId !== 'session') return
    //prettier-ignore
    const newData = {
      students: data.students.filter(({ _id }) => _id !== selectedData?.students?._id),
      instructors: data.instructors.filter(({ _id }) => _id !== selectedData?.instructors?._id),
      vehicles: data.vehicles.filter(({ _id }) => _id !== selectedData?.vehicles?._id),
    }
    //prettier-ignore
    const vehicle = newData[source.droppableId][source.index]?.details?.defaultCar
    setSelectedData({
      ...selectedData,
      vehicles: vehicle,
      [source.droppableId]: newData[source.droppableId][source.index],
    })
    if (source.droppableId === 'students') setSelectedTab('instructors')
    if (source.droppableId === 'instructors')
      if (vehicle) setSelectedTab('')
      else setSelectedTab('vehicles')
    if (source.droppableId === 'vehicles') setSelectedTab('')
  }

  const handleAddAndEdit = () => {
    setSelectedData({})
    setShowNewSession(false)
    setShowDelete(false)
    setSelectedTab('')
  }

  const handleEdit = (session, i) => {
    setSelectedTab('students')
    setShowNewSession(true)
    setShowDelete(i)
    setSelectedData(session)
  }

  useEffect(() => {
    setShowError(false)
  }, [selectedData])

  useEffect(() => {
    setQuery(emptyQuery)
  }, [selectedTab])

  //prettier-ignore
  useEffect(() => {
    if (selectedData?.start && selectedData?.end) setSelectedData({ ...selectedData, start: combineDateAndTime(selectedData?.date, selectedData?.start), end: combineDateAndTime(selectedData?.date, selectedData?.end) })
    else if (selectedData?.start) setSelectedData({ ...selectedData, start: combineDateAndTime(selectedData?.date, selectedData?.start), })
    else if (selectedData?.end) setSelectedData({ ...selectedData, end: combineDateAndTime(selectedData?.date, selectedData?.end), })
  }, [selectedData?.date])

  const { id } = useParams()
  const history = useHistory()
  const { session } = useSelector((state) => state.sessions)
  useEffect(() => {
    if (id) {
      if (!session) dispatch(getSession(id))
      else {
        const length = moment.duration(moment(session?.end).diff(session?.start)).asMinutes() / 50
        const sessionData = {
          _id: session?._id,
          students: session?.student || {},
          instructors: session?.user || {},
          vehicles: session?.car || {},
          date: session?.date ? moment(session?.date).toDate() : null,
          start: session?.start ? moment(session?.start).toDate() : null,
          end: session?.end ? moment(session?.end).toDate() : null,
          type: session?.type || 'initial',
          length,
        }
        setShowNewSession(true)
        setSelectedTab('')
        setShowDelete(-1)
        setSelectedData(sessionData)
      }
    }
  }, [id, session])

  const mapTypes = useMemo(() => {
    return { ...sessionTypes.reduce((acc, { value, label }) => ({ ...acc, [value]: label }), {}), initial: '', }
  }, [])

  // const SuccessAlert = withReactContent(Swal)
  // SuccessAlert.fire({
  //   title: "УСПЕШНО РЕДАКТИРАН ЧАС",
  //   iconHtml: (
  //     <Lottie
  //       animationData={successAnimation}
  //       style={{ height: 200, width: 200 }}
  //       height={false}
  //       autoplay
  //     />
  //   ),
  //   width: "50vw",
  //   confirmButtonText: `ПРОДЪЛЖИ`,
  //   confirmButtonColor: `#0BD84F`,
  // })

  return (
    <div className="session-create-container row">
      <DragDropContext onDragEnd={(a) => handleDrag(a.source, a.destination)}>
        <div className="session-create-left-inner-container col">
          <h2>{translate("sessionCreateInput")}</h2>
          {[
            { label: translate("Students"), value: 'students' },
            { label: translate("Instructors"), value: 'instructors' },
            { label: translate("Cars"), value: 'vehicles' },
          ].map(({ label, value }) => (
            <div
              key={`tab-${value}`}
              className={`tab tab-${value} ${selectedTab === value && 'active'} ${!allowTabsActive && 'disabled'}`}>
              <h3 onClick={() => allowTabsActive && setSelectedTab(value)}>{label}</h3>
              <div className={`tab-inner tab-inner-${value}`}>
                <Input.Text
                  placeholder="Свободно търсене"
                  value={query[value] || ''}
                  onChange={(e) => setQuery({ ...query, [value]: e.target.value })}
                />
                <Droppable droppableId={value}>
                  {(provided) => (
                    <div className="scroll-container" {...provided.droppableProps} ref={provided.innerRef}>
                      {data[value]
                        ?.filter((obj) => obj?._id !== selectedData?.[value]?._id)
                        ?.map((el, i) => (
                          <Draggable key={`element-${i}`} draggableId={`single-element-${value}-${i}`} index={i}>
                            {(provided) => (
                              <div
                                className="element-container"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}>
                                <div className={`${value} row`}>
                                  <div
                                    className="image"
                                    style={{
                                      backgroundImage: `url(${el?.avatar || require('../../assets/images/avatar.png').default
                                        })`,
                                    }}
                                  />
                                  {renderTab(el, selectedTab)}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </div>
          ))}
        </div>
        <div className="session-create-right-inner-container col">
          <h2>{translate("sessionCreateGraphic")}</h2>
          <div className={`new-session ${!isEmpty(sessions) && !showNewSession && 'collapse'}`}>
            {isEmpty(sessions) || showNewSession ? (
              <>
                <p>{translate("sessionCreateNew")}</p>
                <Droppable droppableId="session">
                  {(provided) => (
                    <div className="dropple-container" {...provided.droppableProps} ref={provided.innerRef}>
                      {isEmpty(selectedData) && (
                        <h3>{translate("sessionCreateSwipe")}</h3>
                      )}
                      {selectedData?.students && (
                        <p className="selected">
                          {translate("sessionStudent")}{' '}
                          <span>
                            {selectedData?.students?.fullName} - {selectedData?.students?.type?.name}
                          </span>
                        </p>
                      )}
                      {selectedData?.instructors && (
                        <p className="selected">
                          {translate("sessionInstructor")} <span>{selectedData?.instructors?.fullName}</span>
                        </p>
                      )}
                      {selectedData?.vehicles && (
                        <p className="selected">
                          {translate("sessionCar")}{' '}
                          <span>
                            {selectedData?.vehicles?.model} - {selectedData?.vehicles?.license}
                          </span>
                        </p>
                      )}
                      {selectedData?.students && selectedData?.instructors && selectedData?.vehicles && (
                        <>
                          <Input.Datepicker
                            placeholder="Дата:"
                            pickDate={selectedData.date}
                            minDate={new Date()}
                            onChange={(date) => setSelectedData({ ...selectedData, date })}
                          />
                          <div className="row">
                            <Input.TimePicker
                              className={`${showError ? 'error' : ''}`}
                              placeholder="От:"
                              pickDate={selectedData.start}
                              onChange={(start) => {
                                if (selectedData.length) {
                                  const end = moment(start)
                                    .add(selectedData.length * 50, 'minutes')
                                    .toDate()
                                  setSelectedData({
                                    ...selectedData,
                                    start: combineDateAndTime(selectedData?.date, start),
                                    end: combineDateAndTime(selectedData?.date, end),
                                  })
                                } else {
                                  setSelectedData({
                                    ...selectedData,
                                    start: combineDateAndTime(selectedData?.date, start),
                                  })
                                }
                              }}
                            />
                            <div className="input-length">
                              <Input.Text
                                inputClassName={`${showError ? 'error' : ''}`}
                                value={selectedData?.length ?? '0'}
                                type="number"
                                min="0"
                                max="9"
                                step="1"
                                onKeyDown={(e) => {
                                  if (
                                    (e.keyCode >= 48 && e.keyCode <= 57) ||
                                    (e.keyCode >= 96 && e.keyCode <= 105) ||
                                    e.keyCode === 8
                                  )
                                    setSelectedData({
                                      ...selectedData,
                                      length: '',
                                    })
                                  else e.preventDefault()
                                }}
                                onChange={({ target: { value } }) => {
                                  if (selectedData.start) {
                                    const end = moment(selectedData.start)
                                      .add(value * 50, 'minutes')
                                      .toDate()
                                    setSelectedData({
                                      ...selectedData,
                                      end,
                                      length: value,
                                    })
                                  } else {
                                    setSelectedData({
                                      ...selectedData,
                                      length: value,
                                    })
                                  }
                                }}
                              />
                              <span>Уч. часове:</span>
                            </div>
                            {/*<Input.TimePicker
                                pickDate={selectedData.end}
                                onChange={(end) => {
                                  if (
                                    moment(end).isBefore(
                                      moment(selectedData.start)
                                    )
                                  ) {
                                    setSelectedData({
                                      ...selectedData,
                                      start: null,
                                      end: combineDateAndTime(
                                        selectedData?.date,
                                        end
                                      ),
                                    })
                                  } else
                                    setSelectedData({
                                      ...selectedData,
                                      end: combineDateAndTime(
                                        selectedData?.date,
                                        end
                                      ),
                                    })
                                }}
                              />*/}
                          </div>
                          <div className="row">
                            <Input.Dropdown
                              placeholder="Вид кормуване:"
                              options={sessionTypes}
                              value={selectedData?.type}
                              onChange={({ value }) => setSelectedData({
                                ...selectedData,
                                type: value
                              })}
                            />
                          </div>
                          {!showError ? (
                            <div className="row">
                              {isNumber(showDelete) && (
                                <p
                                  className="btn-delete"
                                  onClick={() => {
                                    if (showDelete === -1) {
                                      Swal.fire({
                                        title: `${translate("sessionDelete")}`,
                                        text:
                                          translate("sessionDeleteQuestion"),
                                        imageUrl: require('../../assets/images/delete.png').default,
                                        imageWidth: 240,
                                        imageHeight: 240,
                                        imageAlt: 'Custom image',
                                        width: '50vw',
                                        showCancelButton: true,
                                        confirmButtonText: `ИЗТРИЙ`,
                                        cancelButtonText: `ОТКАЖИ`,
                                        reverseButtons: true,
                                        confirmButtonColor: '#ed1d5d',
                                        cancelButtonColor: '#7e7e7e',
                                      }).then(({ isConfirmed }) => {
                                        if (isConfirmed) {
                                          dispatch(startLoading())
                                          dispatch(
                                            deleteSession({
                                              data: { _id: session?._id },
                                              onSuccess: () => {
                                                Swal.fire({
                                                  title: `${translate("sessionDelete")}`,
                                                  imageUrl: require('../../assets/images/deleted.png').default,
                                                  imageWidth: 200,
                                                  imageHeight: 200,
                                                  imageAlt: 'Custom image',
                                                  width: '50vw',
                                                  confirmButtonText: `ПРОДЪЛЖИ`,
                                                  confirmButtonColor: 'black',
                                                })
                                                history.push('/')
                                              },
                                            })
                                          )
                                        }
                                      })
                                      return
                                    }
                                    const newSessions = sessions.slice()
                                    newSessions.splice(showDelete, 1)
                                    setSessions(newSessions)
                                    handleAddAndEdit()
                                  }}>
                                  Изтрий
                                </p>
                              )}
                              <p
                                className={`btn-apply ${selectedData?.date &&
                                  selectedData?.start &&
                                  selectedData.end &&
                                  selectedData?.length > 0 &&
                                  selectedData?.type &&
                                  'active'
                                  }`}
                                onClick={() => {
                                  if (
                                    selectedData?.date &&
                                    selectedData?.start &&
                                    selectedData.end &&
                                    selectedData?.length > 0 &&
                                    selectedData?.type
                                  ) {
                                    if (showDelete === -1) {
                                      dispatch(startLoading())
                                      dispatch(
                                        editSession({
                                          data: {
                                            _id: id,
                                            date: selectedData?.date,
                                            start: selectedData?.start,
                                            end: selectedData.end,
                                            student: selectedData.students._id,
                                            car: selectedData.vehicles._id,
                                            user: selectedData.instructors._id,
                                            type: selectedData.type
                                          },
                                          onSuccess: () => {
                                            Swal.fire({
                                              title: `${translate("sessionSuccessfullChange")}`,
                                              icon: 'success',
                                              width: '50vw',
                                              confirmButtonText: `${translate("Continue")}`,
                                              confirmButtonColor: `#0BD84F`,
                                            })
                                            history.push('/')
                                          },
                                        })
                                      )
                                      return
                                    } else
                                      dispatch(
                                        checkSession({
                                          session: {
                                            date: selectedData?.date,
                                            start: selectedData?.start,
                                            end: selectedData.end,
                                            student: selectedData.students._id,
                                            car: selectedData.vehicles._id,
                                            user: selectedData.instructors._id,
                                          },
                                          onSuccess: () => {
                                            if (isNumber(showDelete)) {
                                              const newSessions = sessions.slice()
                                              newSessions.splice(showDelete, 1, selectedData)
                                              setSessions(newSessions)
                                            } else setSessions([...(sessions || []), selectedData])
                                            handleAddAndEdit()
                                          },
                                          onFail: () => setShowError(true),
                                        })
                                      )
                                  }
                                }}>
                                {translate("Save")}
                              </p>
                            </div>
                          ) : (
                            <p className="error">
                             {translate("sessionCreateSameHour")}
                            </p>
                          )}
                        </>
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </>
            ) : (
              <h3
                className="add-session"
                onClick={() => {
                  setShowNewSession(true)
                  setSelectedTab('students')
                }}>
                {translate("Add")}
              </h3>
            )}
          </div>
          <div className="new-sessions-list">
            {sessions?.map((session, i) => (
              <div key={`session-${i}`} className="new-session-container">
                <div className="icon icon-edit" onClick={() => handleEdit(session, i)} />
                <p className="selected">
                {translate("sessionStudent")} {' '}
                  <span>
                    {session?.students?.fullName} - {session?.students?.type?.name}- {session?.students?.type?.name}
                  </span>
                </p>
                <p className="selected">
                {translate("sessionInstructor")} <span>{session?.instructors?.fullName}</span>
                </p>
                <p className="selected">
                {translate("sessionCar")} {' '}
                  <span>
                    {session?.vehicles?.model} - {session?.vehicles?.license}
                  </span>
                </p>
                <div className="row">
                  <p className="selected">
                  {translate("sessionCreateDate")} <span>{moment(session.start).format('DD.MM.YYYY')}</span>
                  </p>
                  <p className="selected">
                  {translate("sessionCreateFrom")} <span>{moment(session.start).format('HH:mm')}</span>
                  </p>
                  <p className="selected">
                  {translate("sessionCreateHours")} <span>{session.length}</span>
                  </p>
                </div>
                <p className="selected">
                  Вид кормуване:{' '}
                  <span>{mapTypes[session?.type]}</span>
                </p>
              </div>
            ))}
          </div>
          {!id && (
            <div className="new-sessions-footer">
              <Button.Raised
                text={translate("sessionCreateSave")}
                disabled={isEmpty(sessions)}
                onClick={() =>
                  dispatch(
                    createSessions(
                      sessions.map((session) => ({
                        date: session?.date,
                        start: session?.start,
                        end: session.end,
                        hours: session.length,
                        student: session.students._id,
                        car: session.vehicles._id,
                        user: session.instructors._id,
                        training: session.students.training,
                        type: session.type
                      }))
                    )
                  )
                }
              />
              <p>{translate("sessionCreateEdit")}</p>
            </div>
          )}
        </div>
      </DragDropContext>
    </div>
  )
}

export default SessionCreate
