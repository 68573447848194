import { isEmpty } from 'lodash'

//prettier-ignore
export const newStudents = (a, b, sortBy, reverse) => {
  if (["pin", 'fullName'].includes(sortBy)) return b?.student?.[sortBy]?.toLowerCase() > a?.student?.[sortBy]?.toLowerCase() ? reverse ? 1 : -1 : reverse ? -1 : 1

  if (!a?.[sortBy]) return 1
  else if (!b?.[sortBy]) return -1
  if (["type", 'theoryGroup'].includes(sortBy)) return b?.[groupBy]?.name > a?.[groupBy]?.name ? reverse ? 1 : -1 : reverse ? -1 : 1
  else if (["instructorTheory", "instructorPractice"].includes(sortBy)) return b[sortBy]?.fullName?.toLowerCase() > a[sortBy]?.fullName?.toLowerCase() ? reverse ? 1 : -1 : reverse ? -1 : 1
  if (["coursePrice"].includes(sortBy)) return ((b?.coursePrice || 0) - (b?.paidPrice || 0)) < ((a?.coursePrice || 0) - (a?.paidPrice || 0)) ? reverse ? 1 : -1 : reverse ? -1 : 1
  if (["paidPrice"].includes(sortBy)) return b[sortBy] < a[sortBy] ? reverse ? 1 : -1 : reverse ? -1 : 1
  else if (["defaultCar"].includes(sortBy)) return b[sortBy]?.license > a[sortBy]?.license ? reverse ? 1 : -1 : reverse ? -1 : 1
  else return b[sortBy] > a[sortBy] ? reverse ? 1 : -1 : reverse ? -1 : 1
}
