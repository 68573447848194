import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { Api, Auth, Alerts } from '@makedonski/socourt-utilities'
import { authTypes, stopLoading } from '../actions'
import { URL } from '../config/settings'
import { history } from '../config/store'

export const requestCode = (action$) =>
  action$.pipe(
    ofType(authTypes.REQUEST_CODE),
    switchMap(({ payload: { phoneNumber, onSuccess, onError, onDisable } }) =>
      Api.post(`${URL}/codes`, JSON.stringify({ phoneNumber })).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next(stopLoading())
            onSuccess()
            if (onDisable
              && (Number(response?.smsData?.limitForHour) === Number(response?.smsData?.sendForHour)
                || Number(response?.smsData?.limitForDay) === Number(response?.smsData?.sendForDay))) onDisable()
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            obs.next(stopLoading())
            const message = err?.response?.error?.message
            if (message === 'Нерегистриран Номер' && onError) onError()
            else Alerts.error('Грешка!', message || '')
            obs.complete()
          })
        )
      )
    )
  )

export const checkCode = (action$) =>
  action$.pipe(
    ofType(authTypes.CHECK_CODE),
    switchMap(({ payload: { phoneNumber, code, onSuccess, onFail } }) =>
      Api.post(`${URL}/codes/check`, JSON.stringify({ phoneNumber, code })).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next(stopLoading())
            onSuccess()
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            obs.next(stopLoading())
            onFail()
            obs.complete()
          })
        )
      )
    )
  )

export const logIn = (action$) =>
  action$.pipe(
    ofType(authTypes.LOGIN),
    switchMap(({ payload }) =>
      Api.post(`${URL}/codes/use`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            window.localStorage.clear()
            Auth.authenticate(response.token, { token: response.token, ...response?.user }).then(() => {
              obs.next({ type: authTypes.LOGIN_SUCCESS, payload: response.user })
              history.push('/')
              obs.next(stopLoading())
              obs.complete()
            })
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            obs.next(stopLoading())
            Alerts.error('Грешка!', err?.response?.error?.message || '')
            obs.complete()
          })
        )
      )
    )
  )

export const logOut = (action$) =>
  action$.pipe(
    ofType(authTypes.LOGOUT),
    switchMap(() =>
      ActionsObservable.create((obs) => {
        Auth.signout().then(() => {
          localStorage.clear()
          history.push('/login')
          obs.complete()
        })
      })
    )
  )

export const checkUser = (action$) =>
  action$.pipe(
    ofType(authTypes.CHECK_USER),
    switchMap(() =>
      Api.get(`${URL}/user/${Auth.getUser()?._id}`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: authTypes.LOGIN_SUCCESS,
              payload: response.user,
            })
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              Auth.signout().then(() => {
                Alerts.error('Невалидна сесия!', 'Моля влезте отново!')
                history.push('/login')
                obs.complete()
              })
            } else {
              Alerts.error('Грешка!', err?.response?.error?.message || '')
              obs.complete()
            }
          })
        )
      )
    )
  )

export const register = (action$) =>
  action$.pipe(
    ofType(authTypes.REGISTER),
    switchMap(({ payload }) =>
      Api.post(`${URL}/codes/register`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            window.localStorage.clear()
            Auth.authenticate(response.token, { token: response.token, ...response?.user }).then((a) => {
              obs.next({ type: authTypes.LOGIN_SUCCESS, payload: response.user })
              obs.next(stopLoading())
              history.push('/')
              obs.complete()
            })
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            obs.next(stopLoading())
            if (err?.response?.error?.message === 'This phone number already exists!')
              Alerts.error('Грешка!', 'Веведения номер фигурира в състемата!')
            else if (err?.response?.error?.message === 'This e-mail number already exists!')
              Alerts.error('Грешка!', 'Веведения имейл фигурира в състемата!')
            else Alerts.error('Грешка!', err?.response?.error?.message || '')
            obs.complete()
          })
        )
      )
    )
  )

export const signUp = (action$) =>
  action$.pipe(
    ofType(authTypes.SIGNUP),
    switchMap(({ payload, onSuccess }) =>
      Api.post(`${URL}/codes/signup`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next(stopLoading())
            if (onSuccess) onSuccess(response?.newUser)
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            obs.next(stopLoading())
            if (err?.response?.error?.message === 'This phone number already exists!')
              Alerts.error('Грешка!', 'Веведения номер фигурира в състемата!')
            else if (err?.response?.error?.message === 'This e-mail number already exists!')
              Alerts.error('Грешка!', 'Веведения имейл фигурира в състемата!')
            else Alerts.error('Грешка!', err?.response?.error?.message || '')
            obs.complete()
          })
        )
      )
    )
  )
