export const firmsTypes = {
  GET_FIRMS: "firms/GET_FIRMS",
  GET_FIRMS_SUCCESS: "firms/GET_FIRMS_SUCCESS",

  GET_FIRM: "firms/GET_FIRM",
  GET_FIRM_SUCCESS: "firms/GET_FIRM_SUCCESS",

  CREATE_FIRM: "firms/CREATE_FIRM",
  UPDATE_FIRM: "firms/UPDATE_FIRM",
  DELETE_FIRM: "firms/DELETE_FIRM",
}

export const getFirms = (payload) => ({
  type: firmsTypes.GET_FIRMS,
  payload,
})

export const getFirm = (payload) => ({
  type: firmsTypes.GET_FIRM,
  payload,
})

export const createFirm = (payload) => ({
  type: firmsTypes.CREATE_FIRM,
  payload,
})

export const updateFirm = (payload) => ({
  type: firmsTypes.UPDATE_FIRM,
  payload,
})

export const deleteFirm = (payload) => ({
  type: firmsTypes.DELETE_FIRM,
  payload,
})

export const clearFirms = (payload) => ({
  type: firmsTypes.GET_FIRM_SUCCESS,
  payload,
})
