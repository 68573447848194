import React, { useState, useEffect, useMemo } from "react"
import { Button, Input } from "@makedonski/admin-ui"
import moment from "moment"
import { useParams, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { isEmpty } from "lodash"
import {
  getInstructor,
  clearInstructor,
  startLoading,
  updateInstructorDetails,
  setOverlay,
  getInstructorSessions,
  setInstructorSessionsFilter,
} from "../../actions"
import { translate } from "../../translations/localization"
import { Instructor as InstructorComp, Session, Popup } from "../../components"
import { useQuery } from "utilities"
import "./styles.scss"

const Instructor = ({ }) => {
  const { id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const { currentUser, overlay } = useSelector((state) => state.general)
  const { instructor, filter } = useSelector((state) => state.instructors)
  const { _id, sessions, details, nextPage, hasNextPage } = instructor || {}

  const isCreate = useMemo(() => id === "create", [id])
  const [editable, setEditable] = useState(isCreate)
  useEffect(() => {
    setEditable(isCreate)
  }, [isCreate])

  useEffect(() => {
    if (!isCreate) {
      dispatch(startLoading())
      dispatch(getInstructor(id))
    } else dispatch(clearInstructor())
    return () => dispatch(setInstructorSessionsFilter({ _id: id, fetch: false }))
  }, [])

  const handlePaggination = (scrollTop, height, scrollHeight) => {
    if (scrollTop + height >= scrollHeight - 10 && hasNextPage) {
      dispatch(startLoading())
      dispatch(getInstructorSessions({ user: id, page: nextPage }))
    }
  }

  const overlayPayload = {
    isOpen: true,
    type: "instructor",
    props: {
      files: details?.files,
      handleChange: (files) =>
        dispatch(
          updateInstructorDetails({ data: { _id: instructor?._id, files } })
        ),
      comments: details?.comments,
      refType: "InstructorDetails",
      refId: instructor?.details?._id
    },
  }

  useEffect(() => {
    if (overlay?.isOpen) dispatch(setOverlay(overlayPayload))
  }, [instructor])

  const props = { instructor, setEditable: (value) => setEditable(value) }

  const { showActiveStudents } = useQuery()
  if (showActiveStudents) return (
    <div className="instructor-container create">
      <InstructorComp.ActiveStudents instructor={instructor} />
    </div>
  )

  if (editable)
    return (
      <div className="instructor-container create">
        <InstructorComp.Form isCreate={isCreate} {...props} />
      </div>
    )

  return (
    <div className="instructor-container row">
      <div className="instructor-inner-left-container">
        <InstructorComp.Profile
          showDocuments={() => dispatch(setOverlay(overlayPayload))}
          {...props}
        />
        <Popup.Overlay />
      </div>
      <div className="instructor-inner-right-container">
        <div className="instructor-sessions-header row">
          <div className="title">
            <h2>{translate("Events")} {filter && moment(filter).format("- DD.MM.YYYY")}</h2>
            {filter && (
              <div
                className="icon icon-delete"
                onClick={() => dispatch(setInstructorSessionsFilter({ _id: id }))}
              />
            )}
          </div>
          {!isEmpty(sessions) && (
            <Input.Datepicker
              pickDate={filter}
              onChange={(value) =>
                dispatch(
                  setInstructorSessionsFilter({
                    _id: id,
                    date: moment(value).startOf("day").toDate(),
                  })
                )
              }
              customInput={<div className="icon icon-calendar ripple" />}
              showMonthDropdown
              showYearDropdown
              todayButton="Днес"
              dropdownMode="select"
            />
          )}
          {id !== "create" &&
            currentUser?.role?.map(({ permissions }) => permissions).includes("admin") && (
              <Button.Raised
                text="Добави"
                className="blue"
                onClick={() => history.push("/session_create")}
              />
            )}
        </div>

        {isEmpty(sessions) ? (
          <div className="box-empty col">
            <div className="image" />
            <p>
              {translate("instructorNoEvents")}
            </p>
          </div>
        ) : (
          <div
            className="instructor-sessions-content"
            onScroll={({ target }) =>
              handlePaggination(
                target.scrollTop,
                target.getBoundingClientRect().height,
                target.scrollHeight
              )
            }
          >
            {sessions?.map((session, i) => (
              <Session
                key={`session-${i}`}
                session={session}
                type="instructor"
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default Instructor
