import Swal from "sweetalert2"
import { renderToString } from "react-dom/server"
import { history } from "../config/store"

export const alertWelcomeManagement = (onSuccess) =>
  Swal.fire({
    title: "ДОБРЕ ДОШЛИ В УПРАВЛЕНИЕ!",
    text:
      "Тук добавяте и след това управлявате Вашите инструктори, курсисти, автомобили, групи и фирми, с които работите.",
    imageUrl: require("../assets/images/alert-welcome.png").default,
    imageWidth: 240,
    imageHeight: 240,
    imageAlt: "Custom image",
    width: "40vw",
    confirmButtonText: `ПРОДЪЛЖИ`,
    confirmButtonColor: "#0BD8DE",
  }).then(() => onSuccess && onSuccess())

export const alertAddStudent = (availableCredits = 5, onSuccess) =>
  Swal.fire({
    title: "ДОБАВЯНЕ НА КУРСИСТ",
    html: renderToString(
      <p style={{ whiteSpace: "pre-line" }}>
        За да добавите курсист в DigiDrive трябва да заплатите с{" "}
        <strong style={{ color: "#1076B5" }}>1 кредит.</strong>
        <br />
        Вие имате налични:{" "}
        <strong style={{ color: "#1076B5" }}>
          {availableCredits} кредита.
        </strong>
        <br />
        <br />
        За да закупите още кредити - влезте в менюто и изберете{" "}
        <strong style={{ color: "#1076B5" }}>DigiDrive баланс.</strong>
      </p>
    ),
    imageUrl: require("../assets/images/profile.png").default,
    imageWidth: 240,
    imageHeight: 240,
    imageAlt: "Custom image",
    width: "40vw",
    confirmButtonText: `ПРОДЪЛЖИ`,
    confirmButtonColor: "#0BD8DE",
  }).then(() => onSuccess && onSuccess())

export const alertInsufficientCredits = () =>
  Swal.fire({
    title: "НЯМАТЕ НАЛИЧНИ КРЕДИТИ",
    text:
      "Към момента нямате налични кредити и не може да добавите нов курсист. Моля закупете още през DigiDrive баланс, за да продължите.",
    imageUrl: require("../assets/images/delete.png").default,
    imageWidth: 240,
    imageHeight: 240,
    imageAlt: "Custom image",
    width: "35vw",
    confirmButtonText: `ЗАКУПУВАНЕ`,
    confirmButtonColor: "#0BD8DE",
  }).then(() => history.push("/balance"))

export const alertAddSession = () =>
  Swal.fire({
    title: "СЪЗДАВАНЕ НА ГРАФИК",
    text:
      "За да добавите сесии за кормуване в графика трябва да имате добавени поне 1 инструктор и 1 курсист в системата.",
    imageUrl: require("../assets/images/delete.png").default,
    imageWidth: 240,
    imageHeight: 240,
    imageAlt: "Custom image",
    width: "35vw",
    confirmButtonText: `КЪМ УПРАВЛЕНИЕ`,
    confirmButtonColor: "#0BD8DE",
  }).then(() => history.push("/managment/students"))

export const paymentAlert = (option) =>
  Swal.fire({
    icon: "success",
    title: "ВАШИЯТ КРЕДИТ ПАКЕТ БЕШЕ ЗАЯВЕН",
    html: renderToString(
      <div
        style={{
          display: "flex",
          alignItem: "center",
          justifyContent: "center",
        }}
      >
        <p
          style={{
            padding: "10px 15px",
            borderRadius: 6,
            background: "#f8f8f8",
            marginRight: 30,
          }}
        >
          Брой:{" "}
          <span style={{ fontWeight: 700 }}>
            {option?.value || "--"} кредита
          </span>
        </p>
        <p
          style={{
            padding: "10px 15px",
            borderRadius: 6,
            background: "#f8f8f8",
          }}
        >
          Сума:{" "}
          <span style={{ fontWeight: 700 }}>
            {option?.label || "--"} лв./без ДДС
          </span>
        </p>
      </div>
    ),
    width: "45vw",
    confirmButtonText: `ПРОДЪЛЖИ`,
    confirmButtonColor: "#0bd84f",
  }).then(() => history.goBack())
