import React, { useState, useMemo } from 'react'
import { Button } from '@makedonski/admin-ui'
import { useSelector, useDispatch } from 'react-redux'
import { addTransaction } from '../../actions'
import { Inputs } from '../../components'
import { paymentOptions } from '../../config/constants'
import { IBAN } from '../../config/settings'
import { paymentAlert } from '../../utilities'
import { translate } from '../../translations/localization'
import './styles.scss'

const BalancePayment = ({}) => {
  const dispatch = useDispatch()
  const { currentUser } = useSelector(({ general }) => general)
  const [showOptions, setShowOptions] = useState(true)
  const [selected, setSelected] = useState()
  const option = useMemo(() => {
    return paymentOptions.find(({ value }) => value === selected)
  }, [selected])

  return (
    <div className="screen-balance-payment-container row">
      <div className="screen-balance-payment-inner-container col">
        <div className="screen-balance-payment-header ">
          {showOptions ? (
            <>
              <h2>{translate("balancePayment")}</h2>
              <p>{translate("balancebalancePaymentInnerTextPayment")}</p>
            </>
          ) : (
            <>
              <h2>{translate("balancebalancePaymentInnerTextPayment")}</h2>
              <p>{translate("balanceData")}</p>
            </>
          )}
        </div>
        <div className="screen-balance-payment-content">
          {showOptions ? (
            paymentOptions.map((opt) => (
              <div
                key={`option-${opt.value}`}
                className={`option-container row value-${opt.value} ${opt.value === selected && 'selected'}`}>
                <Inputs.RadioButtons
                  buttons={[
                    {
                      label: `${opt.value} кредита`,
                      value: opt.value,
                      color: '#0BD8DE',
                    },
                  ]}
                  value={selected}
                  onClick={(value) => setSelected(value)}
                />
                <div className="col">
                  {opt.discount && <p className="discount">-{opt.discount}%</p>}
                  <p>
                    <span>{opt.label}</span> лв/без ДДС
                  </p>
                </div>
              </div>
            ))
          ) : (
            <>
              <div className={`option-container row selected payment value-${option.value}`}>
                <div
                  className="icon icon-edit"
                  onClick={() => {
                    setShowOptions(true)
                    setSelected()
                  }}
                />
                <p className="credits">{option.value} {translate("Credits")}</p>
                <div className="col">
                  {option.discount && <p className="discount">-{option.discount}%</p>}
                  <p>
                    <span>{option.value}</span> лв/без ДДС
                  </p>
                </div>
              </div>
              <div className="payment-form">
                <p className="heading">{translate("balanceWaysOfPayment")}</p>
                <div className="row">
                  <Inputs.RadioButtons
                    buttons={[{ value: `По банков път`, color: '#0BD8DE' }]}
                    value={`По банков път`}
                  />
                  <Inputs.RadioButtons buttons={[{ label: `С карта`, color: '#0BD8DE' }]} value={selected} disabled />
                  <span className="comming-soon">{translate("balanceComingSoon")}</span>
                </div>
                <p className="heading">{translate("balancePayByBankTransfer")}</p>
                <div className="row">
                  <p className="content">
                  {translate("balanceNameFirm")} <span>{translate("balanceFidWeb")}</span>
                  </p>
                  <p className="content">
                  {translate("balanceIBAN")} <span>{IBAN}</span>
                  </p>
                  <p className="content">
                  {translate("balanceDesription")}{' '}
                    <span>
                      {option?.value} {translate("Credits")} - {currentUser?.drivingSchool?.school?.name || '--'}
                    </span>
                  </p>
                  <p className="content">
                  {translate("balanceSum")} <span>{option?.label || '--'} лв./без ДДС</span>
                  </p>
                </div>
                <p className="heading">
                  {translate("balanceNotification")}
                </p>
              </div>
            </>
          )}
        </div>
        <Button.Raised
          disabled={showOptions && !selected}
          onClick={() => {
            showOptions
              ? setShowOptions(false)
              : dispatch(
                  addTransaction({
                    payload: { amount: selected, sum: option?.label },
                    onSuccess: () => paymentAlert(option),
                  })
                )
          }}
          text={showOptions ? 'Избери' : 'Потвърди'}
        />
      </div>
    </div>
  )
}

export default BalancePayment
