import React, { useRef, useEffect, useMemo, useState } from "react"
import mapboxgl from '!mapbox-gl'; // eslint-disable-line 
import { minBy, maxBy, pick } from 'lodash'
import { MAPBOX_API_KEY } from "config/settings";
import 'mapbox-gl/dist/mapbox-gl.css';
import "./styles.scss"
import { getRouteColor } from "utilities";



const MapBox = ({ data, status }) => {

    const coordinates = useMemo(() => data.map((p) => Object.values(pick(p, ["longitude", 'latitude']))), [data])
    const points = useMemo(() => data.map(({ longitude, latitude, speed }, i) => i === data.length - 1 ? ({}) : ({
        'type': 'Feature',
        'properties': { 'color': getRouteColor(speed) },
        'geometry': { 'type': 'LineString', 'coordinates': [[longitude, latitude,], [data?.[i + 1]?.longitude, data?.[i + 1]?.latitude,]] }
    })), [data])

    const [hasBeenMoved, setHasBeenMoved] = useState(false)
    const [hasBeenZoomed, setHasBeenZoomed] = useState(false)

    const map = useRef(null)
    const mapContainer = useRef(null)
    const markerOrigin = useRef(null)
    const markerDestination = useRef(null)
    const markerActive = useRef(null)


    useEffect(() => {
        if (map.current) return
        const center = ["longitude", 'latitude'].map(f => (minBy(data, f)[f] + maxBy(data, f)[f]) / 2)
        map.current = new mapboxgl.Map({ accessToken: MAPBOX_API_KEY, container: mapContainer.current, style: 'mapbox://styles/mapbox/streets-v11', center: center, zoom: 8, attributionControl: false })
        map.current.addControl(new mapboxgl.FullscreenControl());
    }, [])



    useEffect(() => {
        if (!map.current) return
        map.current.on('load', () => {
            markerOrigin.current = new mapboxgl.Marker(getMarker("origin")).setLngLat(coordinates[0]).addTo(map.current);
            markerDestination.current = new mapboxgl.Marker(getMarker('destination')).setLngLat(coordinates[coordinates.length - 1])
            markerActive.current = new mapboxgl.Marker(getMarker('active')).setLngLat(coordinates[coordinates.length - 1])

            // map.current.addSource('mapbox-dem', { 'type': 'raster-dem', 'url': 'mapbox://mapbox.mapbox-terrain-dem-v1', 'tileSize': 512, 'maxzoom': 14 });
            // map.current.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });
            // map.current.addLayer({ 'id': 'sky', 'type': 'sky', 'paint': { 'sky-type': 'atmosphere', 'sky-atmosphere-sun': [0.0, 90.0], 'sky-atmosphere-sun-intensity': 80 } });
            // map.current.addLayer({
            //     'id': '3d-buildings',
            //     'source': 'composite',
            //     'source-layer': 'building',
            //     'filter': ['==', 'extrude', 'true'],
            //     'type': 'fill-extrusion',
            //     'minzoom': 15,
            //     'paint': {
            //         'fill-extrusion-color': '#aaa',
            //         // use an 'interpolate' expression to add a smooth transition effect to the
            //         // buildings as the user zooms in
            //         'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 10, 0, 15.05, ['get', 'height']],
            //         'fill-extrusion-base': ['interpolate', ['linear'], ['zoom'], 10, 0, 15.05, ['get', 'min_height']],
            //         'fill-extrusion-opacity': 0.6
            //     }
            // });

            map.current.addSource('route', { 'type': 'geojson', 'lineMetrics': true, 'data': { 'type': 'FeatureCollection', 'features': points }, tolerance: 0.00001 })
            map.current.addLayer({ 'id': 'route', 'type': 'line', 'source': 'route', 'layout': { 'line-join': 'round', 'line-cap': 'round' }, 'paint': { 'line-color': ['get', 'color'], 'line-width': 8, } })

            if (status !== 'in-action') {
                const bounds = new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]);
                for (const coord of coordinates) { bounds.extend(coord); }
                map.current.fitBounds(bounds, { padding: 50 });
                markerDestination.current.addTo(map.current);
            } else {
                map.current.flyTo({ center: coordinates[coordinates.length - 1], zoom: 15 })
                markerActive.current.addTo(map.current);
            }
        })
        map.current.on('drag', () => setHasBeenMoved(true))
        map.current.on('zoom', () => setHasBeenZoomed(true))
    }, [])

    useEffect(() => {
        if (!map.current) return
        if (!map.current.getSource('route')) return
        map.current.getSource('route').setData({ 'type': 'FeatureCollection', 'features': points });
        markerActive.current.setLngLat(coordinates[coordinates.length - 1])
        if (!hasBeenMoved) map.current.flyTo({ center: coordinates[coordinates.length - 1] })
        if (!hasBeenMoved && !hasBeenZoomed) map.current.flyTo({ zoom: 15 })
    }, [points])


    const getMarker = (type) => {
        const el = document.createElement('div')
        el.classList.add('marker', `marker-${type}`)
        return el
    }


    let animationCounter = 0
    let isAnimating = false
    let stopStep = 0

    const handleAnimation = () => {
        if (!isAnimating) {
            if (stopStep) {
                animationCounter = stopStep
                stopStep = 0
            }
            animateLine()
        }
        else {
            stopStep = animationCounter
            animationCounter = points.length
        }
    }

    const animateLine = () => {
        isAnimating = true
        document.getElementById("btn-play-control").classList = 'icon icon-pause'
        if (animationCounter < points.length) {
            const newPoints = [...points].slice(0, animationCounter)
            map.current.getSource('route').setData({ 'type': 'FeatureCollection', 'features': newPoints });
            // if (coordinates[newPoints.length - 40]) {
            //     const camera = map.current.getFreeCameraOptions();
            //     camera.position = mapboxgl.MercatorCoordinate.fromLngLat({
            //         lng: coordinates[newPoints.length - 40][0],
            //         lat: coordinates[newPoints.length - 40][1]
            //     }, 1200);
            //     camera.lookAtPoint(coordinates[newPoints.length - 1])
            //     map.current.setFreeCameraOptions(camera)
            // }
            animationCounter++
            requestAnimationFrame(animateLine);
        } else {
            isAnimating = false
            document.getElementById("btn-play-control").classList = 'icon icon-arrow-right'
            animationCounter = 0
        }
    }

    const handleResenter = () => {
        setHasBeenMoved(false)
        map.current.flyTo({ center: coordinates[coordinates.length - 1], zoom: 15 })
    }

    return <div className="map-box-container" style={{ height: '100%', width: '100%' }}>
        <div ref={mapContainer} className="map-container" style={{ height: '100%', borderRadius: "6px" }} />
        <div className="row row-buttons">
            <div className="btn-play row" onClick={handleAnimation}><div id="btn-play-control" className="icon icon-arrow-right" /></div>
            {status === 'in-action' && <div className="btn-center row" onClick={handleResenter}><div className="icon icon-center" /></div>}
        </div>

    </div >
}

export default MapBox