import { groupsTypes } from "../actions"

const initialState = {
  groups: [],
  group: {},
}

const groups = (state = initialState, { type, payload }) => {
  switch (type) {
    case groupsTypes.GET_GROUPS_SUCCESS:
      return { ...state, groups: payload }
    case groupsTypes.GET_GROUP_SUCCESS:
      return { ...state, group: payload }
    default:
      return state
  }
}

export default groups
