import { ofType, ActionsObservable } from "redux-observable"
import { switchMap, mergeMap, catchError } from "rxjs/operators"
import { Api, Alerts } from "@makedonski/socourt-utilities"
import { referencesTypes, stopLoading } from "../actions"
import { URL } from "../config/settings"
import { store } from "../config/store"

export const getReference = (action$) =>
  action$.pipe(
    ofType(referencesTypes.GET_REFERENCE),
    mergeMap(({ payload }) => {
      const { from, to } = store.getState().references
      return Api.post(
        `${URL}/statistics/${["newStudents", "income", "obligation"].includes(payload)
          ? "new-students"
          : payload === "newCourses"
            ? "new-courses"
            : payload
        }`,
        JSON.stringify({ to, from })
      ).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: referencesTypes.GET_REFERENCE_SUCCESS,
              payload: {
                type: payload,
                data: response?.finished?.reduce((acc, { name, count }) => ({ ...acc, [name]: count }), {}) || response.statsData || response.result || response.students,
              },
            })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    })
  )

export const getStatisticsSimple = (action$) =>
  action$.pipe(
    ofType(referencesTypes.GET_STATISTICS_SIMPLE),
    mergeMap(({ payload, onSuccess }) => {
      return Api.post(`${URL}/statistics/fast`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response?.data || {})
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    })
  )
