import { default as Form } from "./Form"
import { default as Profile } from "./Profile"
import { default as ActiveStudents } from './ActiveStudents'

const Instructor = {
  Form,
  Profile,
  ActiveStudents
}

export default Instructor
