export const instructorsTypes = {
  GET_INSTRUCTORS: "instructors/GET_INSTRUCTORS",
  GET_INSTRUCTORS_SUCCESS: "instructors/GET_INSTRUCTORS_SUCCESS",

  GET_INSTRUCTOR: "instructors/GET_INSTRUCTOR",
  GET_INSTRUCTOR_SUCCESS: "instructors/GET_INSTRUCTOR_SUCCESS",

  CREATE_INSTRUCTOR: "instructors/CREATE_INSTRUCTOR",
  INVITE_INSTRUCTORS: "instructors/INVITE_INSTRUCTORS",
  UPDATE_INSTRUCTOR: "instructors/UPDATE_INSTRUCTOR",
  UPDATE_INSTRUCTOR_DETAILS: "instructors/UPDATE_INSTRUCTOR_DETAILS",
  DELETE_INSTRUCTOR: "instructors/DELETE_INSTRUCTOR",

  GET_ROLES: "instructors/GET_ROLES",
  GET_INSTRUCTOR_SESSIONS: "instructors/GET_INSTRUCTOR_SESSIONS",
  GET_INSTRUCTOR_SESSIONS_SUCCESS: "instructors/GET_INSTRUCTOR_SESSIONS_SUCCESS",
  SET_INSTRUCTOR_SESSIONS_FILTER: "instructors/SET_INSTRUCTOR_SESSIONS_FILTER",
  RESET_SESSIONS: "instructors/RESET_SESSIONS",
}

export const getRoles = (payload) => ({
  type: instructorsTypes.GET_ROLES,
  payload,
})

export const createInstructor = (payload) => ({
  type: instructorsTypes.CREATE_INSTRUCTOR,
  payload,
})

export const inviteInstructors = (payload) => ({
  type: instructorsTypes.INVITE_INSTRUCTORS,
  payload,
})

export const updateInstructor = (payload) => ({
  type: instructorsTypes.UPDATE_INSTRUCTOR,
  payload,
})

export const updateInstructorDetails = (payload) => ({
  type: instructorsTypes.UPDATE_INSTRUCTOR_DETAILS,
  payload,
})

export const deleteInstructor = (payload) => ({
  type: instructorsTypes.DELETE_INSTRUCTOR,
  payload,
})

export const getInstructors = (payload) => ({
  type: instructorsTypes.GET_INSTRUCTORS,
  payload,
})

export const getInstructor = (payload) => ({
  type: instructorsTypes.GET_INSTRUCTOR,
  payload,
})

export const getInstructorSessions = (payload) => ({
  type: instructorsTypes.GET_INSTRUCTOR_SESSIONS,
  payload,
})

export const setInstructorSessionsFilter = (payload) => ({
  type: instructorsTypes.SET_INSTRUCTOR_SESSIONS_FILTER,
  payload,
})

export const clearInstructor = () => ({
  type: instructorsTypes.GET_INSTRUCTOR_SUCCESS,
  payload: {},
})
