import { referencesTypes } from "../actions"
import moment from "moment"

const initialState = {
  from: moment().subtract(1, "months").toDate(),
  to: moment().toDate(),
}

const references = (state = initialState, { type, payload }) => {
  switch (type) {
    case referencesTypes.GET_REFERENCE_SUCCESS:
      return { ...state, [payload?.type]: payload?.data }
    case referencesTypes.SET_REFERENCES_FIELD:
      return { ...state, [payload?.field]: payload?.value }
    default:
      return state
  }
}

export default references
