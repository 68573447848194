import { ofType, ActionsObservable } from "redux-observable"
import { switchMap, catchError, debounceTime } from "rxjs/operators"
import { Api, Alerts } from "@makedonski/socourt-utilities"
import { firmsTypes, stopLoading } from "../actions"
import { URL } from "../config/settings"
import { history } from "../config/store"

export const getFirms = (action$) =>
  action$.pipe(
    ofType(firmsTypes.GET_FIRMS),
    switchMap(() =>
      Api.get(`${URL}/firms`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: firmsTypes.GET_FIRMS_SUCCESS,
              payload: response.firms,
            })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const getFirm = (action$) =>
  action$.pipe(
    ofType(firmsTypes.GET_FIRM),
    switchMap(({ payload }) =>
      Api.get(`${URL}/firms/${payload}`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: firmsTypes.GET_FIRM_SUCCESS,
              payload: response.firm,
            })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const createFirm = (action$) =>
  action$.pipe(
    ofType(firmsTypes.CREATE_FIRM),
    switchMap(({ payload }) =>
      Api.post(`${URL}/firms`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            history.push(`/managment/firms`)
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const updateFirm = (action$) =>
  action$.pipe(
    ofType(firmsTypes.UPDATE_FIRM),
    switchMap(({ payload }) =>
      Api.put(`${URL}/firms`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            history.push(`/managment/firms`)
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const deleteFirm = (action$) =>
  action$.pipe(
    ofType(firmsTypes.DELETE_FIRM),
    switchMap(({ payload }) =>
      Api.delete(`${URL}/firms`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            history.push(`/managment/firms`)
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )
