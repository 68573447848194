import { ofType, ActionsObservable } from "redux-observable"
import { switchMap, catchError, debounceTime } from "rxjs/operators"
import { Api, Alerts } from "@makedonski/socourt-utilities"
import { groupsTypes, stopLoading } from "../actions"
import { URL } from "../config/settings"
import { history } from "../config/store"

export const getGroups = (action$) =>
  action$.pipe(
    ofType(groupsTypes.GET_GROUPS),
    switchMap(() =>
      Api.get(`${URL}/groups`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: groupsTypes.GET_GROUPS_SUCCESS,
              payload: response.groups,
            })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const getGroup = (action$) =>
  action$.pipe(
    ofType(groupsTypes.GET_GROUP),
    switchMap(({ payload }) =>
      Api.get(`${URL}/groups/${payload}`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: groupsTypes.GET_GROUP_SUCCESS,
              payload: response.group,
            })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const createGroup = (action$) =>
  action$.pipe(
    ofType(groupsTypes.CREATE_GROUP),
    switchMap(({ payload }) =>
      Api.post(`${URL}/groups`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            history.push(`/managment/groups`)
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const updateGroup = (action$) =>
  action$.pipe(
    ofType(groupsTypes.UPDATE_GROUP),
    switchMap(({ payload }) =>
      Api.put(`${URL}/groups`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            history.push(`/managment/groups`)
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const deleteGroup = (action$) =>
  action$.pipe(
    ofType(groupsTypes.DELETE_GROUP),
    switchMap(({ payload }) =>
      Api.delete(`${URL}/groups`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            history.push(`/managment/groups`)
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )
