import React, { useState, useRef } from "react"
import { Button } from "@makedonski/admin-ui"
import { Statistics, NotificationsCard } from "./"
import { useOnClickOutside } from "../../../utilities"
import "./styles.scss"

const Notifications = ({ showNotifications, setShowNotifications }) => {
  const notificationsRef = useRef()

  useOnClickOutside(
    notificationsRef,
    () => showNotifications && setShowNotifications(false)
  )

  const tabs = ["Известия", "Бързи статистики"]
  const [selected, setSelected] = useState(tabs[0])

  const renderContent = () => {
    switch (selected) {
      case "Известия":
        return <NotificationsCard />
      case "Бързи статистики":
        return (
          <Statistics
            hide={() => showNotifications && setShowNotifications(false)}
          />
        )
      default:
        return null
    }
  }

  return (
    <div
      className={`popup-notifications-container ${showNotifications && "open"}`}
    >
      <div
        className="popup-notifications-inner-container"
        ref={notificationsRef}
      >
        <div className="popup-notifications-header row">
          {tabs?.map((tab, i) => (
            <h2
              key={`tab-${i}`}
              onClick={() => setSelected(tab)}
              className={`single-tab ${tab === selected && "selected"}`}
            >
              {tab}
            </h2>
          ))}
          <Button.Icon
            name="plus"
            size="26px"
            onClick={() => setShowNotifications(false)}
          />
        </div>
        <div className="popup-notifications-content">{renderContent()}</div>
      </div>
    </div>
  )
}

export default Notifications
