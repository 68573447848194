import { generalTypes, authTypes } from "../actions"

const initialState = {
  loading: false,
  loadingText: "Loading...",
  modal: { isOpen: false, type: "", props: {} },
  overlay: { isOpen: false, type: "", props: {} },
}

const general = (state = initialState, { type, payload }) => {
  switch (type) {
    case authTypes.LOGIN_SUCCESS:
      return { ...state, currentUser: payload }
    case generalTypes.START_LOADING:
      return { ...state, loading: true }
    case generalTypes.STOP_LOADING:
      return { ...state, loading: false }
    case generalTypes.SET_LOADING_TEXT:
      return { ...state, loadingText: payload }
    case generalTypes.SET_MODAL:
      return { ...state, modal: payload }
    case generalTypes.SET_OVERLAY:
      return { ...state, overlay: payload }
    case generalTypes.SET_GENERAL_FIELD:
      return { ...state, [payload?.field]: payload?.value }
    default:
      return state
  }
}

export default general
