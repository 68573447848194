import React, { useState, useRef } from "react"
import { Input, Button } from "@makedonski/admin-ui"
import { useDispatch, useSelector } from "react-redux"
import { isEmpty } from "lodash"
import {
  startLoading,
  stopLoading,
  updateInstructor,
  checkUser,
} from "../../actions"
import { uploadFile } from "../../utilities"
import { translate } from "../../translations/localization"
import "./styles.scss"

const Settings = ({}) => {
  const [showRequired, setShowRequired] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [data, setData] = useState({})
  const handleChange = (field, value) => setData({ ...data, [field]: value })

  const dispatch = useDispatch()
  const uploadBtnRef = useRef()
  const handleImage = (files) => {
    if (isEmpty(files)) return
    dispatch(startLoading())
    uploadFile(files[0]).then((data) => {
      handleChange("avatar", data?.location)
      dispatch(stopLoading())
    })
  }

  const { currentUser } = useSelector((state) => state.general)

  //prettier-ignore
  const validate = () => !["firstName", "lastName", "email", "phoneNumber"].some((field) => data.hasOwnProperty(field) && !data?.[field] )
  //prettier-ignore
  const isRequred = (field) => showRequired && data.hasOwnProperty(field) && !data?.[field] && "required"

  return (
    <div className="settings-container">
      <div className="settings-inner-container">
        <div className="settings-profile-container">
          <div className="settings-profile-header">
            <div className="row">
              <h2>{translate("settingsHeader")}</h2>
              {showEdit ? (
                <>
                  <div
                    className="icon icon-delete"
                    onClick={() => {
                      setShowEdit(false)
                      setData({})
                    }}
                  />
                  <div
                    className="icon icon-check-white"
                    onClick={() => {
                      if (!validate()) {
                        setShowRequired(true)
                        return
                      } else {
                        dispatch(
                          updateInstructor({
                            data: {
                              _id: currentUser?._id,
                              firstName: currentUser?.firstName,
                              lastName: currentUser?.lastName,
                              ...data,
                            },
                            onSuccess: () => dispatch(checkUser()),
                          })
                        )
                        setShowEdit(false)
                        setData({})
                      }
                    }}
                  />
                </>
              ) : (
                <div
                  className="icon icon-edit"
                  onClick={() => setShowEdit(true)}
                />
              )}
            </div>
            <p>{!showEdit && translate("settingsCheckData")}</p>
          </div>
          <div className="settings-profile-content row">
            {!showEdit || data?.avatar || currentUser?.avatar ? (
              <div
                className="avatar-container-full row"
                style={{
                  backgroundImage: `url(${
                    data?.avatar ||
                    currentUser?.avatar ||
                    require("../../assets/images/avatar.png").default
                  })`,
                }}
              >
                {showEdit && (
                  <div
                    className="icon icon-camera ripple"
                    onClick={() => uploadBtnRef?.current?.click()}
                  />
                )}
              </div>
            ) : (
              <Input.DragAndDrop handleDrop={(files) => handleImage(files)}>
                <div className="avatar-container row">
                  <div
                    className="icon icon-image ripple"
                    onClick={() => uploadBtnRef?.current?.click()}
                  />
                </div>
              </Input.DragAndDrop>
            )}
            <input
              type="file"
              ref={uploadBtnRef}
              className="btn-upload"
              onChange={({ target: { files } }) => handleImage(files)}
              style={{ display: "none" }}
            />
            <div className="row row-data">
              {showEdit ? (
                <>
                  <div className="col">
                    <span>{translate("Name")}</span>
                    <Input.Text
                      value={data?.firstName ?? currentUser?.firstName ?? ""}
                      onChange={({ target: { value } }) =>
                        handleChange("firstName", value)
                      }
                      inputClassName={`${isRequred("firstName")}`}
                    />
                  </div>
                  <div className="col">
                    <span>{translate("lastName")}</span>
                    <Input.Text
                      value={data?.lastName ?? currentUser?.lastName ?? ""}
                      onChange={({ target: { value } }) =>
                        handleChange("lastName", value)
                      }
                      inputClassName={`${isRequred("lastName")}`}
                    />
                  </div>
                  <div className="col">
                    <span>{translate("email")}</span>
                    <Input.Text
                      value={data?.email ?? currentUser?.email ?? ""}
                      onChange={({ target: { value } }) =>
                        handleChange("email", value)
                      }
                      inputClassName={`${isRequred("email")}`}
                    />
                  </div>
                  <div className="col">
                    <span>{translate("Phone")}</span>
                    <Input.Text
                      value={
                        data?.phoneNumber ?? currentUser?.phoneNumber ?? ""
                      }
                      onChange={({ target: { value } }) =>
                        handleChange("phoneNumber", value)
                      }
                      inputClassName={`${isRequred("phoneNumber")}`}
                    />
                  </div>
                </>
              ) : (
                <>
                  <p>
                  {translate("Name")}: <span>{currentUser?.fullName || "—"}</span>
                  </p>
                  <p>
                  {translate("School")}:{" "}
                    <span>
                      {currentUser?.drivingSchool?.school?.name || "—"}
                    </span>
                  </p>
                  <p>
                  {translate("email")}: <span>{currentUser?.email || "—"}</span>
                  </p>
                  <p>
                  {translate("Phone")}: <span>{currentUser?.phoneNumber || "—"}</span>
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="settings-notifications-container">
          <div className="settings-notifications-header">
            <h2>{translate("settingsNotifications")}</h2>
            <p>{translate("settingsNotificationsInnerText")}</p>
          </div>
          <div className="settings-notifications-content row">
            {[
              { label: "Маркетинг имейли", value: "" },
              { label: "Имейли за дейността", value: "" },
              { label: "Нотификации десктоп", value: "" },
            ].map(({ label, value }, i) => (
              <div key={`option-${i}`} className="option-container row">
                <p>{label}</p>
                <Button.Switch isOn />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Settings
