import { coursesTypes } from "../actions"

const initialState = {
  courses: [],
  types: [],
}

const courses = (state = initialState, { type, payload }) => {
  switch (type) {
    case coursesTypes.GET_COURSES_SUCCESS:
      return { ...state, courses: payload }
    case coursesTypes.GET_COURSES_TYPES_SUCCESS:
      return { ...state, types: payload }
    default:
      return state
  }
}

export default courses
