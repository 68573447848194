import { balanceTypes } from "../actions"

const initialState = {
  transactions: [],
  transaction: {},
}

const balance = (state = initialState, { type, payload }) => {
  switch (type) {
    case balanceTypes.GET_TRANSACTIONS_SUCCESS:
      return { ...state, transactions: payload }
    case balanceTypes.GET_TRANSACTION_SUCCESS:
      return { ...state, transaction: payload }
    default:
      return state
  }
}

export default balance
