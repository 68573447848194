export const authTypes = {
  REQUEST_CODE: 'auth/REQUEST_CODE',
  CHECK_CODE: 'auth/CHECK_CODE',
  LOGIN: 'auth/LOGIN',
  LOGIN_SUCCESS: 'auth/LOGIN_SUCCESS',
  LOGOUT: 'auth/LOGOUT',
  CHECK_USER: 'auth/CHECK_USER',
  REGISTER: 'auth/REGISTER',
  SIGNUP: 'auth/SIGNUP',
}

export const requestCode = (payload) => ({
  type: authTypes.REQUEST_CODE,
  payload,
})

export const checkCode = (payload) => ({
  type: authTypes.CHECK_CODE,
  payload,
})

export const logIn = (payload) => ({
  type: authTypes.LOGIN,
  payload,
})

export const logOut = (payload) => ({
  type: authTypes.LOGOUT,
  payload,
})

export const checkUser = (payload) => ({
  type: authTypes.CHECK_USER,
  payload,
})

export const register = (payload) => ({
  type: authTypes.REGISTER,
  ...payload,
})

export const signUp = (payload) => ({
  type: authTypes.SIGNUP,
  ...payload,
})
