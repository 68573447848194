import { combineEpics } from "redux-observable"
import * as auth from "./auth"
import * as sessions from "./sessions"
import * as students from "./students"
import * as school from "./school"
import * as instructors from "./instructors"
import * as socket from "./socket"
import * as groups from "./groups"
import * as vehicles from "./vehicles"
import * as firms from "./firms"
import * as shared from "./shared"
import * as courses from "./courses"
import * as references from "./references"
import * as balance from "./balance"
import * as training from "./training"

export const rootEpic = combineEpics(
  auth.requestCode,
  auth.checkCode,
  auth.logIn,
  auth.logOut,
  auth.checkUser,
  auth.register,
  auth.signUp,
  sessions.getSessions,
  sessions.getSession,
  sessions.editSession,
  sessions.deleteSession,
  sessions.getStudentsForSession,
  sessions.getInstructorsForSession,
  sessions.getVehiclesForSession,
  sessions.checkAllowedToAddSession,
  sessions.checkSession,
  sessions.createSessions,
  sessions.confirmSession,
  sessions.updateSessionCompleted,
  students.getStudents,
  students.getStudent,
  students.getStudentTraining,
  students.createStudent,
  students.updateStudent,
  students.deleteStudent,
  students.getStudentSessions,
  students.setStudentSessionsFilter,
  school.getSchool,
  school.updateSchool,
  instructors.getRoles,
  instructors.getInstructors,
  instructors.getInstructor,
  instructors.getInstructorSessions,
  instructors.createInstructor,
  instructors.inviteInstructors,
  instructors.updateInstructor,
  instructors.updateInstructorDetails,
  instructors.deleteInstructor,
  instructors.setInstructorSessionsFilter,
  socket.connectSocket,
  socket.socketListener,
  socket.disconnectSocket,
  socket.sessionEnded,
  vehicles.getVehicles,
  vehicles.getVehicle,
  vehicles.createVehicle,
  vehicles.updateVehicle,
  vehicles.deleteVehicle,
  groups.getGroups,
  groups.getGroup,
  groups.createGroup,
  groups.updateGroup,
  groups.deleteGroup,
  firms.getFirms,
  firms.getFirm,
  firms.createFirm,
  firms.updateFirm,
  firms.deleteFirm,
  courses.getCourses,
  courses.getCoursesTypes,
  courses.createCourse,
  courses.updateCourses,
  courses.createPermit,
  courses.updatePermit,
  courses.deletePermit,
  references.getReference,
  references.getStatisticsSimple,
  shared.addComment,
  balance.getTransactions,
  balance.getTransaction,
  balance.addTransaction,
  training.getTraining,
  training.createTraining,
  training.updateTraining,
  training.deleteTraining,
)
