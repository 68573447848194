import { isEmpty } from 'lodash'

//prettier-ignore
export const students = (a, b, sortBy, reverse) => {
  // if ([undefined, null].includes(a?.[sortBy])) return 1
  // else if ([undefined, null].includes(b?.[sortBy])) return -1
  if (!a?.[sortBy]) return 1
  else if (!b?.[sortBy]) return -1
  if (["type"].includes(sortBy)) return b?.type?.name > a?.type?.name ? reverse ? 1 : -1 : reverse ? -1 : 1
  if (['firm', 'theoryGroup'].includes(sortBy)) return b[sortBy]?.name > a[sortBy]?.name ? reverse ? 1 : -1 : reverse ? -1 : 1
  else if (["instructorTheory", "instructorPractice"].includes(sortBy)) return b[sortBy]?.fullName?.toLowerCase() > a[sortBy]?.fullName?.toLowerCase() ? reverse ? 1 : -1 : reverse ? -1 : 1
  else if (["defaultCar"].includes(sortBy)) return b[sortBy]?.license > a[sortBy]?.license ? reverse ? 1 : -1 : reverse ? -1 : 1
  else if (["fullName"].includes(sortBy)) return b[sortBy]?.toLowerCase() > a[sortBy]?.toLowerCase() ? reverse ? 1 : -1 : reverse ? -1 : 1
  else return b[sortBy] > a[sortBy] ? reverse ? 1 : -1 : reverse ? -1 : 1
}

//prettier-ignore
export const instructors = (a, b, sortBy, reverse) => {
  if (["isActiveUser"].includes(sortBy)) return a[sortBy] ? reverse ? 1 : -1 : reverse ? -1 : 1
  else if (["defaultCar", "employeeFrom"].includes(sortBy)) {
    if (!a?.details?.[sortBy]) return 1
    else if (!b?.details?.[sortBy]) return -1
  } else {
    if (!a?.[sortBy]) return 1
    else if (!b?.[sortBy]) return -1
  }
  if (["defaultCar"].includes(sortBy)) return b.details[sortBy]?.license > a.details[sortBy]?.license ? reverse ? 1 : -1 : reverse ? -1 : 1
  else return b[sortBy] > a[sortBy] ? reverse ? 1 : -1 : reverse ? -1 : 1
}

//prettier-ignore
export const vehicles = (a, b, sortBy, reverse) => {
  if (!a?.[sortBy] || isEmpty(a?.[sortBy])) return 1
  else if (!b?.[sortBy] || isEmpty(b?.[sortBy])) return -1
  if (["instructors"].includes(sortBy)) return a[sortBy]?.length > b[sortBy]?.length ? reverse ? 1 : -1 : reverse ? -1 : 1
  else return b[sortBy] > a[sortBy] ? reverse ? 1 : -1 : reverse ? -1 : 1
}

//prettier-ignore
export const groups = (a, b, sortBy, reverse) => {
  if (!a?.[sortBy] || isEmpty(a?.[sortBy])) return 1
  else if (!b?.[sortBy] || isEmpty(b?.[sortBy])) return -1
  if (["course"].includes(sortBy)) return b[sortBy]?.name > a[sortBy]?.name ? reverse ? 1 : -1 : reverse ? -1 : 1
  if (["instructors"].includes(sortBy)) return a[sortBy]?.length > b[sortBy]?.length ? reverse ? 1 : -1 : reverse ? -1 : 1
  else return b[sortBy] > a[sortBy] ? reverse ? 1 : -1 : reverse ? -1 : 1
}

//prettier-ignore
export const firms = (a, b, sortBy, reverse) => {
  if (["totalStudents", "activeStudents"].includes(sortBy)){
    if (isEmpty(a?.students)) return 1
    else if (isEmpty(b?.students)) return -1
    if (["totalStudents"].includes(sortBy)) return a?.students?.length > b?.students?.length ? reverse ? 1 : -1 : reverse ? -1 : 1
    else if (["activeStudents"].includes(sortBy)) return a?.students?.reduce((acc, { remainingMinutes }) => (remainingMinutes ? ( acc + 1 ) : 0), 0) > b?.students?.reduce((acc, { remainingMinutes }) => (remainingMinutes ? ( acc + 1 ) : 0), 0) ? reverse ? 1 : -1 : reverse ? -1 : 1
  }
  if (!a?.[sortBy] || isEmpty(a?.[sortBy])) return 1
  else if (!b?.[sortBy] || isEmpty(b?.[sortBy])) return -1
  if (["course"].includes(sortBy)) return b[sortBy]?.name > a[sortBy]?.name ? reverse ? 1 : -1 : reverse ? -1 : 1
  if (["instructors"].includes(sortBy)) return a[sortBy]?.length > b[sortBy]?.length ? reverse ? 1 : -1 : reverse ? -1 : 1
  else return b[sortBy] > a[sortBy] ? reverse ? 1 : -1 : reverse ? -1 : 1
}
