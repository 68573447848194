import React, { useState, useMemo } from "react"
import { Button } from "@makedonski/admin-ui"
import { isEmpty } from "lodash"
import { SingleRow } from "./"
import "./styles.scss"

const List = ({
  fields = [],
  data = [],
  fixedWidth,
  renderField,
  sortingFunction = () => true,
  filteringFunction = () => true,
  className = "",
}) => {
  const [sortBy, setSortBy] = useState("")
  const [reverse, setReverse] = useState(true)
  const scrollContainerWidth = useMemo(
    () => fields?.reduce((acc, { size }) => (acc += +size), 0),
    [fields]
  )

  const getWidth = (size) => {
    if (size <= 100) return "x-small"
    if (size <= 200) return "small"
    if (size <= 300) return "medium"
    if (size <= 400) return "big"
    return "x-big"
  }

  const props = { fields, fixedWidth, renderField, getWidth }

  return (
    <div className={`shared-list-container ${className}`}>
      <div
        className="shared-list-header row"
        style={{ paddingRight: `${fixedWidth ? 24 : 0}px` }}
      >
        {fields?.map(({ label, size = 300, value }, i) => (
          <span
            key={`field-${i}`}
            style={{ minWidth: `${fixedWidth ? 0 : size}px` }}
            className={`row ${sortBy === value && "active"} ${
              fixedWidth && getWidth(size)
            }`}
          >
            <span>{label}</span>
            <Button.Icon
              name="angle-down"
              color="black"
              className={`${sortBy === value && reverse && "rotate"}`}
              onClick={() => {
                setSortBy(value)
                if (sortBy === value) setReverse(!reverse)
                else setReverse(false)
              }}
            />
          </span>
        ))}
      </div>
      {isEmpty(data) ? (
        <div className="box-empty col">
          <div className="image" />
          <p>Нямате добавена информация в системата</p>
        </div>
      ) : (
        <div
          className="shared-list-content col"
          style={{
            minWidth: `${fixedWidth ? 0 : scrollContainerWidth + 35}px`,
          }}
        >
          {data
            ?.sort((a, b) => sortingFunction(a, b, sortBy, reverse))
            ?.filter(filteringFunction)
            ?.map((row, i) => (
              <SingleRow key={`row-${i}`} row={row} {...props} />
            ))}
        </div>
      )}
    </div>
  )
}

export default List
