import React from "react"
import moment from "moment"
import { groupBy } from "lodash"
import "./styles.scss"

const Activity = ({ _activity }) => {
  const groupedByDate = groupBy(_activity, ({ createdAt }) =>
    moment(createdAt).format("DD.MM.YYYY")
  )

  return (
    <div className="shared-activity-container">
      {Object.keys(groupedByDate)?.map((day, i) => (
        <div key={`day-${day}`} className="activity-day-container">
          <h2>{moment(day, "DD.MM.YYYY").format("DD MMMM - dddd")}</h2>
          {groupedByDate[day]?.map((act) => (
            <div
              key={`activity-${act?._id}`}
              className="acitivty-single-container row"
            >
              <div className="row row-content">
                <div className="avatar" style={{ backgroundImage: `url(${act?.user?.avatar || require('../../../assets/images/avatar.png').default})`, }} />
                <p>
                  {act?.user?.fullName} {act?.text}
                </p>
              </div>
              <p className="time">{moment(act?.createdAt).format("HH:mm")}</p>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export default Activity
