import React, { useState, useEffect } from "react"
import { Button } from "@makedonski/admin-ui"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import { getStatisticsSimple } from "../../../actions"
import {translate} from "../../../translations/localization"
import "./styles.scss"

const Statistics = ({ hide }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [stats, setStats] = useState()
  useEffect(() => {
    dispatch(getStatisticsSimple({ onSuccess: (data) => setStats(data) }))
  }, [])

  return (
    <div className="statistics-container">
      <h2>{translate("StudentsInSystem")}</h2>
      <div className="row row-main">
        <p>
        {translate("Active")}: <span>{stats?.activeStudents}</span>
        </p>
        <p>
        {translate("All")}: <span>{stats?.totalStudents}</span>
        </p>
      </div>
      <div className="line" />

      <h2>{translate("ActiveEduc")}</h2>
      <div className="row row-training">
        {stats?.activeTrainings?.map(({ name, count }, i) => (
          <p key={`training-${i}`}>
            {name}: <span>{count}</span>
          </p>
        ))}
      </div>
      <div className="line" />

      <h2>{translate("forms2months")}</h2>
      <div className="row row-expiring">
        <p>
        {translate("41")}: <span>{}</span>
        </p>
        <p>
        {translate("psycho")}: <span>{}</span>
        </p>
        <p>
        {translate("ADR")}: <span>{}</span>
        </p>
      </div>
      <Button.Raised
        text={translate("lookAll")}
        onClick={() => {
          history.push(`/references`)
          hide()
        }}
      />
    </div>
  )
}
export default Statistics
