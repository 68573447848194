export const referencesTypes = {
  GET_REFERENCE: "references/GET_REFERENCE",
  GET_REFERENCE_SUCCESS: "references/GET_REFERENCE_SUCCESS",

  SET_REFERENCES_FIELD: "references/SET_REFERENCES_FIELD",

  GET_STATISTICS_SIMPLE: "references/GET_STATISTICS_SIMPLE",
}

export const getReference = (payload) => ({
  type: referencesTypes.GET_REFERENCE,
  payload,
})

export const setReferencesField = (payload) => ({
  type: referencesTypes.SET_REFERENCES_FIELD,
  payload,
})

export const getStatisticsSimple = (payload) => ({
  type: referencesTypes.GET_STATISTICS_SIMPLE,
  ...payload,
})
