import { ofType, ActionsObservable } from "redux-observable"
import { switchMap, catchError, debounceTime } from "rxjs/operators"
import { Api, Alerts } from "@makedonski/socourt-utilities"
import { vehiclesTypes, stopLoading } from "../actions"
import { URL } from "../config/settings"
import { history } from "../config/store"

export const getVehicles = (action$) =>
  action$.pipe(
    ofType(vehiclesTypes.GET_VEHICLES),
    switchMap(() =>
      Api.get(`${URL}/cars`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: vehiclesTypes.GET_VEHICLES_SUCCESS,
              payload: response.car,
            })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const getVehicle = (action$) =>
  action$.pipe(
    ofType(vehiclesTypes.GET_VEHICLE),
    switchMap(({ payload }) =>
      Api.get(`${URL}/cars/${payload}`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: vehiclesTypes.GET_VEHICLE_SUCCESS,
              payload: response.car,
            })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const createVehicle = (action$) =>
  action$.pipe(
    ofType(vehiclesTypes.CREATE_VEHICLE),
    switchMap(({ payload }) =>
      Api.post(`${URL}/cars`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            history.push(`/managment/vehicles`)
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const updateVehicle = (action$) =>
  action$.pipe(
    ofType(vehiclesTypes.UPDATE_VEHICLE),
    switchMap(({ payload }) =>
      Api.put(`${URL}/cars`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            history.push(`/managment/vehicles`)
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const deleteVehicle = (action$) =>
  action$.pipe(
    ofType(vehiclesTypes.DELETE_VEHICLE),
    switchMap(({ payload }) =>
      Api.delete(`${URL}/cars`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            history.push(`/managment/vehicles`)
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )
