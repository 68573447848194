import React, { useEffect, useMemo } from 'react'
import { Input } from '@makedonski/admin-ui'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { getCourses, getGroups, getInstructors, getVehicles } from '../../../actions'
import { translate } from '../../../translations/localization'
import './styles.scss'

const Form = ({ showNote, data, handleChange, showRequired }) => {
  const { theoryGroup } = data || {}
  //prettier-ignore
  const { groups: { groups }, instructors: { instructors }, vehicles: { vehicles }, courses: { courses } } = useSelector((state) => state)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getCourses())
    dispatch(getGroups())
    dispatch(getInstructors())
    dispatch(getVehicles())
  }, [])

  const isRequred = (field) => showRequired && !data?.[field] && 'required'

  const progress = useMemo(() => {
    if (data?.totalHours && !data?.remainingMinutes) return '0'
    return ((data?.totalHours ?? 31) - (data?.remainingMinutes ?? 31 * 50) / 50).toString()
  }, [data])

  const instructorsIds = useMemo(() => instructors.map(({ _id }) => _id), [instructors])
  useEffect(() => {
    handleChange({
      instructorTheory: !instructorsIds.includes(data?.instructorTheory?._id)
        || !theoryGroup?.instructors.map(({ _id }) => _id).includes(data?.instructorTheory?._id)
        ? undefined
        : data?.instructorTheory?._id,
      instructorPractice: !instructorsIds.includes(data?.instructorPractice?._id) ? undefined : data?.instructorPractice?._id
    })
  }, [instructorsIds, theoryGroup])

  return (
    <div className="training-form-container">
      <div className="row">
        <div className="col col-big">
          <p className="input-label">
            {translate("groupEducation")} <span className="icon icon-required" />
          </p>
          <Input.Dropdown
            value={data?.type?._id || data?.type}
            options={courses?.map(({ type, name }) => ({ value: type?._id, label: name }))}
            onChange={({ value }) => handleChange({ type: value })}
            className={`${isRequred('type')}`}
          />
        </div>
        <div className="col">
          <p>{translate("Number")}</p>
          <Input.Text
            value={data?.registrationNumber ?? ''}
            onChange={({ target: { value } }) => handleChange({ registrationNumber: value })}
          />
        </div>
        <div className="col">
          <p>{translate("Group")}</p>
          <Input.Dropdown
            options={groups.map(({ _id, name }) => ({ value: _id, label: name }))}
            value={data?.theoryGroup?._id ?? data?.theoryGroup ?? ''}
            onChange={({ value }) => {
              const group = groups.find(({ _id }) => _id === value)
              handleChange({
                theoryGroup: value,
                instructorTheory: group?.instructors?.[0]?._id,
                theoryStart: moment(group?.start).toDate(),
                theoryEnd: moment(group?.end).toDate(),
              })
            }}
          />
        </div>
        <div className="col">
          <p>{translate("Teacher") + " " + translate("Theory")}</p>
          <Input.Dropdown
            options={groups
              ?.find(({ _id }) => _id === data?.theoryGroup?._id || data?.theoryGroup)
              ?.instructors?.map(({ _id, fullName }) => ({ value: _id, label: fullName }))}
            onChange={({ value }) => handleChange({ instructorTheory: value })}
            value={data?.instructorTheory?._id || data?.instructorTheory}
          />
        </div>
        <div className="col">
          <p>{translate("Start") + " " + translate("Theory")}</p>
          <Input.Datepicker
            minDate={moment().subtract(10, 'y').toDate()}
            pickDate={data?.theoryStart ? moment(data.theoryStart).toDate() : null}
            onChange={(theoryStart) => {
              if (theoryStart > new Date(data?.theoryEnd)) handleChange({ theoryStart, theoryEnd: null })
              else handleChange({ theoryStart })
            }}
          />
        </div>
        <div className="col">
          <p>{translate("End") + " " + translate("Theory")}</p>
          <Input.Datepicker
            minDate={moment().subtract(10, 'y').toDate()}
            pickDate={data?.theoryEnd ? moment(data.theoryEnd).toDate() : null}
            onChange={(theoryEnd) => {
              if (theoryEnd < new Date(data?.theoryStart)) handleChange({ theoryEnd, theoryStart: null })
              else handleChange({ theoryEnd })
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <p>{translate("Teacher") + " " + translate("Practise")}</p>
          <Input.Dropdown
            options={instructors.map(({ _id, fullName }) => ({ value: _id, label: fullName }))}
            value={data?.instructorPractice?._id || data?.instructorPractice}
            onChange={({ value }) => {
              const defaultCar = instructors.find(({ _id }) => _id === value)?.details?.defaultCar?._id
              handleChange({ instructorPractice: value, defaultCar })
            }}
          />
        </div>
        <div className="col">
          <p>{translate("MPS")}</p>
          <Input.Dropdown
            value={data?.defaultCar?._id || data?.defaultCar}
            options={vehicles.map(({ _id, model, license }) => ({ value: _id, label: `${model} - ${license}` }))}
            onChange={({ value }) => handleChange({ defaultCar: value })}
          />
        </div>
        <div className="col">
          <p>{translate("Start") + " " + translate("Practise")}</p>
          <Input.Datepicker
            pickDate={data?.practiceStart ? moment(data.practiceStart).toDate() : null}
            onChange={(practiceStart) => {
              if (practiceStart > new Date(data?.practiceEnd)) handleChange({ practiceStart, practiceEnd: null })
              else handleChange({ practiceStart })
            }}
          />
        </div>
        <div className="col">
          <p>{translate("End") + " " + translate("Practise")}</p>
          <Input.Datepicker
            pickDate={data?.practiceEnd ? moment(data.practiceEnd).toDate() : null}
            onChange={(practiceEnd) => {
              if (practiceEnd < new Date(data?.practiceStart)) handleChange({ practiceEnd, practiceStart: null })
              else handleChange({ practiceEnd })
            }}
          />
        </div>
        <div className="col col-small">
          <p>{translate("Progress")}</p>
          <div className="row row-progress">
            <div className="hours-input-container">
              <Input.Text
                value={data?.practiceProgress ?? progress ?? ''}
                onChange={({ target: { value } }) => {
                  if (!/^[0-9]{0,2}$/.test(value) || value > (data?.totalHours ?? 31)) return
                  handleChange({ practiceProgress: value })
                }}
              />
              <span>ч</span>
            </div>
            <div className="hours-input-container">
              <Input.Text
                value={data?.totalHours ?? 31}
                onChange={({ target: { value } }) => {
                  if (!/^[0-9]{0,2}$/.test(value)) return
                  handleChange({ totalHours: value })
                }}
              />
              <span>ч</span>
            </div>
          </div>
        </div>
        <div className="col">
          <p>{translate("InsideExamT")}</p>
          <Input.Datepicker
            pickDate={data?.innerExamTheory ? moment(data?.innerExamTheory).toDate() : null}
            onChange={(innerExamTheory) => {
              if (innerExamTheory > new Date(data?.innerExamPractice))
                handleChange({ innerExamTheory, innerExamPractice: null })
              else handleChange({ innerExamTheory })
            }}
          />
        </div>
        <div className="col">
          <p>{translate("InsideExamP")}</p>
          <Input.Datepicker
            minDate={data?.practiceEnd}
            pickDate={data?.innerExamPractice ? moment(data?.innerExamPractice).toDate() : null}
            onChange={(innerExamPractice) => {
              if (innerExamPractice < new Date(data?.innerExamTheory))
                handleChange({ innerExamPractice, innerExamTheory: null })
              else handleChange({ innerExamPractice })
            }}
          />
        </div>
      </div>
      <div className="row row-payments">
        <div className="col">
          <p>{translate("SumCourse")}</p>
          <div className="price-input-container">
            <Input.Text
              value={data?.coursePrice ?? ''}
              onChange={({ target: { value } }) => {
                if (!/^\d+(\.||\.\d{0,2})?$/.test(value) && value !== '') return
                if (data?.paidPrice && Number(value) > Number(data?.paidPrice)) return
                handleChange({ coursePrice: value })
              }}
            />
            <span>лв.</span>
          </div>
        </div>
        <div className="col">
          <p>{translate("PaidSum")}</p>
          <div className="price-input-container">
            <Input.Text
              value={data?.paidPrice ?? ''}
              onChange={({ target: { value } }) => {
                if (!/^\d+(\.||\.\d{0,2})?$/.test(value) && value !== '') return
                if (data?.coursePrice && Number(value) > Number(data?.coursePrice)) return
                handleChange({ paidPrice: value })
              }}
            />
            <span>лв.</span>
          </div>
        </div>
        {showNote && (
          <div className="col col-auto">
            <p>{translate("Note")}</p>
            <Input.Text
              value={data?.comment ?? ''}
              onChange={({ target: { value } }) => handleChange({ comment: value })}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default Form
