import { schoolTypes } from "../actions"

const school = (state = {}, { type, payload }) => {
  switch (type) {
    case schoolTypes.GET_SCHOOL_SUCCESS:
      return { ...state, ...payload }
    default:
      return state
  }
}

export default school
