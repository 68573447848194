import React, { useState, useEffect, useMemo, useRef } from "react"
import { Button, Input } from "@makedonski/admin-ui"
import { Popover } from "@varld/popover"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { isEmpty, pickBy } from "lodash"
import { getSessions, checkAllowedToAddSession } from "../../actions"
import { Views } from "./"
import { Inputs, Popup } from "../"
import { useQuery, alertAddSession } from "../../utilities"
import "./styles.scss"

const buttons = [
  { label: "Дневен", value: "day" },
  { label: "Седмичен", value: "week" },
  { label: "Месечен", value: "month" },
]

const Calendar = ({ }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { currentUser } = useSelector((state) => state.general)
  const { sessions } = useSelector((state) => state.sessions)

  const {
    view = "week",
    date: dateParam = moment().startOf("week").format("DD.MM.YYYY"),
    showWeekend = false,
    filter: filterParam = "[]",
    filterOption,
    handleUrlChange,
    handleUrlChangeMultiple,
    clear,
  } = useQuery({ view: [buttons[1], buttons[0], buttons[2]] })

  const date = useMemo(() => {
    return moment(dateParam, "DD.MM.YYYY").toDate()
  }, [dateParam])
  const filter = useMemo(() => JSON.parse(filterParam), [filterParam])

  const filteringFunction = (obj, func) => {
    return Object.entries(obj).reduce((acc, [key, value]) => ({ ...acc, [key]: value.filter(func) }), {})
  }

  const filterSessions = useMemo(() => {
    let filtered = sessions
    if (!currentUser?.role?.map(({ permissions }) => permissions)?.includes("admin")) filtered = pickBy(sessions, (s) => s[0].user._id === currentUser?._id)
    else if (!isEmpty(filter)) filtered = pickBy(sessions, (s) => filter.includes(s[0].user._id))
    if (filterOption) {
      const functions = {
        "upcomming": ({ endedAt, end }) => !moment(endedAt || end).isBefore(moment()),
        "expired": ({ endedAt, end }) => moment(endedAt || end).isBefore(moment()),
        "withApp": ({ startedAt }) => !!startedAt,
        "withoutApp": ({ startedAt }) => !startedAt,
      }
      filtered = filteringFunction(filtered, functions[filterOption])
      filtered = pickBy(filtered, (s) => !isEmpty(s))
    }
    return filtered
  }, [sessions, currentUser, filter, filterOption])

  const fetchData = { start: date, end: moment(date).endOf(view).toDate() }
  useEffect(() => {
    dispatch(getSessions(fetchData))
  }, [date])

  const [allowedToAddSession, setAllowedToAddSession] = useState(true)
  useEffect(() => {
    dispatch(
      checkAllowedToAddSession({
        onSuccess: ({ instructors, students }) =>
          setAllowedToAddSession(!!instructors && !!students),
      })
    )
  }, [])

  const props = {
    sessions: filterSessions,
    date,
    setDate: (value) =>
      handleUrlChange("date", moment(value).format("DD.MM.YYYY")),
    fetchData,
  }

  const renderView = () => {
    switch (view) {
      case "day":
        return <Views.Daily {...props} />
      case "week":
        return <Views.Grid {...props} weekend={showWeekend} />
      case "month":
        return <Views.Grid {...props} monthly />
      default:
        return null
    }
  }

  return (
    <div className="calendar-container">
      <div className="calendar-header row">
        <div className="date row">
          <Input.Datepicker
            pickDate={moment(date).toDate()}
            onChange={(value) =>
              handleUrlChange(
                "date",
                moment(value).startOf(view).format("DD.MM.YYYY")
              )
            }
            customInput={<div className="icon icon-calendar ripple" />}
            showMonthDropdown
            showYearDropdown
            todayButton="Днес"
            dropdownMode="select"
          />
          {view === "day" ? (
            <p>{moment(date).format("DD MMMM")}</p>
          ) : (
            <p>
              {moment(date).format("DD")} -{" "}
              {moment(date).endOf(view).format("DD MMMM")}
            </p>
          )}
        </div>
        <Inputs.RoundedTab
          buttons={buttons}
          value={view}
          onChange={({ value }) => {
            clear()
            handleUrlChange("view", value)
            handleUrlChange(
              "date",
              moment().startOf(value).format("DD.MM.YYYY")
            )
          }}
        />
        <div className="buttons row">
          {currentUser?.role
            ?.map(({ permissions }) => permissions)
            ?.includes("admin") && (
              <>
                {[filter, filterOption].some(f => !isEmpty(f)) && <p className="active-filters">Активни филтри</p>}
                <Popover
                  popover={({ visible, close }) => (
                    <Popup.InstructorsFilter
                      hide={close}
                      handleApply={({ selected, option }) =>
                        handleUrlChangeMultiple({ filter: JSON.stringify(selected), filterOption: option })
                      }
                      selected={filter}
                      option={filterOption}
                      instructors={Object.keys(sessions).map((key) => sessions[key][0].user)}
                    />
                  )}
                >
                  <div className={`icon icon-filter ripple`} />
                </Popover>
                <Button.Raised
                  className="blue"
                  text="Добави час"
                  onClick={() => {
                    if (allowedToAddSession) history.push("/session_create")
                    else alertAddSession()
                  }}
                />
              </>
            )}
        </div>
      </div>
      {renderView()}
      {view === "week" && (
        <div className="row row-weekend">
          Почивни дни
          <div
            className={`icon icon-arrow-down ${showWeekend && "rotate"}`}
            onClick={() => handleUrlChange("showWeekend", !showWeekend)}
          />
        </div>
      )}
    </div>
  )
}

export default Calendar
