import React, { useRef } from "react"
import { Button } from "@makedonski/admin-ui"
import { useOnClickOutside } from "../../../utilities"
import { Shared } from "../../"
import { translate } from "../../../translations/localization"
import "./styles.scss"

const Documents = ({
  showDocuments,
  setShowDocuments,
  files,
  handleChange,
}) => {
  const documentsRef = useRef()
  useOnClickOutside(
    documentsRef,
    () => showDocuments && setShowDocuments(false)
  )

  const props = { files, handleChange }

  return (
    <div className={`documents-container ${showDocuments && "open"}`}>
      <div className="documents-inner-container col" ref={documentsRef}>
        <div className="documents-header row">
          <h2>{translate("documents")}</h2>
          <Button.Icon
            name="plus"
            size="26px"
            onClick={() => setShowDocuments(false)}
          />
        </div>
        <Shared.Documents {...props} />
      </div>
    </div>
  )
}

export default Documents
