export const studentsFields = [
  { label: 'ЕГН', size: '200', value: 'pin' },
  { label: 'Име', size: '300', value: 'fullName' },
  { label: 'Обучение', size: '350', value: 'type' },
  { label: 'Брой часове', size: '250', value: 'remainingMinutes' },
  { label: 'Преподавател теория', size: '300', value: 'instructorTheory' },
  { label: 'Група', size: '200', value: 'theoryGroup' },
  { label: 'Преподавател практика', size: '300', value: 'instructorPractice' },
  { label: 'Автомобил', size: '250', value: 'defaultCar' },
  { label: 'Фирма', size: '250', value: 'firm' },
]

export const instructorsFields = [
  { label: 'Име', size: '300', value: 'fullName' },
  // { label: "Шофьорски Теория", size: "250", value: "theory" },
  // { label: "Шофьорски Практика", size: "250", value: "practice" },
  // { label: "АДР", size: "100", value: "adr" },
  // { label: "ЦПО", size: "100", value: "cpo" },
  // { label: "41-ва теория", size: "180", value: "theory41" },
  // { label: "41-ва практика", size: "190", value: "practice41" },
  // { label: "Точки", size: "130", value: "points" },
  { label: 'Автомобил', size: '200', value: 'defaultCar' },
  { label: 'Служебен телефон', size: '280', value: 'phoneNumber' },
  { label: 'Служител от', size: '300', value: 'employeeFrom' },
  // { label: "Добавен тук", size: "300", value: "isActiveUser" },
]

export const managmentTypes = [
  { label: 'Курсисти', value: 'students' },
  { label: 'Инструктори', value: 'instructors' },
  { label: 'МПС-та', value: 'vehicles' },
  { label: 'Групи', value: 'groups' },
  { label: 'Фирми', value: 'firms' },
]

export const groupsFields = [
  { label: 'Име на групата', size: '200', value: 'name' },
  { label: 'Година', size: '200', value: 'year' },
  { label: 'Вид обучение', size: '300', value: 'course' },
  { label: 'Начало на курс', size: '200', value: 'start' },
  { label: 'Край на курс', size: '200', value: 'end' },
  { label: 'Инструктори', size: '400', value: 'instructors' },
]

export const vehiclesFields = [
  { label: 'Марка/модел', size: '300', value: 'model' },
  { label: 'Рег.номер', size: '300', value: 'license' },
  { label: 'Година', size: '200', value: 'makeYear' },
  { label: 'Инструктор', size: '500', value: 'instructors' },
]

export const firmsFields = [
  { label: 'Име на фирма', size: '300', value: 'name' },
  { label: 'ЕИК', size: '300', value: 'eic' },
  { label: 'Лице за контакт', size: '300', value: 'contactName' },
  { label: 'Телефон', size: '300', value: 'contactPhone' },
  { label: 'Имейл', size: '300', value: 'email' },
  { label: 'Активни курсисти', size: '300', value: 'activeStudents' },
  { label: 'Всички курсисти', size: '300', value: 'totalStudents' },
]

export const managmentFields = {
  students: studentsFields,
  instructors: instructorsFields,
  groups: groupsFields,
  vehicles: vehiclesFields,
  firms: firmsFields,
}

//prettier-ignore
export const referencesTypes = [
  { label: "Генерална", value: "general", subtypes: [
    { label: "Проведени обучения:", value: "finished" },
    { label: "Нови курсисти:", value: "newStudents" },
    { label: "Нови обучения:", value: "newCourses" },
    { label: "Приход:", value: "income" },
    { label: "Задължения:", value: "obligation" },
  ] },
  { label: "Удостоверения", value: "certificates" },
]

export const referencesIncomeFields = [
  { label: 'Име', size: '300', value: 'fullName' },
  { label: 'ЕГН', size: '300', value: 'pin' },
  { label: 'Обучение', size: '300', value: 'type' },
  { label: 'Период', size: '300', value: 'theoryStart' },
  { label: 'Фирма', size: '300', value: 'firm' },
  { label: 'Платена сума', size: '300', value: 'paidPrice' },
]

export const referencesObligationFields = [
  { label: 'Име', size: '300', value: 'fullName' },
  { label: 'ЕГН', size: '300', value: 'pin' },
  { label: 'Обучение', size: '300', value: 'type' },
  { label: 'Период', size: '300', value: 'theoryStart' },
  { label: 'Фирма', size: '300', value: 'firm' },
  { label: 'Дължима сума', size: '300', value: 'coursePrice' },
]

export const referencesCertificatesFields = [
  { label: 'Име', size: '300', value: 'fullName' },
  { label: 'ЕГН', size: '200', value: 'pin' },
  { label: 'Обучение', size: '350', value: 'type' },
  { label: 'Телефон', size: '200', value: 'phoneNumber' },
  { label: 'Група', size: '200', value: 'theoryGroup' },
  { label: 'Валидност', size: '200', value: 'expiretyDate' },
]

export const referencesFields = {
  finished: [],
  newStudents: studentsFields,
  newCourses: studentsFields,
  income: referencesIncomeFields,
  obligation: referencesObligationFields,
  certificates: referencesCertificatesFields,
}

export const sessionTypes = [
  { value: 'urban', label: 'Градско' },
  { value: 'suburban', label: 'Извънградско' },
  { value: 'highway', label: 'Магистрала' },
  { value: 'night', label: 'Нощно' },
  { value: 'parking', label: 'Паркиране' },
  { value: 'internalExam', label: 'Вътрешен изпит' },
]

export const paymentOptions = [
  { value: 10, label: 60 },
  { value: 20, label: 114, discount: 5 },
  { value: 30, label: 162, discount: 10 },
  { value: 40, label: 204, discount: 15 },
  { value: 50, label: 240, discount: 20 },
  { value: 60, label: 270, discount: 25 },
]
