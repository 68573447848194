import { studentsTypes } from "../actions"

const initialState = {
  students: [],
  student: {},
  filter: null,
}

const students = (state = initialState, { type, payload }) => {
  switch (type) {
    case studentsTypes.GET_STUDENTS_SUCCESS:
      return { ...state, students: payload }
    case studentsTypes.GET_STUDENT_SUCCESS:
      return { ...state, student: { ...state.student, ...payload } }
    case studentsTypes.GET_STUDENT_SESSIONS_SUCCESS:
      const newSessions = payload.page > 1 ? [...(state?.student?.sessions || []), ...(payload?.docs || [])] : payload?.docs
      return { ...state, student: { ...state.student, ...payload, sessions: newSessions, }, }
    case studentsTypes.RESET_SESSIONS:
      return { ...state, student: { ...state.student, sessions: [] } }
    case studentsTypes.SET_STUDENT_SESSIONS_FILTER:
      return { ...state, filter: payload?.date }
    default:
      return state
  }
}

export default students
