import React from 'react'
import moment from 'moment'
import { IBAN } from '../../config/settings'
import { translate } from '../../translations/localization'
import './styles.scss'

const Transaction = ({ transaction, school }) => {
  const { amount, confirmed, createdAt, sum, company, account, invoice, reason } = transaction || {}
  return (
    <div className="transaction-container">
      <div className={`transaction-header row ${confirmed ? 'confirmed' : 'pending'}`}>
        <h3>{translate("packet")} {amount} {translate("Credits")}</h3>
        <p>{confirmed ? 'Платено' : 'Очаква плащане'}</p>
      </div>
      <div className="transaction-content">
        <div className="row">
          <p className="content">
            {translate("orderedAt")}: <span>{moment(createdAt).format('DD.MM.YYYY')}</span>
          </p>
          <p className="content">
            {translate("balanceSum")} <span>{sum || '--'} лв./без ДДС</span>
          </p>
          <p className="content row">
            {translate("invoice")}:
            {invoice && (
              <a href={invoice || ''} target="_blank" rel="noopener noreferrer">
                <span className="icon icon-download-custom" />
              </a>
            )}
          </p>
        </div>
        {!confirmed && (
          <>
            <p className="heading">{translate("infoForPayment")}:</p>
            <div className="row">
              <p className="content">
                {translate("balanceNameFirm")}: <span>{translate("balanceFidWeb")}</span>
              </p>
              <p className="content">
              {translate("balanceIBAN")} <span>{IBAN}</span>
              </p>
              <p className="content">
              {translate("balanceDescription")}:{' '}
                <span>
                  {amount} {translate("Credits")} - {school || '--'}
                </span>
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Transaction
