export const socketTypes = {
  SOCKET_CONNECT: "socket/SOCKET_CONNECT",
  SOCKET_CONNECTED: "socket/SOCKET_CONNECTED",
  SOCKET_DISCONNECT: "socket/SOCKET_DISCONNECT",
}

export const connectSocket = (payload) => ({
  type: socketTypes.SOCKET_CONNECT,
  payload,
})

export const disconnectSocket = () => ({
  type: socketTypes.SOCKET_DISCONNECT,
})
