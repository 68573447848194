import React from "react"
import "./styles.scss"

const NotificationsCard = ({ }) => {
    return <div className="notifications-card-container col">
        <div className="image"></div>
        <p>Тук ще Ви известяваме за всички проведени сесии</p>
    </div>
}

export default NotificationsCard