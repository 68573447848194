import React, { useState, useEffect, useMemo } from 'react'
import { Button, Input } from '@makedonski/admin-ui'
import moment from 'moment'
import useLocalStorage from 'use-local-storage'
import { flatten, omit } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getStudents, getVehicles, getGroups, getFirms, getInstructors } from '../../actions'
import { Student, Instructor, Shared } from '../../components'
import { managmentFields, managmentTypes } from '../../config/constants'
import * as renderField from './renderField'
import * as sortingFunction from './sortingFunction'
import * as filteringFunction from './filteringFunction'
import { alertWelcomeManagement, alertInsufficientCredits, useQuery } from '../../utilities'
import './styles.scss'

const Managment = ({ }) => {
  const { type } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  const { currentUser } = useSelector((state) => state.general)
  const {
    students: { students },
    vehicles: { vehicles },
    instructors: { instructors },
    firms: { firms },
    groups: { groups },
  } = useSelector((state) => state)

  useEffect(() => {
    type === 'students' && dispatch(getStudents())
    type === 'instructors' && dispatch(getInstructors())
    type === 'vehicles' && dispatch(getVehicles())
    type === 'groups' && dispatch(getGroups())
    type === 'firms' && dispatch(getFirms())
  }, [type])

  useEffect(() => {
    if (!managmentTypes.map(({ value }) => value).includes(type)) history.push('/managment/students')
  }, [type])

  const { searchBy, search, handleUrlChangeMultiple } = useQuery({ searchBy: managmentFields[type || 'students'] })

  const props = {
    className: type,
    fields: managmentFields[type],
    renderField: renderField[type],
    sortingFunction: sortingFunction[type],
    filteringFunction: (el) => (filteringFunction[type] ? filteringFunction[type](el, search, searchBy) : true),
    fixedWidth: true,
  }

  const studentsMapped = useMemo(() => {
    return flatten(
      students?.map((student) =>
        student?._trainings?.map((training) => ({
          ...student,
          ...omit(training, ['_id', '__v', 'updatedAt', 'id', 'createdAt']),
          training: training?._id,
          trainingDate: training?.updatedAt,
        }))
      )
    ).sort((a, b) => (moment(a.trainingDate).isAfter(moment(b.trainingDate)) ? -1 : 1))
  }, [students])

  const renderContent = () => {
    switch (type) {
      case 'students':
        return <Shared.List {...props} data={studentsMapped} fixedWidth={false} />
      case 'instructors':
        return <Shared.List {...props} data={instructors} />
      case 'groups':
        return <Shared.List {...props} data={groups} />
      case 'vehicles':
        return <Shared.List {...props} data={vehicles} />
      case 'firms':
        return <Shared.List {...props} data={firms} />
      default:
        return null
    }
  }

  const [alerts, setAlerts] = useLocalStorage('alerts', {})
  useEffect(() => {
    !alerts?.managment && alertWelcomeManagement(() => setAlerts({ ...alerts, managment: true }))
  }, [alerts])

  return (
    <div className="managment-container">
      <div className="managment-inner-container">
        <div className="managment-header row">
          <div className="row row-buttons">
            {managmentTypes
              ?.filter(
                ({ value }) =>
                  currentUser?.role?.map(({ permissions }) => permissions)?.includes('admin') || value === 'students'
              )
              ?.map(({ label, value }) => (
                <div
                  key={`tab-${value}`}
                  className={`single-tab ${value === type && 'selected'}`}
                  onClick={() => history.push(`/managment/${value}`)}>
                  {label}
                </div>
              ))}
          </div>
          <div className="search row">
            <Input.Text
              placeholder="Свободно търсене"
              value={search || ''}
              onChange={({ target: { value } }) => handleUrlChangeMultiple({ search: value })}
            />
            <Input.Dropdown
              options={managmentFields[type]}
              value={searchBy}
              onChange={({ value }) => handleUrlChangeMultiple({ searchBy: value })}
            />
          </div>
          {currentUser?.role?.map(({ permissions }) => permissions)?.includes('admin') && (
            <Button.Raised
              text="Добави"
              className="blue"
              onClick={() => {
                /*if (type === 'students' && !currentUser?.drivingSchool?.credits) alertInsufficientCredits()
                else*/
                history.push(`/${type.slice(0, type.length - 1)}/create`)
              }}
            />
          )}
        </div>
        <div className="managment-content">{renderContent()}</div>
      </div>
    </div>
  )
}

export default Managment
