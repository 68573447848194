import { ofType, ActionsObservable } from "redux-observable"
import { switchMap, catchError, debounceTime } from "rxjs/operators"
import { Api, Alerts } from "@makedonski/socourt-utilities"
import { studentsTypes, startLoading, stopLoading, checkUser } from "../actions"
import { URL } from "../config/settings"
import { history, store } from "../config/store"
import moment from 'moment'

export const getStudents = (action$) =>
  action$.pipe(
    ofType(studentsTypes.GET_STUDENTS),
    switchMap(() =>
      Api.get(`${URL}/students`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: studentsTypes.GET_STUDENTS_SUCCESS,
              payload: response.student,
            })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const getStudent = (action$) =>
  action$.pipe(
    ofType(studentsTypes.GET_STUDENT),
    switchMap(({ payload }) =>
      Api.get(`${URL}/students/${payload}`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: studentsTypes.GET_STUDENT_SUCCESS,
              payload: response.student,
            })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const getStudentTraining = (action$) =>
  action$.pipe(
    ofType(studentsTypes.GET_STUDENT_TRAINING),
    switchMap(({ payload, onSuccess }) =>
      Api.get(`${URL}/training/student/${payload}`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response)
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const createStudent = (action$) =>
  action$.pipe(
    ofType(studentsTypes.CREATE_STUDENT),
    switchMap(({ payload, onSuccess }) =>
      Api.post(`${URL}/students`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response?.student || {})
            obs.next(checkUser())
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.response.message === "PIN Already exists")
              Alerts.error("Грешка!", "Вече има добавен курсист с това ЕГН!")
            else Alerts.error("Грешка!", err?.response?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const updateStudent = (action$) =>
  action$.pipe(
    ofType(studentsTypes.UPDATE_STUDENT),
    switchMap(({ payload }) =>
      Api.put(`${URL}/students`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: studentsTypes.GET_STUDENT,
              payload: response.student._id,
            })
            history.push(`/student/${response?.student?._id}`)
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const deleteStudent = (action$) =>
  action$.pipe(
    ofType(studentsTypes.DELETE_STUDENT),
    switchMap(({ payload }) =>
      Api.delete(`${URL}/students`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            history.push(`/managment/students`)
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const setStudentSessionsFilter = (action$) =>
  action$.pipe(
    ofType(studentsTypes.SET_STUDENT_SESSIONS_FILTER),
    switchMap(({ payload }) =>
      ActionsObservable.create((obs) => {
        if (payload?.fetch !== false) {
          obs.next(startLoading())
          obs.next({
            type: studentsTypes.GET_STUDENT_SESSIONS,
            payload: { student: payload._id, pageNumber: 1 },
          })
        }
        obs.next({ type: studentsTypes.RESET_SESSIONS })
        obs.complete()
      })
    )
  )

export const getStudentSessions = (action$) =>
  action$.pipe(
    ofType(studentsTypes.GET_STUDENT_SESSIONS),
    debounceTime(300),
    switchMap(({ payload }) => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const { training } = Object.fromEntries(urlSearchParams.entries());
      const date = store.getState().students.filter
      const filter = date
        ? {
          startedAt: {
            $gte: moment(date).startOf("day").toDate(),
            $lte: moment(date).endOf("day").toDate(),
          },
          start: {
            $gte: moment(date).startOf("day").toDate(),
            $lte: moment(date).endOf("day").toDate(),
          },
          training
        }
        : { training }
      return Api.post(`${URL}/students/sessions`, JSON.stringify({ ...payload, pageSize: 5, filter })).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: studentsTypes.GET_STUDENT_SESSIONS_SUCCESS,
              payload: response.result,
            })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    })
  )
