import React, { useEffect, useRef } from "react"
import { isEmpty } from "lodash"
import "./styles.scss"

const RoundedTab = ({ value, buttons, onChange, width = 120 }) => {
  let buttonsRef = useRef({})
  let activeButtonRef = useRef()

  const detectRoute = (btn) => {
    let currentBtn = buttonsRef?.current?.[btn]
    if (currentBtn) {
      Object.keys(buttonsRef?.current).forEach((button, i) =>
        buttonsRef?.current[button].classList.remove("rounded-tab-active")
      )
      currentBtn.classList.add("rounded-tab-active")
      if (activeButtonRef.current)
        activeButtonRef.current.style.left = `${
          width * Object.keys(buttonsRef?.current).indexOf(btn)
        }px`
    }
  }

  useEffect(() => {
    setTimeout(() => {
      detectRoute(value)
    }, 100)
  }, [value, buttonsRef, activeButtonRef.current])

  return (
    <div className="input-rounded-tabs-container row">
      <div className="input-rounded-tabs-inner-container row">
        {buttons?.map((el, i) => (
          <div
            key={i}
            ref={(ref) => {
              buttonsRef.current[el.value] = ref
            }}
            className="rounded-tab ripple"
            onClick={() => onChange && onChange(el)}
            style={{ width }}
          >
            {el.label}
          </div>
        ))}
        <div
          ref={activeButtonRef}
          className="active-rounded-selector"
          style={{ width }}
        />
      </div>
    </div>
  )
}

export default RoundedTab
