import React from "react"
import Swal from "sweetalert2"
import { Button, Input } from "@makedonski/admin-ui"
import { renderToString } from "react-dom/server"
import {
  deleteSession,
  getSessions,
  confirmSession,
  addComment,
  startLoading,
} from "../../actions"
import { sessionTypes } from "../../config/constants"
import { translate } from "../../translations/localization"
import { store } from "../../config/store"

export const handleMissedSession = (student, session, endedAt, fetchData, training, type) => {

  Swal.fire({
    title: "ЛИПСВАЩА ИНФОРМАЦИЯ",
    html: renderToString(
      <p style={{ whiteSpace: "pre-line" }}>
        {translate("calendarMobileAppNotStarted")}
        <br />
        <br />
        <strong>
          {student?.fullName} | {training?.type?.name}
        </strong>
        <br />
        <br />
        {translate("calendarApprove")}
      </p>
    ),
    imageUrl: require("../../assets/images/delete.png").default,
    imageWidth: 240,
    imageHeight: 240,
    imageAlt: "Custom image",
    width: "50vw",
    showDenyButton: true,
    showCancelButton: false,
    confirmButtonText: translate("calendarConducted"),
    denyButtonText: translate("calendarDenied"),
    reverseButtons: true,
    confirmButtonColor: "#0BD8DE",
    denyButtonColor: "#ED1D5D",
  }).then(({ isConfirmed, isDenied }) => {
    if (isConfirmed)
      Swal.fire({
        title: "Данни за час",
        width: 700,
        html: renderToString(
          <div id="sessionForm">
            <p>
            {translate("calendarChooseType")}
            </p>
            <div className="row row-type">
              {sessionTypes?.map(({ label, value }, i) => (
                <React.Fragment key={`radio-${value}`}>
                  <input
                    name="type"
                    type="radio"
                    id={value}
                    value={value}
                    defaultChecked={type ? type === value : i === 0}
                  />
                  <label htmlFor={value}>{label}</label>
                </React.Fragment>
              ))}
            </div>
            <p style={{ textAlign: "left" }}>{translate("calendarComment")}</p>
            <Input.Textarea />
          </div>
        ),
        confirmButtonColor: "#0BD8DE",
        confirmButtonText: `ЗАПАЗИ`,
        preConfirm: () => {
          const form = document.getElementById("sessionForm")
          const selectedType = document.querySelector('input[name="type"]:checked')
            ?.value
          const comment = form.childNodes[3].childNodes[0].value
          if (!comment || !selectedType) return false
          return { selectedType, comment }
        },
      }).then(({ value: { selectedType, comment } = {} }) => {
        if (!selectedType || !comment) return
        store.dispatch(
          confirmSession({
            data: { _id: session, type: selectedType, endedAt },
            onSuccess: () => {
              store.dispatch(startLoading())
              store.dispatch(
                addComment({ refType: "Sessions", ref: session, text: comment })
              )
              store.dispatch(getSessions(fetchData))
            },
          })
        )
      })
    else if (isDenied)
      store.dispatch(
        deleteSession({
          data: { _id: session },
          onSuccess: () => {
            Swal.fire({
              title: translate("calendarDeleted"),
              text: translate("calendarDeletedInnerText"),
              imageUrl: require("../../assets/images/deleted.png").default,
              imageWidth: 200,
              imageHeight: 200,
              imageAlt: "Custom image",
              width: "50vw",
              confirmButtonText: translate("Continue"),
              confirmButtonColor: "black",
            }).then(() => {
              store.dispatch(startLoading())
              store.dispatch(getSessions(fetchData))
            })
          },
        })
      )
  })
}
