import React, { useState, useEffect, useRef } from "react"
import Swal from "sweetalert2"
import { Button, Input } from "@makedonski/admin-ui"
import { isEmpty } from "lodash"
import { useDispatch } from "react-redux"
import { getRoles, updateInstructor, deleteInstructor } from "../../../actions"
import { Inputs } from "../../"
import { useOnClickOutside } from "../../../utilities"
import { translate } from "../../../translations/localization"
import "./styles.scss"

const InstructorEdit = ({ editData, setEditData }) => {
  const editRef = useRef()
  useOnClickOutside(editRef, () => {
    setData({})
    {
      setEditData({})
      setData({})
    }
  })

  const [data, setData] = useState(editData || {})
  const handleChange = (field, value) => setData({ ...data, [field]: value })

  const dispatch = useDispatch()
  const [roles, setRoles] = useState([])
  useEffect(() => {
    dispatch(getRoles((value) => setRoles(value)))
  }, [])
  return (
    <div
      className={`popup-instructor-edit-container ${
        !isEmpty(editData) && "open"
      }`}
    >
      <div className="popup-instructor-edit-inner-container col" ref={editRef}>
        <h2>{translate("editUser")}</h2>
        <p>{translate("editUserInnerText")}</p>
        <div className="invitation-form">
          <span>{translate("User")}</span>
          <div className="form-container">
            <div className="row row-name">
              <div className="col">
                <span>{translate("Name")}</span>
                <Input.Text
                  value={data?.firstName ?? editData?.firstName ?? ""}
                  onChange={({ target: { value } }) =>
                    handleChange("firstName", value)
                  }
                />
              </div>
              <div className="col">
                <span>{translate("lastName")}</span>
                <Input.Text
                  value={data?.lastName ?? editData?.lastName ?? ""}
                  onChange={({ target: { value } }) =>
                    handleChange("lastName", value)
                  }
                />
              </div>
            </div>
            <span>{translate("Email")}</span>
            <Input.Text
              value={data?.email ?? editData?.email ?? ""}
              onChange={({ target: { value } }) => handleChange("email", value)}
            />
            <span>{translate("Phone")}</span>
            <Input.Text
              value={data?.phoneNumber ?? editData?.phoneNumber ?? ""}
              onChange={({ target: { value } }) =>
                handleChange("phoneNumber", value)
              }
            />
            <span>{translate("Role")}</span>
            <Inputs.CheckBoxes
              buttons={roles
                ?.filter(({ permissions }) => ["admin", "instructor"].includes(permissions))
                ?.map(({ name, _id }) => ({
                  label: name,
                  value: _id,
                }))}
              value={data?.role || editData?.role?.map(({ _id }) => _id)}
              onClick={(value) => handleChange("role", value)}
            />
            <div className="row">
              <p
                className="btn-cancel"
                onClick={() => {
                  setEditData({})
                  setData({})
                }}
              >
                {translate("Cancel")}
              </p>
              <p
                className="btn-delete"
                onClick={() =>
                  Swal.fire({
                    title: "ИЗТРИВАНЕ НА ПОТРЕБИТЕЛ",
                    text:
                      "Вие сте на път да изтриете този потренител от системата. Ако потвърдите действието, информацията му ще бъде премахната от системата,  но курсисте му ще бъдат запазени.",
                    imageUrl: require("../../../assets/images/delete.png")
                      .default,
                    imageWidth: 240,
                    imageHeight: 240,
                    imageAlt: "Custom image",
                    width: "50vw",
                    showCancelButton: true,
                    confirmButtonText: `ИЗТРИЙ`,
                    cancelButtonText: `ОТКАЖИ`,
                    reverseButtons: true,
                    confirmButtonColor: "#ed1d5d",
                    cancelButtonColor: "#7e7e7e",
                  }).then(({ isConfirmed }) => {
                    if (isConfirmed) {
                      dispatch(
                        deleteInstructor({
                          data: editData?._id,
                          onSuccess: () => {
                            Swal.fire({
                              title: "ИЗТРИТ ПОТРЕБИТЕЛ",
                              text: `${editData?.fullName} беше премахнат от системата и вече няма да има достъп.`,
                              imageUrl: require("../../../assets/images/deleted.png")
                                .default,
                              imageWidth: 200,
                              imageHeight: 200,
                              imageAlt: "Custom image",
                              width: "50vw",
                              confirmButtonText: `ПРОДЪЛЖИ`,
                              confirmButtonColor: "black",
                            })
                            setEditData({})
                            setData({})
                          },
                        })
                      )
                    }
                  })
                }
              >
                {translate("Delete")}
              </p>
            </div>
          </div>
        </div>
        <Button.Raised
          text={translate("saveChanges")}
          disabled={
            isEmpty(data) ||
            ["firstName", "lastName", "email", "phoneNumber", "role"].some(
              (field) => data.hasOwnProperty(field) && !data?.[field]
            )
          }
          onClick={() =>
            dispatch(
              updateInstructor({
                data: {
                  _id: editData?._id,
                  firstName: editData?.firstName,
                  lastName: editData?.lastName,
                  ...data,
                },
                onSuccess: () => {
                  setEditData({})
                  setData({})
                },
              })
            )
          }
        />
      </div>
    </div>
  )
}

export default InstructorEdit
