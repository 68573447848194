import React, { useState, useEffect, useMemo } from 'react'
import { Button } from '@makedonski/admin-ui'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { omit } from 'lodash'
import { startLoading, getTraining, createTraining, updateTraining, deleteTraining, getStudent } from '../../actions'
import { Training as TrainingComp } from '../../components'
import { translate } from '../../translations/localization'
import './styles.scss'

const omitFields = ['_id', '__v', 'updatedAt', 'id', 'createdAt', 'deletedAt']
const requiredField = ['type']

const Training = ({ }) => {
  const { id, student: studentId } = useParams()
  const { student } = useSelector(({ students }) => students)
  const training = useMemo(() => student?._trainings?.find(({ _id }) => _id === id), [student, id])
  const isCreate = useMemo(() => id === 'create', [id])

  const history = useHistory()
  const onSuccess = () => history.goBack()

  const [data, setData] = useState()
  const handleChange = (payload) => setData({ ...data, ...payload })

  const dispatch = useDispatch()
  useEffect(() => {
    if (!isCreate) dispatch(getTraining({ payload: id, onSuccess: (res) => setData(omit(res, omitFields)) }))
    dispatch(getStudent(studentId))
  }, [])

  const [showRequired, setShowRequired] = useState(false)
  const isRequred = (field) => showRequired && !data?.[field] && 'required'
  const validate = () => !requiredField.some((field) => !data?.[field])

  return (
    <div className="screen-training-container">
      <h2>{translate("TrainingHeader")}</h2>
      <TrainingComp.Form data={data} handleChange={handleChange} />
      <div className="row row-buttons">
        {!isCreate && student?._trainings?.length > 1 && (
          <Button.Raised
            text={translate("Delete")}
            className="red"
            onClick={() => {
              dispatch(startLoading())
              dispatch(deleteTraining({ payload: id, onSuccess }))
            }}
          />
        )}
        <Button.Raised
          text={isCreate ? translate("Add") : translate("Save")}
          className="blue"
          onClick={() => {
            if (!validate()) {
              setShowRequired(true)
              return
            } else {
              dispatch(startLoading())
              if (isCreate)
                dispatch(
                  createTraining({
                    payload: {
                      ...omit(data, 'practiceProgress'),
                      student: studentId,
                      remainingMinutes: data?.practiceProgress ? ((data?.totalHours ?? 31) - data?.practiceProgress) * 50 : undefined,
                    },
                    onSuccess: ({ _id }) => history.push(`/student/${studentId}?training=${_id}`),
                  })
                )
              else {
                const totalHours = data?.totalHours ?? training?.totalHours ?? 31
                let remainingMinutes = ![undefined, null].includes(data?.practiceProgress)
                  ? (totalHours - data?.practiceProgress) * 50
                  : data?.remainingMinutes || training?.remainingMinutes
                if (training?.remainingMinutes === 0 && data?.totalHours > training?.totalHours) remainingMinutes = (data?.totalHours - training?.totalHours) * 50
                dispatch(updateTraining({ payload: { ...data, _id: id, type: data?.type?._id || data?.type, remainingMinutes }, onSuccess }))
              }
            }
          }}
        />
      </div>
    </div>
  )
}

export default Training
