import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { Api, Alerts } from '@makedonski/socourt-utilities'
import { omit } from 'lodash'
import { trainingTypes, stopLoading } from '../actions'
import { URL } from '../config/settings'

export const getTraining = (action$) =>
  action$.pipe(
    ofType(trainingTypes.GET_TRAINING),
    switchMap(({ payload, onSuccess }) =>
      Api.get(`${URL}/training/${payload}`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            onSuccess && onSuccess(response?.data || {})
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Грешка!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const createTraining = (action$) =>
  action$.pipe(
    ofType(trainingTypes.CREATE_TRAINING),
    switchMap(({ payload, onSuccess }) =>
      Api.post(`${URL}/training/`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            onSuccess && onSuccess(response?.data || {})
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Грешка!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const updateTraining = (action$) =>
  action$.pipe(
    ofType(trainingTypes.UPDATE_TRAINING),
    switchMap(({ payload, onSuccess }) =>
      Api.put(`${URL}/training/${payload?._id}`, JSON.stringify(omit(payload, ['_id']))).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            onSuccess && onSuccess(response?.data || {})
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Грешка!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const deleteTraining = (action$) =>
  action$.pipe(
    ofType(trainingTypes.DELETE_TRAINING),
    switchMap(({ payload, onSuccess }) =>
      Api.delete(`${URL}/training/${payload}`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            onSuccess && onSuccess(response?.data || {})
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Грешка!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )
