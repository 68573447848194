import React from "react"
import { Button } from "@makedonski/admin-ui"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { logOut } from "../../../actions"
import { translate } from "../../../translations/localization"
import "./styles.scss"

const Settings = ({ hide }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { currentUser } = useSelector((state) => state.general)
  return (
    <div className="popup-settings-container">
      <div className="popup-settings-header row">
        <h2>{translate("profile")}</h2>
        <Button.Icon name="plus" onClick={hide} />
      </div>
      <div className="popup-settings-content">
        <div className="row row-main">
          <div
            className="profile-image"
            style={{
              backgroundImage: `url(${currentUser?.drivingSchool?.school?.logo ||
                require("../../../assets/images/avatar.png").default
                })`,
            }}
          />
          <div className="col">
            <p>
              {currentUser?.drivingSchool?.school?.name}
              <br />
              <span>{currentUser?.drivingSchool?.email}</span>
            </p>
          </div>
        </div>
        {currentUser?.role?.map(({ permissions }) => permissions)?.includes("admin") && (
          <>
            <div
              className="row"
              onClick={() => {
                history.push("/settings")
                hide()
              }}
            >
              <div className="icon icon-settings" />
              <p>{translate("settings")}</p>
            </div>
            <div
              className="row"
              onClick={() => {
                history.push("/school")
                hide()
              }}
            >
              <div className="icon icon-company-profile" />
              <p>{translate("headerProfileSchool")}</p>
            </div>
            <div
              className="row"
              onClick={() => {
                history.push("/balance")
                hide()
              }}
            >
              <div className="icon icon-balance" />
              <p>{translate("digibalance")}</p>
            </div>
          </>
        )}
        <div className="row">
          <div className="icon icon-help" />
          <p onClick={() => {
            history.push("/support")
            hide()
          }}>{translate("help")}</p>
        </div>
        <div className="row" onClick={() => dispatch(logOut())}>
          <div className="icon icon-logout" />
          <p>{translate("logOut")}</p>
        </div>
      </div>
    </div>
  )
}

export default Settings
