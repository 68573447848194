import React, { useMemo } from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { maxBy } from 'lodash'
import { translate } from '../../translations/localization'
import { sessionTypes } from '../../config/constants'
import './styles.scss'


const Session = ({ session, type }) => {
  const history = useHistory()
  const { _id, student, avgSpeed, distance, duration, start, startedAt, end, endedAt, training, maxSpeed } = session || {}

  const mapTypes = useMemo(() => {
    return { ...sessionTypes.reduce((acc, { value, label }) => ({ ...acc, [value]: label }), {}), initial: '', }
  }, [])

  return (
    <div className="session-component-container" onClick={() => history.push(`/session_${_id}`)}>
      <div className="session-component-header row">
        <h2>{moment((startedAt || start)).format('DD MMMM - dddd')}</h2>
        {startedAt
          ? <div className="with-mobile row">
            <h3>С мобилно приложение</h3>
            <div className="icon icon-phone-blue"></div>
          </div>
          : <div className="without-mobile row">
            <h3>Без мобилно приложение</h3>
            <div className="icon icon-phone-custom"></div>
          </div>}

      </div>
      <div className="session-component-content row">
        <div className="session-content col">
          <div className="session-data row">
            {[
              { label: translate("Start") + ":", value: moment(startedAt || start).format("HH:mm") },
              { label: translate("End") + ":", value: moment(endedAt || end).format("HH:mm") },
              { label: translate("duration"), value: duration + " мин" },
              {
                label: translate("durationInHours"),
                value: `${~~(((duration || 1) - 1) / 50) + 1} час${~~(duration / 50) === 0 ? '' : 'a'}`,
              },
              { label: translate("sessionStudent"), value: student?.fullName, id: "student" },
              { label: translate("sessionEducation"), value: training?.type?.name, id: "training" },
              { label: translate("type"), value: mapTypes[session?.type] || '—' },
              { label: translate("sessionDistance"), value: `${distance ?? '—'} км` },
              { label: translate("sessionMedSpeed"), value: `${avgSpeed ?? '—'} км/ч` },
              {
                label: translate("sessionMaxSpeed"),
                value: `${maxSpeed ?? '—'} км/ч`,
              },
            ]
              .filter(({ id }) => !['student'].includes(type) || !['student', "training"].includes(id))
              .map(({ label, value, id }, i) => <div className="col" key={`session-data-${i}`}>
                <p className='row'>{label} <span>{value}</span>
                  {id === 'student' && <span
                    className="icon icon-profile"
                    onClick={(e) => {
                      e.stopPropagation()
                      history.push(`/student/${student?._id}`)
                    }} />}
                </p>
              </div>)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Session
