import { ofType, ActionsObservable } from "redux-observable"
import { switchMap, catchError } from "rxjs/operators"
import { Api, Alerts } from "@makedonski/socourt-utilities"
import {
  sessionsTypes,
  sharedTypes,
  studentsTypes,
  instructorsTypes,
  stopLoading,
} from "../actions"
import { URL } from "../config/settings"
import { history } from "../config/store"

export const addComment = (action$) =>
  action$.pipe(
    ofType(sharedTypes.ADD_COMMENT),
    switchMap(({ payload }) =>
      Api.post(`${URL}/comments`, JSON.stringify({ ...payload })).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (payload?.refType === "Sessions")
              obs.next({
                type: sessionsTypes.GET_SESSION,
                payload: response.comment.ref._id,
              })
            else if (payload?.refType === "Students")
              obs.next({
                type: studentsTypes.GET_STUDENT,
                payload: response.comment.ref._id,
              })
            else if (payload?.refType === "InstructorDetails")
              obs.next({
                type: instructorsTypes.GET_INSTRUCTOR,
                payload: response.comment.ref.user,
              })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )
