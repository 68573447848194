import React, { useState, useEffect } from 'react'
import { Button, Input } from '@makedonski/admin-ui'
import moment from 'moment'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { startLoading, getReference, setReferencesField } from '../../actions'
import { Shared, Inputs } from '../../components'
import { referencesFields, referencesTypes } from '../../config/constants'
import * as renderField from './renderField'
import * as sortingFunction from './sortingFunction'
import './styles.scss'
import { isEmpty } from 'lodash'

const References = ({ }) => {
  const { type, subtype } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  const { from, to, overall, finished, newStudents, newCourses, income, obligation } = useSelector((state) => state.references)

  useEffect(() => {
    dispatch(startLoading())
    dispatch(getReference('overall'))
  }, [from, to])
  useEffect(() => {
    if (subtype) {
      dispatch(startLoading())
      dispatch(getReference(subtype || type))
    }
  }, [type, subtype, from, to])

  //prettier-ignore
  useEffect(() => {
    switch (type) {
      case "general":
        if (!subtype || !referencesTypes[0].subtypes.map(({ value }) => value).includes(subtype))
          history.push("/references/general/finished")
        break
      case "certificates":
        if (subtype) history.push("/references/certificates")
        break
      default:
        history.push("/references/general/finished")
    }
  }, [type, subtype])

  const props = {
    className: subtype || type,
    fields: referencesFields[subtype || type],
    sortingFunction: sortingFunction.newStudents,
    renderField: renderField.newStudents,
    data: newStudents || income || obligation,
  }

  console.log(finished);

  const renderContent = () => {
    switch (type) {
      case 'general':
        switch (subtype) {
          case 'finished':
            return (
              <Shared.List
                {...props}
                //prettier-ignore
                fields={Object.keys(finished || [])?.reduce((acc, key, i) => [...acc, { label: key, value: key, size: 400 },], [])}
                data={!isEmpty(finished) ? [finished] : []}
                fixedWidth
              />
            )
          case 'newStudents':
            return <Shared.List {...props} />
          case 'newCourses':
            return <Shared.List {...props} data={newCourses} />
          case 'income':
            return <Shared.List {...props} fixedWidth />
          case 'obligation':
            return <Shared.List {...props} fixedWidth />
          default:
            return null
        }
      case 'certificates':
        return <Shared.List {...props} data={[]} />
      default:
        return null
    }
  }

  return (
    <div className="screen-references-container">
      <div className="screen-references-inner-container">
        <div className="screen-references-header row">
          <div className="row row-buttons">
            {/* {referencesTypes?.map(({ label, value }) => (
              <div
                key={`tab-type-${value}`}
                className={`single-tab ${value === type && 'selected'}`}
                onClick={() => history.push(`/references/${value}`)}>
                {label}
              </div>
            ))}*/}
          </div>
          <div className="row row-dates">
            <span>От</span>
            <Input.Datepicker
              minDate={moment().subtract(10, 'years').toDate()}
              pickDate={from}
              maxDate={to}
              onChange={(value) => dispatch(setReferencesField({ field: 'from', value }))}
            />
            <span>До</span>
            <Input.Datepicker
              minDate={from}
              maxDate={new Date()}
              pickDate={to}
              onChange={(value) => dispatch(setReferencesField({ field: 'to', value }))}
            />
          </div>
        </div>
        <div className={`screen-references-content ${type !== 'general' && 'expand'}`}>{renderContent()}</div>
        {type === 'general' && (
          <div className="screen-references-footer row">
            <Inputs.RoundedTab
              width={220}
              buttons={referencesTypes[0]?.subtypes?.map(({ value, label }) => ({
                value,
                //prettier-ignore
                label: `${label} ${(overall && overall[value === "finished" ? "finishedCourses" : value]) ?? "--"} ${["income", "obligation"].includes(value) ? "лв." : ""}`,
              }))}
              value={subtype}
              onChange={({ value }) => history.push(`/references/general/${value}`)}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default References
