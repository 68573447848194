import React, { useState } from "react"
import { Portal } from "react-overlays"
import { Button, Input } from "@makedonski/admin-ui"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import {
  setOverlay,
  createPermit,
  updatePermit,
  deletePermit,
} from "../../../actions"
import { translate } from "../../../translations/localization"
import "./styles.scss"

const Form = ({ course }) => {
  const { _id, permit } = course || {}
  const dispatch = useDispatch()
  const hide = () => dispatch(setOverlay({ isOpen: false }))

  const [data, setData] = useState({})

  return (
    <div className="courses-form-container">
      <div className="courses-form-header row">
        <h2>{translate("formHeader")}</h2>
        <Button.Icon name="plus" size="26px" onClick={() => hide()} />
      </div>
      <div className="courses-form-content row">
        <div className="col">
          <span>{translate("formNumber")}</span>
          <Input.Text
            value={data?.number || permit?.number || ""}
            onChange={({ target: { value } }) =>
              setData({ ...data, number: value })
            }
          />
        </div>
        <div className="col">
          <span>{translate("formCreated")}</span>
          <Input.Datepicker
            minDate={moment().subtract(10, 'y').toDate()}
            pickDate={
              data?.dateIssued ||
              (permit?.dateIssued && moment(permit?.dateIssued).toDate()) ||
              null
            }
            onChange={(dateIssued) => setData({ ...data, dateIssued })}
            /*popperContainer={({ children }) => {
              const el = document.getElementById("calendar-portal")
              return <Portal container={el}>{children}</Portal>
            }}*/
          />
        </div>
      </div>
      <div className={`courses-form-footer row ${permit && "btns-small"}`}>
        {permit && (
          <Button.Raised
            text={translate("Delete")}
            className="red"
            onClick={() => {
              dispatch(deletePermit({ _id: permit?._id }))
              hide()
            }}
          />
        )}
        <Button.Raised
          disabled={!permit && (!data?.number || !data?.dateIssued)}
          text={permit ? "Редактирай" : "Въведи"}
          onClick={() => {
            if (!permit) dispatch(createPermit({ course: _id, ...data }))
            else
              dispatch(updatePermit({ _id: permit?._id, ...permit, ...data }))
            hide()
          }}
        />
      </div>
    </div>
  )
}

export default Form
