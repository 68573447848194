export const groupsTypes = {
  GET_GROUPS: "groups/GET_GROUPS",
  GET_GROUPS_SUCCESS: "groups/GET_GROUPS_SUCCESS",

  GET_GROUP: "groups/GET_GROUP",
  GET_GROUP_SUCCESS: "groups/GET_GROUP_SUCCESS",

  CREATE_GROUP: "groups/CREATE_GROUP",
  UPDATE_GROUP: "groups/UPDATE_GROUP",
  DELETE_GROUP: "groups/DELETE_GROUP",
}

export const getGroups = (payload) => ({
  type: groupsTypes.GET_GROUPS,
  payload,
})

export const getGroup = (payload) => ({
  type: groupsTypes.GET_GROUP,
  payload,
})

export const createGroup = (payload) => ({
  type: groupsTypes.CREATE_GROUP,
  payload,
})

export const updateGroup = (payload) => ({
  type: groupsTypes.UPDATE_GROUP,
  payload,
})

export const deleteGroup = (payload) => ({
  type: groupsTypes.DELETE_GROUP,
  payload,
})

export const clearGroups = (payload) => ({
  type: groupsTypes.GET_GROUP_SUCCESS,
  payload: {},
})
