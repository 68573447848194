import React, { useState, useEffect } from "react"
import { Popover } from "@varld/popover"
import { Button, Input } from "@makedonski/admin-ui"
import { useParams } from "react-router-dom"
import { isEmpty } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import {
  getFirm,
  clearFirms,
  createFirm,
  updateFirm,
  deleteFirm,
  startLoading,
  stopLoading,
} from "../../actions"
import { Popup } from "../../components"
import { uploadFiles } from "../../utilities"
import { translate } from "../../translations/localization"
import "./styles.scss"

const Firm = ({}) => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { firm } = useSelector((state) => state.firms)

  const [data, setData] = useState({})
  const handleChange = (field, value) => setData({ ...data, [field]: value })
  useEffect(() => {
    id !== "create" && dispatch(getFirm(id))
    return () => dispatch(clearFirms())
  }, [])

  const files = data?.files || firm?.files
  const handleUpload = (newFiles) => {
    if (isEmpty(newFiles)) return
    dispatch(startLoading())
    uploadFiles(newFiles).then((data) => {
      handleChange("files", [
        ...(files || []),
        ...data.map(({ location }, i) => ({
          name: newFiles[i].name,
          url: location,
        })),
      ])
      dispatch(stopLoading())
    })
  }

  const [showRequired, setShowRequired] = useState(false)
  //prettier-ignore
  const isRequred = (field) => showRequired && !data?.[field] && !firm?.[field] && "required"
  //prettier-ignore
  const validate = () => !["name", "eic", "mol", "address", "contactName", "contactPhone", "email"].some((field) => !data?.[field] && !firm?.[field])

  return (
    <div className="screen-firm-container">
      <h2>{translate("firmAdd")}</h2>
      <div className="row">
        <div className="col">
          <span className="input-label">
            {translate("balanceNameFirm")}
            <span className="icon icon-required" />
          </span>
          <Input.Text
            value={data?.name ?? firm?.name ?? ""}
            onChange={({ target: { value } }) => handleChange("name", value)}
            inputClassName={`${isRequred("name")}`}
          />
        </div>
        <div className="col">
          <span className="input-label">
          {translate("firmEIK")} <span className="icon icon-required" />
          </span>
          <Input.Text
            value={data?.eic ?? firm?.eic ?? ""}
            onChange={({ target: { value } }) => handleChange("eic", value)}
            inputClassName={`${isRequred("eic")}`}
          />
        </div>
        <div className="col">
          <span className="input-label">
          {translate("firmMol")} <span className="icon icon-required" />
          </span>
          <Input.Text
            value={data?.mol ?? firm?.mol ?? ""}
            onChange={({ target: { value } }) => handleChange("mol", value)}
            inputClassName={`${isRequred("mol")}`}
          />
        </div>
        <div className="col">
          <span className="input-label">
          {translate("firmAddress")} <span className="icon icon-required" />
          </span>
          <Input.Text
            value={data?.address ?? firm?.address ?? ""}
            onChange={({ target: { value } }) => handleChange("address", value)}
            inputClassName={`${isRequred("address")}`}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <span className="input-label">
          {translate("firmContact")} <span className="icon icon-required" />
          </span>
          <Input.Text
            value={data?.contactName ?? firm?.contactName ?? ""}
            onChange={({ target: { value } }) =>
              handleChange("contactName", value)
            }
            inputClassName={`${isRequred("contactName")}`}
          />
        </div>
        <div className="col">
          <span className="input-label">
            {translate("Phone")} <span className="icon icon-required" />
          </span>
          <Input.Text
            value={data?.contactPhone ?? firm?.contactPhone ?? ""}
            onChange={({ target: { value } }) =>
              handleChange("contactPhone", value)
            }
            inputClassName={`${isRequred("contactPhone")}`}
          />
        </div>
        <div className="col">
          <span className="input-label">
            {translate("Email")} <span className="icon icon-required" />
          </span>
          <Input.Text
            value={data?.email ?? firm?.email ?? ""}
            onChange={({ target: { value } }) => handleChange("email", value)}
            inputClassName={`${isRequred("email")}`}
          />
        </div>
      </div>
      <div className="row">
        <div className="col col-big">
          <div className="row row-no-margin">
            <div className="col">
              <span>{translate("firmFiles")}</span>
              <Button.UploadButton
                text={<Button.Raised text="Добави" className="blue" />}
                onChange={({ target: { files } }) => handleUpload(files)}
                accept="*"
              />
            </div>
            <div className="row row-files row-no-margin">
              {files?.map(({ url, name }, i) => (
                <div
                  key={`file-${i}`}
                  className="single-file row row-no-margin"
                >
                  <Button.Icon
                    name="plus"
                    color="#ed1d5d"
                    onClick={() => {
                      const newFiles = files?.slice()
                      files.splice(i, 1)
                      handleChange("files", newFiles)
                    }}
                  />
                  <a href={url || ""} target="_blank" rel="noopener noreferrer">
                    <p>{name}</p>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row row-no-margin row-buttons">
          <>
            {id !== "create" && (
              <Button.Raised
                text={translate("Delete")}
                className="red"
                onClick={() => {
                  dispatch(startLoading())
                  dispatch(deleteFirm({ _id: id }))
                }}
              />
            )}
            <Button.Raised
              text={id === "create" ? "Добави" : "Запази"}
              className="blue"
              onClick={() => {
                if (!validate()) {
                  setShowRequired(true)
                  return
                }
                dispatch(startLoading())
                if (id === "create") dispatch(createFirm(data))
                else dispatch(updateFirm({ _id: id, ...data }))
              }}
            />
          </>
        </div>
      </div>
    </div>
  )
}

export default Firm
