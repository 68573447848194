import React, { useState, useEffect, useRef } from 'react'
import { Popover } from '@varld/popover'
import { NavLink, withRouter, useLocation, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Popup } from '../'
import { usePreviousLocation, useQuery } from '../../utilities'
import { translate } from '../../translations/localization'
import './styles.scss'

const Header = ({ }) => {
  const history = useHistory()
  const location = useLocation()
  const didGoBack = history.action === 'POP'
  const prevLocation = usePreviousLocation(location?.pathname, didGoBack)
  const [showNotifications, setShowNotifications] = useState(false)

  let linksRef = useRef({})
  let activeLinkRef = useRef()
  useEffect(() => {
    setTimeout(() => detectRoute(location), 100)
    history.listen((location, action) => detectRoute(location))
  }, [])
  const detectRoute = ({ pathname }) => {
    let currentLoc = linksRef?.current?.[pathname]
    if (pathname.includes('managment')) currentLoc = linksRef?.current?.['/managment']
    else if (pathname.includes('references')) currentLoc = linksRef?.current?.['/references']

    if (currentLoc) {
      const left = currentLoc.offsetLeft
      const { width } = currentLoc.getBoundingClientRect()
      activeLinkRef?.current?.setAttribute('style', `left: ${left}px; width: ${width}px;`)
      if (activeLinkRef?.current?.offsetHeight === 0) {
        setTimeout(() => {
          activeLinkRef.current?.classList.add('selector-add-height')
        }, 300)
      }
    } else activeLinkRef?.current?.setAttribute('style', `left: ${0}px; width: ${0}px;`)
  }

  const { currentUser } = useSelector((state) => state.general)
  const { showActiveStudents, handleUrlChange } = useQuery()

  return (
    <div className="header-container row">
      {showActiveStudents ? (
        <div className="back-button-container row" onClick={() => handleUrlChange("showActiveStudents", null)}>
          <div className="icon icon-back" />
          <p>{translate("headerProfileInstructor")}</p>
        </div>
      ) : location.pathname.includes('session') ? (
        <div className="back-button-container row" onClick={() => history.goBack()}>
          <div className="icon icon-back" />
          <p>
            {prevLocation?.includes('student/')
              ? translate("headerProfileStudent")
              : prevLocation?.includes('instructor/')
                ? translate("headerProfileInstructor")
                : translate("headerCheckGraphic")}
          </p>
        </div>
      ) : ['instructor/', '/school/courses', '/school/edit'].some((path) => location.pathname.includes(path)) ? (
        <div className="back-button-container row" onClick={() => history.goBack()}>
          <div className="icon icon-back" />
          <p>
            {prevLocation?.includes('school')
              ? translate("headerProfileSchool")
              : prevLocation?.includes('session')
                ? translate("headerCheckSession")
                : ['student', 'managment'].some((path) => prevLocation?.includes(path))
                  ? translate("headerManagement")
                  : translate("headerCheckGraphic")}
          </p>
        </div>
      ) : location.pathname.includes('student/') ? (
        <div className="back-button-container row" onClick={() => history.goBack()}>
          <div className="icon icon-back" />
          <p>{prevLocation?.includes('session') ? translate("headerCheckSession") : translate("headerManagement")}</p>
        </div>
      ) : location.pathname.includes('invite') ? (
        <div className="back-button-container row" onClick={() => history.goBack()}>
          <div className="icon icon-back" />
          <p>
            {prevLocation?.includes('student/')
              ? translate("headerProfileStudent")
              : prevLocation?.includes('instructor/')
                ? translate("headerProfileInstructor")
                : translate("headerCheckGraphic")}
          </p>
        </div>
      ) : ['/training/'].some((path) => location.pathname.includes(path)) ? (
        <div className="back-button-container row" onClick={() => history.goBack()}>
          <div className="icon icon-back" />
          <p>{translate("headerStudent")}</p>
        </div>
      ) : ['/vehicle/', '/group/', '/firm/', '/student/create', '/instructor/create'].some((path) => location.pathname.includes(path)) ? (
        <div className="back-button-container row" onClick={() => history.goBack()}>
          <div className="icon icon-back" />
          <p>{translate("headerManagement")}</p>
        </div>
      ) : (
        <div className="header-logo row" onClick={() => history.push('/')}>
          <img alt="Logo" src={require('../../assets/images/logo.png').default} />
        </div>
      )}
      <div className="header-links row">
        <NavLink
          exact
          to="/"
          ref={(ref) => {
            linksRef.current['/'] = ref
          }}>
          {translate("headerGraphic")}
        </NavLink>
        <NavLink
          to="/managment"
          ref={(ref) => {
            linksRef.current['/managment'] = ref
          }}>
          {translate("headerManagement")}
        </NavLink>
        {currentUser?.role?.map(({ permissions }) => permissions)?.includes('admin') && (
          <NavLink
            to="/references"
            ref={(ref) => {
              linksRef.current['/references'] = ref
            }}>
            {translate("headerReference")}
          </NavLink>
        )}
        <div
          ref={(ref) => {
            activeLinkRef.current = ref
          }}
          className="active-route-selector"
        />
      </div>
      <div className="header-buttons row">
        <div className="notifications-container ripple" onClick={() => setShowNotifications(true)}>
          <div className="icon icon-notifications" />
          <div className="indicator" />
        </div>
        <Popup.Notifications
          showNotifications={showNotifications}
          setShowNotifications={(value) => setShowNotifications(value)}
        />
        <Popover popover={({ visible, close }) => <Popup.Settings hide={close} />}>
          <div
            className="profile ripple"
            style={{
              backgroundImage: `url(${currentUser?.avatar || require('../../assets/images/avatar.png').default})`,
            }}
          />
        </Popover>
      </div>
    </div>
  )
}

export default withRouter(Header)
