import { combineReducers } from "redux"
import { routerReducer } from "react-router-redux"

import general from "./general"
import sessions from "./sessions"
import students from "./students"
import school from "./school"
import instructors from "./instructors"
import groups from "./groups"
import vehicles from "./vehicles"
import firms from "./firms"
import courses from "./courses"
import references from "./references"
import balance from "./balance"

export default combineReducers({
  routerReducer,
  general,
  sessions,
  students,
  school,
  instructors,
  groups,
  vehicles,
  firms,
  courses,
  references,
  balance,
})
