import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react"
import { Input } from "@makedonski/admin-ui"
import moment from "moment"
import { useParams } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { addComment } from "../../../actions"
import "./styles.scss"

const Comments = forwardRef(({ comments, refId, refType = "Sessions", editCompletedSession }, ref) => {
  const { id } = useParams()
  const [text, setText] = useState()

  const dispatch = useDispatch()
  const { avatar } = useSelector((state) => state.general)
  const handleSend = () => {
    if (!text || editCompletedSession) return
    dispatch(addComment({ refType, ref: refId || id, text }))
    setText("")
  }

  const scrollRef = useRef()
  useEffect(() => {
    if (scrollRef?.current)
      scrollRef.current.scrollIntoView({ behavior: "smooth" })
  }, [scrollRef?.current, comments])

  useImperativeHandle(ref, () => ({ text, setText }), [text])

  return (
    <div className="inputs-comments-container">
      <div className="comments-scroll-container col">
        {comments?.map(({ owner, createdAt, text }, i) => (
          <div key={`single-comment-${i}`} className="single-comment-container">
            <div className="row row-details">
              <div className="single-member row">
                {owner?.avatar ? (
                  <div
                    className="profile-image"
                    style={{ backgroundImage: `url(${owner?.avatar})` }}
                  />
                ) : (
                  <div className="icon icon-profile no-pointer" />
                )}
                <p className="member-fullName">{owner?.fullName}</p>
              </div>
              <p>
                {createdAt && moment(createdAt).format("HH:mm | DD.MM.YYYY")}
              </p>
            </div>
            <div className="row row-text">
              <p>{text}</p>
            </div>
          </div>
        ))}
        <div ref={scrollRef} />
      </div>

      <div className="comment-input row">
        <div className="text-input-outer-container">
          <Input.Text
            placeholder={editCompletedSession ? 'Въведете коментар към редакцията' : "Добавете коментар към обучението"}
            value={text || ""}
            onChange={({ target: { value } }) => setText(value)}
            onKeyDown={({ key }) => key === "Enter" && handleSend()}
            inputClassName={editCompletedSession && !text && "required"}
          />
          {!editCompletedSession && <div className="icon ripple" onClick={() => handleSend()} />}
        </div>
      </div>
    </div>
  )
})

export default Comments
