import io from "socket.io-client"
import { Auth } from "@makedonski/socourt-utilities"
import { ofType, ActionsObservable } from "redux-observable"
import { map, mergeMap, catchError, switchMap } from "rxjs/operators"
import { fromEvent } from "rxjs"
import { socketTypes, sessionsTypes } from "../actions"
import { URL } from "../config/settings"

let socket = null

export const connectSocket = (action$) =>
  action$.pipe(
    ofType(socketTypes.SOCKET_CONNECT),
    map(({ payload }) => {
      socket = io(URL, {
        query: `token=${Auth.getToken()}&session=${payload}&isWeb=true`,
      })
      return { type: socketTypes.SOCKET_CONNECTED }
    })
  )

export const socketListener = (action$) =>
  action$.pipe(
    ofType(socketTypes.SOCKET_CONNECTED),
    mergeMap(() =>
      fromEvent(socket, "socket-message").pipe(
        map(({ action, payload }) => {
          return { type: action, payload }
        }),
        catchError((error) =>
          Alerts.error("Грешка!", err?.response?.error?.message || "")
        )
      )
    )
  )

export const disconnectSocket = (action$) =>
  action$.pipe(
    ofType(socketTypes.SOCKET_DISCONNECT),
    switchMap(() =>
      ActionsObservable.create((obs) => {
        if (socket) socket.disconnect()
        obs.complete()
      })
    )
  )

export const sessionEnded = (action$) =>
  action$.pipe(
    ofType("socket/END_SESSION"),
    switchMap(({ payload }) =>
      ActionsObservable.create((obs) => {
        obs.next({
          type: sessionsTypes.GET_SESSION_SUCCESS,
          payload: payload.session,
        })

        obs.complete()
      })
    )
  )
