import React, { useState, useRef } from 'react'
import { Button } from '@makedonski/admin-ui'
import { useSelector, useDispatch } from 'react-redux'
import { setOverlay } from '../../../actions'
import { Shared, Courses, Inputs } from '../../'
import { useOnClickOutside } from '../../../utilities'
import './styles.scss'

const Overlay = ({}) => {
  const { isOpen, type, props } = useSelector((state) => state.general.overlay)
  const dispatch = useDispatch()
  const hide = () => dispatch(setOverlay({ isOpen: false, type }))
  const containerRef = useRef()
  useOnClickOutside(containerRef, () => type !== 'course' && hide())

  const renderType = () => {
    if (!isOpen) return null
    switch (type) {
      case 'course':
        return <Courses.Form {...props} />
      case 'instructor':
      case 'student':
        return (
          <>
            <div className="popup-overlay-header row">
              {tabs?.map((tab, i) => (
                <h2
                  key={`tab-${i}`}
                  onClick={() => setSelected(tab)}
                  className={`single-tab ${tab === selected && 'selected'}`}>
                  {tab}
                </h2>
              ))}
              <Button.Icon name="plus" size="26px" onClick={() => hide()} />
            </div>
            <div className="popup-overlay-content">{render()}</div>
          </>
        )
      default:
        return null
    }
  }

  const tabs = ['Документи', 'Бележки', 'История']
  const [selected, setSelected] = useState(tabs[0])

  const render = () => {
    switch (selected) {
      case 'Документи':
        return <Shared.Documents {...props} />
      case 'Бележки':
        return <Inputs.Comments {...props} />
      case 'История':
        return <Shared.Activity {...props} />
      default:
        return null
    }
  }

  return (
    <div className={`popup-overlay-container ${isOpen && 'open'} ${type}`}>
      <div className="popup-overlay-inner-container" ref={containerRef}>
        {renderType()}
      </div>
    </div>
  )
}

export default Overlay
