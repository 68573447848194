import React, { useEffect } from "react"
import { Button } from "@makedonski/admin-ui"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { isEmpty } from "lodash"
import { getCourses, setOverlay } from "../../../actions"
import { Popup } from "../../../components";
import { translate } from "../../../translations/localization"
import "./styles.scss"

const List = ({}) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { courses } = useSelector((state) => state.courses)
  useEffect(() => {
    dispatch(getCourses())
  }, [])

  const openOverlay = (course) =>
    dispatch(setOverlay({ isOpen: true, type: "course", props: { course } }))

  return (
    <div className="courses-list-container">
      <div className="courses-list-header row">
        <h2>{translate("listEducations")}</h2>
        <Button.Raised
          text="Редакция"
          className="blue"
          onClick={() => history.push("/school/courses")}
        />
      </div>
      <div className="courses-list-content">
        {!isEmpty(courses) && (
          <div className="courses-list-content-header row">
            <span className="big">{translate("listTypeEducation")}</span>
            <span className="small">{translate("listForm")}</span>
            <span className="x-small" />
          </div>
        )}
        <div className="courses-list-content-courses">
          {courses?.map((course) => (
            <div key={`course-${course?._id}`} className="single-course row">
              <span className="big">
                <abbr title={course?.name || "—"}>{course?.name || "—"}</abbr>
              </span>
              <span className="small row">
                {course?.permit ? (
                  `${course?.permit?.number} | ${moment(
                    course?.permit.dateIssued
                  ).format("DD.MM.YYYY")}`
                ) : (
                  <Button.Raised
                    text="Въведи"
                    onClick={() => openOverlay(course)}
                  />
                )}
              </span>
              <span className="x-small">
                {course?.permit && (
                  <div
                    className="icon icon-edit"
                    onClick={() => openOverlay(course)}
                  />
                )}
              </span>
            </div>
          ))}
        </div>
      </div>
      <Popup.Overlay />
    </div>
  )
}

export default List
