import React, { useState, useEffect } from 'react'
import { Button, Input } from '@makedonski/admin-ui'
import { isEmpty } from 'lodash'
import { Inputs } from '../../'
import { translate } from '../../../translations/localization'
import './styles.scss'

const InstructorsAdd = ({ hide, handleApply, selected: selectedProp, options: optionsProp }) => {
  const [query, setQuery] = useState('')
  const [selected, setSelected] = useState(selectedProp)
  const options = optionsProp?.filter(({ fullName }) => fullName?.toLowerCase().includes(query?.toLowerCase()))

  useEffect(() => {
    setSelected(selectedProp)
  }, [selectedProp])

  return (
    <div className="popup-instructors-add-container col">
      <div className="popup-instructors-add-header row">
        <h2>{translate("Instructors")}</h2>
        <Button.Icon name="plus" onClick={() => hide()} size="20px" />
      </div>
      <div className="popup-instructors-add-content col">
        <Input.Text placeholder="Търси" value={query || ''} onChange={({ target: { value } }) => setQuery(value)} />
        <div className="scroll-container">
          {!isEmpty(options) && (
            <Inputs.CheckBoxes
              buttons={[{ label: 'Избери всички', value: options?.length || 0 }]}
              value={[selected?.length]}
              onClick={() => {
                if (isEmpty(selected)) setSelected(options.map(({ _id }) => _id))
                else setSelected(null)
              }}
            />
          )}
          <Inputs.CheckBoxes
            buttons={options?.map(({ _id, fullName }) => ({
              value: _id,
              label: fullName,
            }))}
            value={selected}
            onClick={(value) => setSelected(value)}
            col
          />
        </div>
      </div>
      <div className="popup-instructors-add-footer col">
        <Button.Raised
          text="Избери"
          className="btn-apply blue"
          onClick={() => {
            handleApply && handleApply(selected)
            hide()
          }}
        />
      </div>
    </div>
  )
}

export default InstructorsAdd
