export const URL = process.env.REACT_APP_URL || 'https://digi-drive-api-dev.herokuapp.com'
export const MAPBOX_API_KEY = 'pk.eyJ1IjoiaW9ua28iLCJhIjoiY2wwcnQzd205MDUxZDNqczBtZ2YxeXdhNSJ9.obIklcld90kwyuwcNNSWjA'
export const s3Credentials = {
  bucketName: 'dev-fidweb',
  dirName: 'digidrive',
  region: 'eu-central-1',
  accessKeyId: 'AKIA5L7PZ7W5KYJIFZTM',
  secretAccessKey: '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',
}
export const IBAN = 'BG91FINV91501016980147'
export const SUPPORT_URL = process.env.REACT_APP_SUPPORT_URL || 'https://customer-support-dev.herokuapp.com'