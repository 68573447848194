import React, { useState, useEffect, useMemo, useRef } from "react"
import { Tooltip } from '@varld/popover';
import moment from "moment"
import useResizeObserver from "@react-hook/resize-observer"
import { useHistory } from "react-router-dom"
import { isEmpty, times } from "lodash"
import { sessionTypes } from "../../../config/constants";
import { handleMissedSession } from "../handleMissedSession"
import { translate } from "../../../translations/localization"
import "./styles.scss"

const Grid = ({ sessions, date, setDate, weekend, monthly, fetchData }) => {
  const history = useHistory()
  const [daysToShow, setDaysToShow] = useState(
    weekend ? 7 : monthly ? moment(date).daysInMonth() : 5
  )
  useEffect(() => {
    setDaysToShow(weekend ? 7 : monthly ? moment(date).daysInMonth() : 5)
  }, [weekend, monthly, date])

  //Height
  const heightRef = useRef()
  const [containerHeight, setcontainerHeight] = useState(0)
  const [scrollHeight, setScrollHeight] = useState(0)
  useResizeObserver(heightRef, (ref) => {
    setScrollHeight(ref.target.scrollHeight)
    setcontainerHeight(ref.contentRect.height)
  })

  const [cellHeight, setCellHeight] = useState(0)
  useEffect(() => {
    document
      .querySelector(".calendar-navigation")
      .style.setProperty("--days-to-show", weekend ? 7 : 5)
    if (monthly) setCellHeight((scrollHeight - 50) / daysToShow)
    else setCellHeight((containerHeight - 50) / daysToShow - 1)
  }, [scrollHeight, containerHeight, daysToShow])

  //Scroll
  const currentDayRef = useRef()
  useEffect(() => {
    if (currentDayRef?.current)
      currentDayRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      })
  }, [currentDayRef?.current])
  const scrollHours = () =>
    document.querySelectorAll(".current").forEach((session, i) => {
      session.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center",
      })
    })
  useEffect(() => {
    setTimeout(() => scrollHours(), 200)
  }, [date])

  const mapTypes = useMemo(() => {
    return { ...sessionTypes.reduce((acc, { value, label }) => ({ ...acc, [value]: label }), {}), initial: '', }
  }, [])

  return (
    <div
      className={`calendar-content-weekly-view ${!monthly && "remove-height"}`}
      ref={heightRef}
    >
      <div className="calendar-navigation">
        {setDate && (
          <div className="cell cell-arrows row">
            <div className="icon icon-arrow-left" onClick={() => setDate(moment(date).subtract(1, monthly ? "month" : "week").toDate())} />
            <div
              className="icon icon-arrow-right"
              onClick={() => setDate(moment(date).add(1, monthly ? "month" : "week").toDate())}
            />
          </div>
        )}
        {times(daysToShow).map((el, i) => (
          <div
            key={`cell-date-${i}`}
            className={`cell col cell-date ${moment(date).add(i, "days").isSame(moment(), "day") && "today"}`}
            style={{ height: cellHeight }}
            ref={moment(date).add(i, "days").isSame(moment(), "day") ? currentDayRef : null}
          >
            <p className={`bold`}>
              {moment(date).add(i, "days").format("DD [\n]MMMM")}
            </p>
            <p>{moment(date).add(i, "days").format("dddd")}</p>
          </div>
        ))}
      </div>
      {isEmpty(sessions) ? (
        <div className="empty-state col">
          <div className="image" />
          <p>{translate("dailyNoInfo")}</p>
        </div>
      ) : (
        <div className="scroll-container row" style={{ height: monthly ? scrollHeight : containerHeight }} >
          {Object?.keys(sessions)
            ?.sort((a, b) => (a < b ? -1 : 1))
            /*
            Sort by number of sessions
            ?.sort((a, b) => (sessions[a].length > sessions[b].length ? -1 : 1))*/
            ?.map((instructor, i) => (
              <div className="col" key={`instructor-${i}`} style={{ height: scrollHeight }}  >
                {setDate && (
                  <div className=" cell-instructor row">
                    <p>{instructor}</p>
                    <div
                      className="icon icon-profile"
                      onClick={() => history.push(`/instructor/${sessions[instructor][0]?.user?._id}`)}
                    />
                  </div>
                )}
                {times(daysToShow).map((i) => (
                  <div
                    key={`cell-day-${i}`}
                    className="cell cell-day"
                    style={{ height: cellHeight }}
                  >
                    {sessions[instructor]
                      .filter(({ start, startedAt }) => moment(startedAt || start).isSame(moment(date).startOf(monthly ? "month" : "week").add(i, "days"), "day"))
                      .sort((a, b) => new Date(a.startedAt || a.start) - new Date(b.startedAt || b.start))
                      .map(({ _id, student, start, startedAt, end, endedAt, user, status, training, type }) => (
                        <React.Fragment key={`session-${_id}`}>
                          <div
                            className={`${moment(endedAt || end).isBefore(moment())
                              ? status === "pending" ? "expired missed" : "expired"
                              : moment(startedAt || start).isAfter(moment()) ? "upcomming" : "current"
                              } row signle-session`}
                            onClick={() => moment(endedAt || end).isBefore(moment()) && status === "pending"
                              ? handleMissedSession(student, _id, endedAt || end, fetchData, training, type)
                              : history.push(`/session_${_id}`)
                            }
                          >
                            <p>
                              {moment(startedAt || start).format("HH:mm")}
                              {(endedAt || end) && ` - ${moment(endedAt || end).format("HH:mm")}`} | {student?.fullName}
                            </p>
                            <Tooltip content={<p>{mapTypes[type]}</p>}>
                              <p className="session-type">{mapTypes[type].slice(0, 1)}</p>
                            </Tooltip>
                          </div>
                        </React.Fragment>
                      )
                      )}
                  </div>
                ))}
              </div>
            ))}
        </div>
      )}
    </div >
  )
}

export default Grid
