import { firmsTypes } from "../actions"

const initialState = {
  firms: [],
  firm: {},
}

const firms = (state = initialState, { type, payload }) => {
  switch (type) {
    case firmsTypes.GET_FIRMS_SUCCESS:
      return { ...state, firms: payload }
    case firmsTypes.GET_FIRM_SUCCESS:
      return { ...state, firm: payload }
    default:
      return state
  }
}

export default firms
