import { ofType, ActionsObservable } from "redux-observable"
import { switchMap, catchError, debounceTime } from "rxjs/operators"
import { Api, Alerts } from "@makedonski/socourt-utilities"
import moment from "moment"
import {
  instructorsTypes,
  schoolTypes,
  authTypes,
  startLoading,
  stopLoading,
} from "../actions"
import { URL } from "../config/settings"
import { history, store } from "../config/store"

export const getRoles = (action$) =>
  action$.pipe(
    ofType(instructorsTypes.GET_ROLES),
    switchMap(({ payload }) =>
      Api.get(`${URL}/roles`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            payload(response.roles)
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const getInstructors = (action$) =>
  action$.pipe(
    ofType(instructorsTypes.GET_INSTRUCTORS),
    switchMap(({ payload }) =>
      Api.get(`${URL}/user/instructors`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: instructorsTypes.GET_INSTRUCTORS_SUCCESS,
              payload: response?.users,
            })
            payload && payload(response?.users)
            obs.next(stopLoading())

            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.complete()
          })
        )
      )
    )
  )

export const getInstructor = (action$) =>
  action$.pipe(
    ofType(instructorsTypes.GET_INSTRUCTOR),
    switchMap(({ payload }) =>
      Api.get(`${URL}/user/${payload}`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: instructorsTypes.GET_INSTRUCTOR_SUCCESS,
              payload: { ...response.user, sessions: [] },
            })
            obs.next({
              type: instructorsTypes.GET_INSTRUCTOR_SESSIONS,
              payload: { user: response.user._id, page: 1 },
            })
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const createInstructor = (action$) =>
  action$.pipe(
    ofType(instructorsTypes.CREATE_INSTRUCTOR),
    switchMap(({ payload }) =>
      Api.post(`${URL}/instructors`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            history.push(`/managment/instructors`)
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const inviteInstructors = (action$) =>
  action$.pipe(
    ofType(instructorsTypes.INVITE_INSTRUCTORS),
    switchMap(({ payload: users }) =>
      Api.post(`${URL}/user/create`, JSON.stringify({ users })).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            history.goBack()
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const updateInstructor = (action$) =>
  action$.pipe(
    ofType(instructorsTypes.UPDATE_INSTRUCTOR),
    switchMap(({ payload: { data, onSuccess, isCurrentUser } }) =>
      Api.put(`${URL}/user/update`, JSON.stringify(data)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            onSuccess && onSuccess()
            isCurrentUser && obs.next({ type: authTypes.CHECK_USER })
            obs.next({ type: schoolTypes.GET_SCHOOL })
            obs.next({
              type: instructorsTypes.GET_INSTRUCTOR,
              payload: response.user._id,
            })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const updateInstructorDetails = (action$) =>
  action$.pipe(
    ofType(instructorsTypes.UPDATE_INSTRUCTOR_DETAILS),
    switchMap(({ payload: { data, onSuccess } }) =>
      Api.put(`${URL}/user/update-details`, JSON.stringify(data)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            onSuccess && onSuccess()
            obs.next({
              type: instructorsTypes.GET_INSTRUCTOR,
              payload: response.user._id,
            })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const deleteInstructor = (action$) =>
  action$.pipe(
    ofType(instructorsTypes.DELETE_INSTRUCTOR),
    switchMap(({ payload: { data, onSuccess } }) =>
      Api.delete(`${URL}/user`, JSON.stringify({ _id: data })).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            onSuccess && onSuccess()
            obs.next({ type: schoolTypes.GET_SCHOOL })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const setInstructorSessionsFilter = (action$) =>
  action$.pipe(
    ofType(instructorsTypes.SET_INSTRUCTOR_SESSIONS_FILTER),
    switchMap(({ payload }) =>
      ActionsObservable.create((obs) => {
        if (payload?.fetch !== false) {
          obs.next(startLoading())
          obs.next({
            type: instructorsTypes.GET_INSTRUCTOR_SESSIONS,
            payload: { user: payload._id, page: 1 },
          })
        }
        obs.next({ type: instructorsTypes.RESET_SESSIONS })

        obs.complete()
      })
    )
  )

export const getInstructorSessions = (action$) =>
  action$.pipe(
    ofType(instructorsTypes.GET_INSTRUCTOR_SESSIONS),
    debounceTime(300),
    switchMap(({ payload }) => {
      const date = store.getState().instructors.filter
      const filter = date
        ? {
          startedAt: {
            $gte: moment(date).startOf("day").toDate(),
            $lte: moment(date).endOf("day").toDate(),
          },
          start: {
            $gte: moment(date).startOf("day").toDate(),
            $lte: moment(date).endOf("day").toDate(),
          },
        }
        : null
      return Api.post(
        `${URL}/user/sessions`,
        JSON.stringify({ ...payload, limit: 5, filter })
      ).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: instructorsTypes.GET_INSTRUCTOR_SESSIONS_SUCCESS,
              payload: response.sessions,
            })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    })
  )
