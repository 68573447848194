import { default as RoundedTab } from './RoundedTab'
import { default as CheckBoxes } from './CheckBoxes'
import { default as Comments } from './Comments'
import { default as RadioButtons } from './RadioButtons'
import { default as TextEdit } from './TextEdit'
import { default as Dropdown } from './Dropdown'

const Inputs = { RoundedTab, CheckBoxes, Comments, RadioButtons, TextEdit, Dropdown }

export default Inputs
