import React, { useState, useEffect, useMemo } from 'react'
import { Button, Input } from '@makedonski/admin-ui'
import moment from 'moment'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import {
  getStudent,
  clearStudent,
  startLoading,
  updateStudent,
  setOverlay,
  setStudentSessionsFilter,
  getStudentSessions
} from '../../actions'
import { Student as StudentComp, Session, Popup } from '../../components'
import { translate } from "translations/localization"
import { useQuery } from 'utilities'
import './styles.scss'

const Student = ({ }) => {
  const { id } = useParams()
  const { training } = useQuery()
  const history = useHistory()
  const dispatch = useDispatch()
  const { currentUser, overlay } = useSelector((state) => state.general)
  const { student, filter } = useSelector((state) => state.students)
  const { _id, sessions, files, comments, _activity, nextPage, hasNextPage } = student || {}

  const isCreate = useMemo(() => id === 'create', [id])
  const [editable, setEditable] = useState(isCreate)
  useEffect(() => {
    setEditable(isCreate)
  }, [isCreate])

  useEffect(() => {
    if (!isCreate) {
      dispatch(startLoading())
      dispatch(getStudent(id))
    } else dispatch(clearStudent())
    return () => dispatch(setStudentSessionsFilter({ _id: id, fetch: false }))
  }, [id])

  useEffect(() => {
    if (!isCreate) dispatch(getStudentSessions({ student: id, pageNumber: 1 }))
  }, [training])

  const handlePaggination = (scrollTop, height, scrollHeight) => {
    if (scrollTop + height >= scrollHeight - 10 && hasNextPage) {
      dispatch(startLoading())
      dispatch(getStudentSessions({ student: id, pageNumber: nextPage }))
    }
  }

  const overlayPayload = {
    isOpen: true,
    type: 'student',
    props: {
      files,
      handleChange: (files) => dispatch(updateStudent({ _id, files })),
      comments,
      _activity,
      refType: 'Students',
    },
  }

  useEffect(() => {
    if (overlay?.isOpen) dispatch(setOverlay(overlayPayload))
  }, [student])

  const props = { student, setEditable: (value) => setEditable(value) }

  if (editable)
    return (
      <div className="student-container create">
        <StudentComp.Form isCreate={isCreate} {...props} />
      </div>
    )
  return (
    <div className="student-container row">
      <div className="student-inner-left-container">
        <StudentComp.Profile showDocuments={() => dispatch(setOverlay(overlayPayload))} {...props} />
        <Popup.Overlay />
      </div>
      <div className="student-inner-right-container">
        <div className="student-sessions-header row">
          <div className="title">
            <h2>{translate("Events")} {filter && moment(filter).format('- DD.MM.YYYY')}</h2>
            {filter && <div className="icon icon-delete" onClick={() => dispatch(setStudentSessionsFilter({ _id: id }))} />}
          </div>
          {!isEmpty(sessions) && (
            <Input.Datepicker
              pickDate={filter}
              onChange={(value) => dispatch(setStudentSessionsFilter({ _id: id, date: moment(value).startOf("day").toDate() }))}
              customInput={<div className="icon icon-calendar ripple" />}
              showMonthDropdown
              showYearDropdown
              todayButton={translate("Today")}
              dropdownMode="select"
            />
          )}
          {id !== 'create' && currentUser?.role?.map(({ permissions }) => permissions)?.includes('admin') && (
            <Button.Raised text={translate("Add")} className="blue" onClick={() => history.push('/session_create')} />
          )}
        </div>
        {isEmpty(sessions) ? (
          <div className="box-empty col">
            <div className="image" />
            <p>{translate("StudentNoEvents")}</p>
          </div>
        ) : (
          <div
            className="student-sessions-content"
            onScroll={({ target }) =>
              handlePaggination(
                target.scrollTop,
                target.getBoundingClientRect().height,
                target.scrollHeight
              )
            }
          >
            {sessions?.map((session, i) => (
              <Session key={`session-${i}`} session={session} type="student" />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default Student
