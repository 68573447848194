import React, { useState, useEffect } from 'react'
import { Button, Input } from '@makedonski/admin-ui'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { startLoading, stopLoading, requestCode, checkCode, logIn, register, signUp } from '../../actions'
import { LoginVerification } from '../../components'
import { translate } from "../../translations/localization"
import './styles.scss'

const Login = ({ }) => {
  const { pathname } = useLocation()
  const [showRegister, setShowRegister] = useState(pathname.includes('register'))
  const [data, setData] = useState()
  const handleChange = (payload) => setData({ ...data, ...payload })

  const [showCode, setShowCode] = useState(false)
  const [code, setCode] = useState(new Array(4).fill(''))
  const [codeState, setCodeSate] = useState(null)

  const [phoneNumber, setPhoneNumber] = useState('+359')
  const [disableSMS, setDisableSMS] = useState(false)

  const dispatch = useDispatch()
  const handleRegister = () => {
    dispatch(startLoading())
    dispatch(
      signUp({
        payload: { ...data, phoneNumber },
        onSuccess: (response) => setShowCode(true),
      })
    )
  }
  const handleRequest = () => {
    dispatch(startLoading())
    dispatch(requestCode({
      phoneNumber,
      onSuccess: () => setShowCode(true),
      onError: () => setShowRegister(true),
      onDisable: () => setDisableSMS(true)
    }))
  }
  const handleLogin = () => {
    dispatch(startLoading())
    if (!isEmpty(data)) dispatch(register({ payload: { ...data, phoneNumber, code: code.join('') } }))
    else dispatch(logIn({ phoneNumber, code: code.join('') }))
  }

  useEffect(() => {
    if (!code?.includes(''))
      dispatch(
        checkCode({
          phoneNumber,
          code: code.join(''),
          onSuccess: () => setCodeSate('valid'),
          onFail: () => setCodeSate('invalid'),
        })
      )
    else setCodeSate(null)
  }, [code])

  useEffect(() => {
    const onEnter = ({ key }) => key === 'Enter' && handleLogin()
    if (codeState === "valid") document.addEventListener('keydown', onEnter);
    else document.removeEventListener('keydown', onEnter)
    return () => document.removeEventListener('keydown', onEnter)
  }, [codeState])

  return (
    <div className="login-container row">
      <div className="login-left-container">
        <div className="login-left-inner-container col">
          <img src={require('../../assets/images/logo.png').default} alt="logo" />
          <div className="login-content col">
            <p className="header">
              {showCode ? translate("phoneVer") : showRegister ? translate("Welcome") : translate("LogIntoSystem")}
            </p>
            <p>
              {showCode
                ? translate("inputCode")
                : showRegister
                  ? ''
                  : translate("EnterYourNumber")}
            </p>
            <div className="login-form col">
              {showRegister && !showCode && (
                <>
                  <span>{translate("School")}</span>
                  <Input.Text
                    className="add-margin"
                    value={data?.school || ''}
                    onChange={({ target: { value } }) => handleChange({ school: value })}
                  />
                  <span>{translate("Names")}</span>
                  <Input.Text
                    className="add-margin"
                    value={data?.name || ''}
                    onChange={({ target: { value } }) => handleChange({ name: value })}
                  />
                  <span>{translate("Email")}</span>
                  <Input.Text
                    className="add-margin"
                    value={data?.email || ''}
                    onChange={({ target: { value } }) => handleChange({ email: value })}
                  />
                </>
              )}
              {!showCode && <span>{translate("Phone")}</span>}
              <Input.Text
                placeholder="Телефонен номер"
                value={phoneNumber || ''}
                onChange={({ target: { value } }) => {
                  if (value.length < 4 || value.length > 13) return
                  if (!/^[+]?$|(^[+]{1}[0-9]{1,12}$)/.test(value)) return
                  setPhoneNumber(value)
                }}
                disabled={showCode}
                onKeyDown={({ key }) => key === 'Enter' && phoneNumber.length === 13 && handleRequest()}
              />
              {showCode && (
                <LoginVerification
                  code={code}
                  setCode={(value) => setCode(value)}
                  codeState={codeState}
                  disabled={disableSMS}
                  onSendAgain={() => {
                    handleRequest()
                    setCode(new Array(4).fill(''))
                  }}
                />
              )}
            </div>
          </div>
          <div className="login-buttons">
            {showCode ? (
              <Button.Raised
                text="Вход"
                onClick={() => handleLogin()}
                disabled={codeState !== 'valid'}
                className="btn-login"
              />
            ) : showRegister ? (
              <Button.Raised
                disabled={
                  !phoneNumber?.includes('+359') ||
                  phoneNumber.length !== 13 ||
                  !/^\S+@\S+\.\S+$/.test(data?.email) ||
                  ['school', 'name', 'email'].some((field) => !data?.[field])
                }
                text="Продължи"
                onClick={() => handleRegister()}
                className="btn-code"
              />
            ) : (
              <Button.Raised
                text="Продължи"
                onClick={() => handleRequest()}
                disabled={!phoneNumber?.includes('+359') || phoneNumber.length !== 13}
                className="btn-code"
              />
            )}
            <p>
              {showRegister ? (
                <>
                  {translate("acceptWith")}{' '}
                  <a
                    href="https://dev-fidweb.s3.eu-central-1.amazonaws.com/digidrive_demo/digidrive_tos.pdf"
                    target="_blank"
                    rel="noopener noreferrer">
                    <span className="btn-edit">{translate("termsOfUse")}</span>
                  </a>{' '}
                  и{' '}
                  <a
                    href="https://dev-fidweb.s3.eu-central-1.amazonaws.com/digidrive_demo/digidrive_privacy.pdf"
                    target="_blank"
                    rel="noopener noreferrer">
                    <span className="btn-edit">{translate("Privacy")}</span>
                  </a>
                </>
              ) : (
                showCode && (
                  <>
                    {translate("wrongNumber")}{' '}
                    <span
                      onClick={() => {
                        setShowCode(false)
                        setCode(new Array(4).fill(''))
                      }}
                      className="btn-edit">
                      {translate("newPhoneNumber")}
                    </span>
                  </>
                )
              )}
            </p>
          </div>
        </div>
      </div>
      <div className="login-right-container"></div>
    </div>
  )
}

export default Login
