import { sessionsTypes } from "../actions"

const initialState = {
  sessions: [],
  session: {},
}

const sessions = (state = initialState, { type, payload }) => {
  switch (type) {
    case sessionsTypes.SET_SESSION_FIELDS:
      return { ...state, ...payload }
    case sessionsTypes.GET_SESSIONS_SUCCESS:
      return { ...state, sessions: payload }
    case sessionsTypes.GET_SESSION_SUCCESS:
      return { ...state, session: payload }
    case "socket/NEW_DATA":
      return {
        ...state,
        session: {
          ...state.session,
          data: [...state.session.data, ...payload.newPoints],
        },
      }
    case "socket/NEW_DISTANCE_SET":
      return {
        ...state,
        session: {
          ...state.session,
          duration: payload?.duration,
          avgSpeed: payload?.avgSpeed,
          distance: payload?.distance,
        },
      }
    case 'socket/USERS':
      return { ...state, inSocket: payload }
    case 'socket/USER_IS_OFFLINE':
      return { ...state, inSocket: state?.inSocket?.filter(({ user }) => user !== payload.user) }
    case 'socket/USER_IS_ONLINE':
      return { ...state, inSocket: [...(state?.inSocket || []), payload] }
    default:
      return state
  }
}

export default sessions
