import React, { useState, useEffect, useMemo } from 'react'
import { Button } from '@makedonski/admin-ui'
import { Popover } from '@varld/popover'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { scale, useQuery } from '../../../utilities'
import { translate } from '../../../translations/localization'
import './styles.scss'
import { getStudent, startLoading, updateTraining } from 'actions'
import Swal from 'sweetalert2'

const Profile = ({ student, showDocuments, setEditable }) => {
  const history = useHistory()
  const { currentUser } = useSelector((state) => state.general)

  const { training: trainingId, handleUrlChange } = useQuery()
  const training = useMemo(() => student?._trainings?.find(({ _id }) => _id === trainingId), [student, trainingId])

  const dispatch = useDispatch()

  return (
    <div className="student-profile-container col">
      <div className="student-profile-main-container display">
        <div className="row">
          <div
            className="avatar-container-full row"
            style={{
              backgroundImage: `url(${student?.avatar || require('../../../assets/images/avatar.png').default})`,
            }}
          />
          <div className="col col-big">
            <p className="no-bg">{translate("headerStudent")}</p>
            <p className="header">
              {student?.fullName || ''}
              {student?.age ? ` - ${student?.age} г.` : ''}
            </p>
          </div>
          <div className="row row-buttons">
            <div className="icon icon-details" onClick={() => showDocuments(true)} />
            {currentUser?.role?.map(({ permissions }) => permissions)?.includes('admin') && (
              <div className="icon icon-edit" onClick={setEditable} />
            )}
          </div>
        </div>
        <div className="row">
          <p>
            {translate("EGN")}: <span>{student?.pin ?? '—'}</span>
          </p>
          <p>
            {translate("Phone")}: <span>{student?.phoneNumber ?? '—'}</span>
          </p>
        </div>
        <div className="row">
          <p>
            {translate("email")}: <span>{student?.email ?? '—'}</span>
          </p>
          <p>
            {translate("firm")}: <span>{student?.firm?.name ?? '—'}</span>
          </p>
        </div>
      </div>
      <div className="student-profile-additional-container display">
        <div className="row no-margin row-space-between row-training-type">
          {training?.status === 'finished' && <span className='training-finished'>Завършен</span>}
          <p className="header add-padding">{training?.type?.name || '—'}</p>
          {currentUser?.role?.map(({ permissions }) => permissions)?.includes('admin') && (
            <div className="row">
              {student?._trainings?.length > 1 ? (
                <Popover
                  popover={({ visible, close }) => (
                    <div className="popup-switch-training-conatiner">
                      <div className="row">
                        <p className="bold">{translate("chooseEduc")}</p>
                        <Button.Icon name="plus" onClick={() => close()} size="20px" />
                      </div>
                      {student?._trainings?.map((training, i) => (
                        <p
                          key={`training-${training?._id}`}
                          className={`${trainingId === training?._id && 'bold'}`}
                          onClick={() => {
                            handleUrlChange('training', training?._id)
                            close()
                          }}>
                          {training?.type?.name}
                        </p>
                      ))}
                      <p className="add" onClick={() => history.push(`/training/${student?._id}/create`)}>
                        {translate("addEduc")}
                      </p>
                    </div>
                  )}>
                  <div className="icon icon-swap" />
                </Popover>
              ) : (
                <div
                  className="icon icon-plus-custom"
                  onClick={() => history.push(`/training/${student?._id}/create`)}
                />
              )}
              {training?.status !== 'finished' && <div
                className="icon icon-edit"
                onClick={() => history.push(`/training/${student?._id}/${training?._id}`)}
              />}
            </div>
          )}
        </div>

        <div className="row">
          <p>
            {translate("Number")}: <span>{training?.registrationNumber || '—'}</span>
          </p>
          <p>
            {translate("Paid")}:{' '}
            <span>
              {training?.paidPrice || '—'}/{training?.coursePrice || '—'} лв.
            </span>
          </p>
        </div>
        <div className="row">
          <p>
            {translate("InsideT")}:{' '}
            <span>{training?.innerExamTheory ? moment(training?.innerExamTheory).format('DD.MM.YYYY') : '—'}</span>
          </p>
          <p>
            {translate("InsideP")}:{' '}
            <span>{training?.innerExamPractice ? moment(training?.innerExamPractice).format('DD.MM.YYYY') : '—'}</span>
          </p>
        </div>

        <div className="row row-theory">
          <p className="header"> {translate("Theory")}</p>
        </div>
        <div className="row">
          <p>
            {translate("Start")}: <span>{training?.theoryStart ? moment(training?.theoryStart).format('DD.MM.YYYY') : '—'}</span>
          </p>
          <p>
            {translate("End")}: <span>{training?.theoryEnd ? moment(training?.theoryEnd).format('DD.MM.YYYY') : '—'}</span>
          </p>
        </div>
        <div className="row">
          <p className={`${training?.instructorTheory?.deletedAt && 'deleted'}`}>
            {translate("Teacher")}:{' '}
            <span>
              {training?.instructorTheory?.deletedAt ? 'МОЛЯ ДОБАВЕТЕ' : training?.instructorTheory?.fullName || '—'}
            </span>
          </p>
          <p>
            {translate("Group")}: <span>{training?.theoryGroup?.name || '—'}</span>
          </p>
        </div>
        <div className="row row-practice">
          <div
            className="progress-bar"
            style={{
              width: `${scale(Math.floor(training?.remainingMinutes / 50), training?.totalHours, 0, 0, 100)}%`,
            }}
          />
          <p className="header"> {translate("Practise")}</p>
          <p className="bold">
            {training?.totalHours - Math.floor(training?.remainingMinutes / 50) || '—'} / {training?.totalHours} часа
          </p>
          {/*  <p className="">
            {training?.practiceProgress?.hours || "—"} ч |{" "}
            {training?.practiceProgress?.minutes || "—"} мин
          </p>*/}
        </div>
        {training?.remainingMinutes === 0 && training.status === 'progress' && <>
          <p className='text-finish-course'>Моля потвърдете, че курсистът е завършил успешно курса или добавете още часове</p>
          <div className="row">
            <Button.Raised
              text="Добави часове"
              className="btn-add-hours"
              onClick={() => history.push(`/training/${student?._id}/${training?._id}`)}
            />
            <Button.Raised
              text="Потвърди"
              className="btn-finish-course"
              onClick={() => Swal.fire({
                title: 'ЗАВЪРШВАНЕ НА КУРСА',
                text: 'Вие сте на път да приключите курса на този потребител. Ако потвърдите действието, няма да можете да редактирате информацията в профила му, но ще можете да му добавяте други курсове.',
                imageUrl: require('../../../assets/images/delete.png').default,
                imageWidth: 240,
                imageHeight: 240,
                imageAlt: 'Custom image',
                width: '50vw',
                showCancelButton: true,
                confirmButtonText: `ПОТВЪРДИ`,
                cancelButtonText: `ОТКАЖИ`,
                reverseButtons: true,
                confirmButtonColor: '#0bd84f',
                cancelButtonColor: '#7e7e7e',
              }).then(({ isConfirmed }) => {
                if (isConfirmed) {
                  dispatch(startLoading())
                  dispatch(updateTraining({
                    payload: { _id: trainingId, student: student._id, status: 'finished' },
                    onSuccess: ({ student }) => {
                      Swal.fire({
                        icon: 'success',
                        title: 'Успешно завършен курс',
                        confirmButtonText: `ПРОДЪЛЖИ`,
                        confirmButtonColor: '#0bd84f',
                      })
                      dispatch(getStudent(student))
                    },
                  })
                  )
                }
              })}
            />
          </div>
        </>}
        <div className="row">
          <p>
            {translate("Start")}: <span>{training?.practiceStart ? moment(training?.practiceStart).format('DD.MM.YYYY') : '—'}</span>
          </p>
          <p>
            {translate("End")}: <span>{training?.practiceEnd ? moment(training?.practiceEnd).format('DD.MM.YYYY') : '—'}</span>
          </p>
        </div>
        <div className="row">
          <p className={`${training?.instructorPractice?.deletedAt && 'deleted'}`}>
            {translate("Teacher")}:{' '}
            <span>
              {training?.instructorPractice?.deletedAt
                ? translate("pleaseAdd")
                : training?.instructorPractice?.fullName || '—'}
            </span>
          </p>
          <p>
            {translate("MPS")}:{' '}
            <span>
              {training?.defaultCar?.model || '—'} - {training?.defaultCar?.license || '—'}
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Profile
