import moment from "moment"

//prettier-ignore
export const students = (el, query, searchBy) => {
  if (!query) return true
  if (['firm', 'theoryGroup'].includes(searchBy)) return el?.[searchBy]?.name?.toLowerCase()?.includes(query?.toLowerCase())
  if (['course'].includes(searchBy)) return el?.type?.name?.toLowerCase()?.includes(query?.toLowerCase())
  else if (["instructorTheory", "instructorPractice"].includes(searchBy)) return el?.[searchBy]?.fullName?.toLowerCase()?.includes(query?.toLowerCase())
  else if (["defaultCar"].includes(searchBy)) return el?.[searchBy]?.license?.toLowerCase()?.includes(query?.toLowerCase())
  else if (["theoryStart"].includes(searchBy)) return el?.[searchBy] && moment(el?.[searchBy]).format("DD.MM")?.includes(query?.toLowerCase())
  else return el[searchBy]?.toLowerCase()?.includes(query?.toLowerCase())
}

//prettier-ignore
export const instructors = (el, query, searchBy) => {
  if (!query) return true
  if (["isActiveUser"].includes(searchBy)) return (el?.[searchBy] ? "Да" : "Не").toLowerCase()?.includes(query?.toLowerCase())
  else if (["employeeFrom"].includes(searchBy)) return el?.details?.[searchBy] && moment(el?.details?.[searchBy]).format("DD.MM.YYYY")?.includes(query?.toLowerCase())
  else if (["defaultCar"].includes(searchBy)) return el?.details?.[searchBy]?.license?.toLowerCase()?.includes(query?.toLowerCase())
  else return el[searchBy]?.toLowerCase()?.includes(query?.toLowerCase())
}

//prettier-ignore
export const vehicles = (el, query, searchBy) => {
  if (!query) return true
  if (["instructors"].includes(searchBy)) return (el?.[searchBy].map(({ user }) => user?.fullName).join(", ")).toLowerCase()?.includes(query?.toLowerCase())
  else return el[searchBy]?.toLowerCase()?.includes(query?.toLowerCase())
}

//prettier-ignore
export const groups = (el, query, searchBy) => {
  if (!query) return true
  if (["course"].includes(searchBy)) return el?.[searchBy]?.name?.toLowerCase()?.includes(query?.toLowerCase())
  else if (["start", "end"].includes(searchBy)) return el?.[searchBy] && moment(el?.[searchBy]).format("DD.MM.YYYY")?.includes(query?.toLowerCase())
  else if (["instructors"].includes(searchBy)) return (el?.[searchBy].map(({ fullName }) => fullName).join(", ")).toLowerCase()?.includes(query?.toLowerCase())
  else return el[searchBy]?.toLowerCase()?.includes(query?.toLowerCase())
}

//prettier-ignore
export const firms = (el, query, searchBy) => {
  if (!query) return true
  if (["totalStudents"].includes(searchBy)) return el?.students?.length.toString()?.includes(query?.toLowerCase())
  if (["activeStudents"].includes(searchBy)) return el?.students?.reduce((acc, { remainingMinutes }) => (remainingMinutes ? ( acc + 1 ) : 0), 0).toString()?.includes(query?.toLowerCase())
  else return el[searchBy]?.toLowerCase()?.includes(query?.toLowerCase())
}
