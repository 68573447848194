import { instructorsTypes } from "../actions"

const initialState = {
  instructors: [],
  instructor: {},
  filter: null,
}

const instructors = (state = initialState, { type, payload }) => {
  switch (type) {
    case instructorsTypes.GET_INSTRUCTORS_SUCCESS:
      return { ...state, instructors: payload }
    case instructorsTypes.GET_INSTRUCTOR_SUCCESS:
      return { ...state, instructor: payload }
    case instructorsTypes.GET_INSTRUCTOR_SESSIONS_SUCCESS:
      return {
        ...state,
        instructor: {
          ...state.instructor,
          ...payload,
          sessions: [...state?.instructor?.sessions, ...payload?.docs],
        },
      }
    case instructorsTypes.RESET_SESSIONS:
      return { ...state, instructor: { ...state.instructor, sessions: [] } }
    case instructorsTypes.SET_INSTRUCTOR_SESSIONS_FILTER:
      return { ...state, filter: payload?.date }
    default:
      return state
  }
}

export default instructors
