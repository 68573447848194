export const studentsTypes = {
  GET_STUDENTS: "students/GET_STUDENTS",
  GET_STUDENTS_SUCCESS: "students/GET_STUDENTS_SUCCESS",

  GET_STUDENT: "students/GET_STUDENT",
  GET_STUDENT_SUCCESS: "students/GET_STUDENT_SUCCESS",
  GET_STUDENT_TRAINING: "students/GET_STUDENT_TRAINING",

  CREATE_STUDENT: "students/CREATE_STUDENT",
  UPDATE_STUDENT: "students/UPDATE_STUDENT",
  DELETE_STUDENT: "students/DELETE_STUDENT",

  GET_STUDENT_SESSIONS: "students/GET_STUDENT_SESSIONS",
  GET_STUDENT_SESSIONS_SUCCESS: "students/GET_STUDENT_SESSIONS_SUCCESS",
  SET_STUDENT_SESSIONS_FILTER: "students/SET_STUDENT_SESSIONS_FILTER",
  RESET_SESSIONS: "students/RESET_SESSIONS",
}

export const getStudents = (payload) => ({
  type: studentsTypes.GET_STUDENTS,
  payload,
})

export const getStudent = (payload) => ({
  type: studentsTypes.GET_STUDENT,
  payload,
})

export const createStudent = (payload) => ({
  type: studentsTypes.CREATE_STUDENT,
  ...payload,
})

export const updateStudent = (payload) => ({
  type: studentsTypes.UPDATE_STUDENT,
  payload,
})

export const deleteStudent = (payload) => ({
  type: studentsTypes.DELETE_STUDENT,
  payload,
})

export const clearStudent = () => ({
  type: studentsTypes.GET_STUDENT_SUCCESS,
  payload: {},
})

export const getStudentSessions = (payload) => ({
  type: studentsTypes.GET_STUDENT_SESSIONS,
  payload,
})

export const setStudentSessionsFilter = (payload) => ({
  type: studentsTypes.SET_STUDENT_SESSIONS_FILTER,
  payload,
})