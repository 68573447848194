import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Button, Input } from '@makedonski/admin-ui'
import { Alerts } from '@makedonski/socourt-utilities'
import { Tooltip } from '@varld/popover'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getSession, startLoading, connectSocket, disconnectSocket, updateSessionCompleted, setSessionFields } from '../../actions'
import { Inputs, MapBox } from '../../components'
import { sessionTypes } from '../../config/constants'
import { translate } from '../../translations/localization'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './styles.scss'

const Session = ({ }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams()
  const { currentUser } = useSelector((state) => state.general)
  const { session, inSocket } = useSelector((state) => state.sessions)
  //prettier-ignore
  const { status, type, duration, student, start, startedAt, end, endedAt, avgSpeed, data, distance, car, user, comments, training } = session || {}
  useEffect(() => {
    dispatch(startLoading())
    dispatch(getSession(id))
    return () => dispatch(setSessionFields({ session: {} }))
  }, [])

  useEffect(() => {
    if (status === 'in-action') dispatch(connectSocket(id))
    else if (status === 'completed') dispatch(disconnectSocket())
    return () => dispatch(disconnectSocket())
  }, [status])

  const maxSpeed = data?.reduce((p, c) => (p.speed > c.speed ? p : c), 0)?.speed

  const battery = useMemo(() => (data?.length && data[data?.length - 1]?.battery) || 0, [data])
  const isOnline = useMemo(() => {
    const userInSocket = inSocket?.find((userInSocket) => userInSocket.user === user?._id)
    return userInSocket?.session === id
  }, [inSocket, user])

  const [editCompletedSession, setEditCompletedSession] = useState(false)
  const [editData, setEditData] = useState({})
  const commentRef = useRef(null)
  const handleUpdateSession = () => {
    if (!commentRef.current.text) {
      Alerts.error('Моля добавете коментар!')
      return
    }
    else {
      dispatch(startLoading())
      dispatch(updateSessionCompleted({
        payload: { _id: id, startedAt, endedAt, ...editData, comment: commentRef.current.text },
        onSuccess: () => {
          setEditCompletedSession(false)
          setEditData({})
          commentRef.current.setText("")
        }
      }))
    }
  }

  return (
    <div className={`session-container ${editCompletedSession && 'blur'}`}>
      <div className="session-inner-container row">
        <div className="map col">
          {!['in-action', 'completed'].includes(status) || isEmpty(data) ? (
            <div className="pending-session col">
              <div className="image" />
              <h2>{translate("sessionAppNotStarted")}</h2>
              <p>
                {isEmpty(data)
                  ? translate("sessionInstructorNotStarted")
                  : translate("sessionNoVisualization")}
              </p>
            </div>
          ) : (
            !isEmpty(data) && <MapBox data={data} status={status} />
          )}
        </div>
        <div className="session-content col">
          {editCompletedSession ? <div className="session-header">
            <div className="row">
              <h2>{translate("sessionEdit")}</h2>
              <div className="row row-buttons">
                <Button.Raised text="Откажи" className='cancel' onClick={() => {
                  setEditCompletedSession(false)
                  setEditData({})
                  commentRef.current.setText("")
                }} />
                <Button.Raised
                  text="Запази"
                  className="blue"
                  disabled={!editData?.startedAt && !editData?.endedAt}
                  onClick={handleUpdateSession}
                />
              </div>
            </div>
            <h3>{translate("sessionEditInnerText")}</h3>
          </div> : <div className="session-header row">
            <h2>{sessionTypes.find(({ value }) => value === type)?.label} кормуване</h2>
            {status === 'pending' && currentUser?.role?.map(({ permissions }) => permissions)?.includes('admin') && (
              <div className="icon icon-edit" onClick={() => history.push(`session_${id}/edit`)} />
            )}
            {status === 'completed' && currentUser?.role?.map(({ permissions }) => permissions)?.includes('admin') && (
              <div className="icon icon-edit" onClick={() => setEditCompletedSession(true)} />
            )}
            {status === "in-action" && (
              <>
                {isOnline ? <Tooltip
                  content={
                    <div className="battery-status-container">
                      <p className="title">{translate("sessionStatusOnline")}</p>
                      <p>
                        {translate("sessionInAction")}
                      </p>
                      <div className="row">
                        <div className="icon icon-battery" />
                        <p>{translate("sessionBattery")} {battery}%</p>
                      </div>
                    </div>
                  }
                >
                  <div className="is-online"><p>{translate("Online")}</p></div>
                </Tooltip> : <Tooltip
                  content={
                    <div className="battery-status-container">
                      <p className="title offline">{translate("sessionStatusOffline")}</p>
                      <p>
                        {translate("sessionLeftConnection")}
                      </p>
                      <div className="row">
                        <div className="icon icon-battery-red" />
                        <p>{translate("sessionBattery")}: {battery}%</p>
                      </div>
                    </div>
                  }
                >
                  <div className="is-online offline"><p>{translate("Offline")}</p></div>
                </Tooltip>}
              </>
            )}
            {status === 'completed' && (
              <div className="time-left">
                <p>{`${~~(((duration || 1) - 1) / 50) + 1} час${~~(duration / 50) === 0 ? '' : 'a'}`}</p>
              </div>
            )}
          </div>}
          <div className="session-data row">
            {[
              {
                label: translate("sessionLeft"),
                value: `${~~(training?.remainingMinutes / 50)} ч`,
              },
              {
                label: 'Начало:',
                value: startedAt || start ? moment(startedAt || start).format('HH:mm') : '—',
                input: "startedAt"
              },
              {
                label: status === 'completed' ? 'Край:' : 'Планиран до:',
                value: endedAt || end ? moment(endedAt || end).format('HH:mm') : '—',
                input: "endedAt"
              },
              {
                label: translate("sessionSpeed"),
                value: `${data ? data[data.length - 1]?.speed : '—'} км/ч`,
                status: ['in-action'],
              },
              {
                label: translate("sessionMedSpeed"),
                value: `${avgSpeed || '—'} км/ч`,
                status: ['pending', 'completed'],
              },
              {
                label: translate("sessionMaxSpeed"),
                value: `${data?.reduce((p, c) => (p.speed > c.speed ? p : c), 0)?.speed || '—'} км/ч`,
              },
              { label: 'Разстояние:', value: `${distance || '—'} км` },
              { label: 'Автомобил:', value: car?.model || '—' },
              { label: 'Инструктор:', value: user?.fullName || '—' },
              { label: 'Курсист:', value: student?.fullName || '—' },
              { label: 'Обучение:', value: training?.type?.name || '—' },
            ].map(({ label, value, status, input }, i) => {
              if (status && !status.includes(session.status)) return
              if (editCompletedSession && ['Начало:', 'Край:'].includes(label)) return (
                <div className="row" key={`session-data-${i}`}>
                  <p>{label}</p>
                  <DatePicker
                    className="input-timepicker"
                    selected={editData?.[input] || moment(session?.[input]).toDate()}
                    filterTime={(time) => {
                      if (input === 'endedAt') return moment(editData?.startedAt || session?.startedAt).isBefore(moment(time))
                      else if (input === 'startedAt') return moment(editData?.endedAt || session?.endedAt).isAfter(moment(time))
                      else return true
                    }}
                    onChange={(value) => setEditData({ ...editData, [input]: value })}
                    showTimeSelect
                    showTimeSelectOnly
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    timeIntervals={15}
                  />
                </div>
              )
              return (
                <div className="row" key={`session-data-${i}`}>
                  <p>
                    {label} <span>{value}</span>
                  </p>
                  {['Инструктор:'].includes(label) &&
                    currentUser?.role?.map(({ permissions }) => permissions)?.includes('admin') && (
                      <div className="icon icon-profile" onClick={() => history.push(`/instructor/${user?._id}`)} />
                    )}
                  {['Курсист:'].includes(label) && (
                    <div
                      className="icon icon-profile"
                      onClick={() => history.push(`/student/${student?._id}?training=${training?._id}`)}
                    />
                  )}
                </div>
              )
            })}
          </div>
          <div className={`session-legend row ${isEmpty(data) && 'blur'}`}>
            <h2>{translate("sessionLegend")}</h2>
            {[
              { label: '0-30', color: '#0BD84F', value: 0 },
              { label: '30-60', color: '#103CB5', value: 29 },
              { label: '60-90', color: '#FF5800', value: 59 },
              { label: '90-140', color: '#0D242E', value: 89 },
              { label: '>140', color: '#D814A9', value: 140 },
            ].map(({ label, color, value }, i) => (
              <div key={`range-${i}`} className={`range ${value > maxSpeed && 'blur'}`} style={{ borderColor: color }}>
                {label}
              </div>
            ))}
          </div>
          <div className="session-comments">
            <h2>{translate("sessionComment")} ({comments?.length ?? 0})</h2>
            <Inputs.Comments
              ref={commentRef}
              editCompletedSession={editCompletedSession}
              comments={comments} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Session
