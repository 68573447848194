import React from "react"
import { isString } from "lodash"
import "./styles.scss"

const SingleRow = ({ fields, row, renderField, fixedWidth, getWidth }) => {
  return (
    <div className="shared-list-single-row-container">
      <div className="shared-list-single-row-inner-container row">
        {fields?.map(({ label, size = 300, value }, i) => (
          <span
            key={`shared-list-field-${i}`}
            style={{
              width: `${fixedWidth ? 0 : size}px`,
              paddingLeft: `${15 + i * 1}px`,
            }}
            className={`row ${value} ${fixedWidth && getWidth(size)}`}
          >
            {renderField
              ? renderField(row, value)
              : isString(row?.[value])
              ? row?.[value]
              : "-"}
          </span>
        ))}
      </div>
    </div>
  )
}

export default SingleRow
