export const generalTypes = {
  START_LOADING: "general/START_LOADING",
  STOP_LOADING: "general/STOP_LOADING",
  SET_LOADING_TEXT: "general/SET_LOADING_TEXT",
  SET_MODAL: "general/SET_MODAL",
  SET_OVERLAY: "general/SET_OVERLAY",
  SET_GENERAL_FIELD: "general/SET_GENERAL_FIELD",
}

export const startLoading = () => ({
  type: generalTypes.START_LOADING,
})

export const stopLoading = () => ({
  type: generalTypes.STOP_LOADING,
})

export const setLoadingText = (payload) => ({
  type: generalTypes.SET_LOADING_TEXT,
  payload,
})

export const setModal = (payload) => ({
  type: generalTypes.SET_MODAL,
  payload,
})

export const setOverlay = (payload) => ({
  type: generalTypes.SET_OVERLAY,
  payload,
})


export const setGeneralField = (payload) => ({
  type: generalTypes.SET_GENERAL_FIELD,
  payload,
})
