export const balanceTypes = {
  GET_TRANSACTIONS: "balance/GET_TRANSACTIONS",
  GET_TRANSACTIONS_SUCCESS: "balance/GET_TRANSACTIONS_SUCCESS",
  GET_TRANSACTION: "balance/GET_TRANSACTION",
  GET_TRANSACTION_SUCCESS: "balance/GET_TRANSACTION_SUCCESS",
  ADD_TRANSACTION: "balance/ADD_TRANSACTION",
}

export const getTransactions = (payload) => ({
  type: balanceTypes.GET_TRANSACTIONS,
  ...payload,
})

export const getTransaction = (payload) => ({
  type: balanceTypes.GET_TRANSACTION,
  ...payload,
})

export const addTransaction = (payload) => ({
  type: balanceTypes.ADD_TRANSACTION,
  ...payload,
})
