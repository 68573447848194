import { ofType, ActionsObservable } from "redux-observable"
import { switchMap, catchError } from "rxjs/operators"
import { Api, Alerts } from "@makedonski/socourt-utilities"
import { balanceTypes, stopLoading } from "../actions"
import { URL } from "../config/settings"

export const getTransactions = (action$) =>
  action$.pipe(
    ofType(balanceTypes.GET_TRANSACTIONS),
    switchMap(({ payload, onSuccess }) =>
      Api.post(`${URL}/credit-history/list`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next(stopLoading())
            obs.next({
              type: balanceTypes.GET_TRANSACTIONS_SUCCESS,
              payload: response?.data,
            })
            if (onSuccess) onSuccess(response?.data)
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            obs.next(stopLoading())
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.complete()
          })
        )
      )
    )
  )

export const getTransaction = (action$) =>
  action$.pipe(
    ofType(balanceTypes.GET_TRANSACTION),
    switchMap(({ payload, onSuccess }) =>
      Api.get(`${URL}/credit-history/${payload}`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next(stopLoading())
            obs.next({
              type: balanceTypes.GET_TRANSACTION_SUCCESS,
              payload: response?.data,
            })
            if (onSuccess) onSuccess(response?.data)
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            obs.next(stopLoading())
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.complete()
          })
        )
      )
    )
  )

export const addTransaction = (action$) =>
  action$.pipe(
    ofType(balanceTypes.ADD_TRANSACTION),
    switchMap(({ payload, onSuccess }) =>
      Api.post(`${URL}/credit-history/`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next(stopLoading())
            obs.next({
              type: balanceTypes.GET_TRANSACTIONS,
              payload: response?.data,
            })
            if (onSuccess) onSuccess(response?.data)
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            obs.next(stopLoading())
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.complete()
          })
        )
      )
    )
  )
