import React, { useState, useEffect } from "react"
import { Button } from "@makedonski/admin-ui"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { getSchool, updateSchool } from "../../actions"
import { Popup, Courses } from "../../components"
import { translate } from "../../translations/localization"
import "./styles.scss"

const mapPermissions = { instructor: "Инструктор", admin: "Админ" }

const School = ({}) => {
  const history = useHistory()
  const [showDocuments, setShowDocuments] = useState(false)
  const [editData, setEditData] = useState({})

  const dispatch = useDispatch()
  const { school } = useSelector((state) => state)
  useEffect(() => {
    dispatch(getSchool())
  }, [])

  return (
    <div className="school-container row">
      <div className="school-inner-left-container col">
        <div className="school-form-container">
          <div className="row row-main">
            <div
              className="avatar-container-full row"
              style={{
                backgroundImage: `url(${
                  school?.school?.logo ||
                  require("../../assets/images/avatar.png").default
                })`,
              }}
            ></div>

            <div className="col col-big">
              <p className="header">{school?.school?.name || "—"}</p>
            </div>
            <div className="row row-buttons">
              <div
                className="icon icon-document"
                onClick={() => setShowDocuments(true)}
              />
              <div
                className="icon icon-edit"
                onClick={() => history.push("/school/edit")}
              />
            </div>
          </div>
          <div className="row row-data">
            <p>
              {translate("Phone")}: <span>{school?.phoneNumber || "—"}</span>
            </p>
            <p>
            {translate("email")}: <span>{school?.email || "—"}</span>
            </p>
            <p className="double">
            {translate("firmContact")}: <span>{school?.ownerName || "—"}</span>
            </p>
            <p className="double">
            {translate("firmAddress")}: <span>{school?.address || "—"}</span>
            </p>
          </div>
        </div>
        <Courses.List />
        <Popup.Documents
          showDocuments={showDocuments}
          setShowDocuments={(value) => setShowDocuments(value)}
          files={school?.files || []}
          handleChange={(files) =>
            dispatch(updateSchool({ _id: school?._id, files }))
          }
        />
      </div>
      <div className="school-inner-right-container">
        <div className="school-users-list-header row">
          <h2>{translate("Users")}</h2>
          <Button.Raised
            text="Покани"
            className="blue"
            onClick={() => history.push("/school/invite")}
          />
        </div>
        <div className="school-users-list-content">
          <div className="list-header row">
            <div className="medium">{translate("Names")}</div>
            <div className="medium">{translate("email")}</div>
            <div className="medium">{translate("Phone")}</div>
            <div className="medium">{translate("Role")}</div>
          </div>
          <div className="list-scroll">
            {school?.users
              ?.filter(
                ({ role }) =>
                  !role
                    .map(({ permissions }) => permissions)
                    .includes("student")
              )
              ?.map((user, i) => (
                <div key={`user-${i}`} className="single-user-container row">
                  <div className="medium row">
                    <div
                      className="icon icon-profile"
                      onClick={() => history.push(`/instructor/${user?._id}`)}
                    />
                    {user?.fullName || "—"}
                  </div>
                  <div className="medium ">{user?.email || "—"}</div>
                  <div className="medium ">{user?.phoneNumber || "—"}</div>
                  <div className="medium row">
                    {user?.role
                      ?.map(({ permissions }) => mapPermissions[permissions])
                      ?.join(", ") || "—"}
                    <div
                      className="icon icon-edit"
                      onClick={() => setEditData(user)}
                    />
                  </div>
                </div>
              ))}
          </div>
          <Popup.InstructorEdit
            setEditData={(value) => setEditData(value)}
            editData={editData}
          />
        </div>
      </div>
    </div>
  )
}

export default School
