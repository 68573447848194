import React, { useState, useEffect, useRef } from 'react'
import { Button, Input } from '@makedonski/admin-ui'
import useLocalStorage from 'use-local-storage'
import { isEmpty, pick, omit } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  startLoading,
  stopLoading,
  getFirms,
  createStudent,
  updateStudent,
  deleteStudent,
  createTraining,
} from '../../../actions'
import { Training } from '../../../components'
import { uploadFile, alertAddStudent } from '../../../utilities'
import { translate } from '../../../translations/localization'
import './styles.scss'

const studentFields = ['avatar', 'fullName', 'pin', 'firm', 'phoneNumber', 'email', 'comment']
const requiredField = ['fullName', 'pin', 'phoneNumber']

const Form = ({ isCreate, setEditable, student }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [data, setData] = useState(isCreate ? { phoneNumber: '+359' } : {})
  const handleChange = (payload) => setData({ ...data, ...payload })

  const uploadBtnRef = useRef()
  const handleImage = (files) => {
    if (isEmpty(files)) return
    dispatch(startLoading())
    uploadFile(files[0]).then((data) => {
      handleChange({ avatar: data?.location })
      dispatch(stopLoading())
    })
  }

  //prettier-ignore
  const { general: { currentUser }, firms: { firms } } = useSelector((state) => state)
  useEffect(() => {
    dispatch(getFirms())
    return () => setEditable(false)
  }, [])

  const [showRequired, setShowRequired] = useState(false)
  const isRequred = (field) => showRequired && !data?.[field] && !student?.[field] && 'required'
  const isRequredPhoneNumber = () => {
    const temp = data?.phoneNumber || student?.phoneNumber
    return showRequired && (!temp?.includes('+359') || temp.length !== 13) && 'required'
  }
  const validate = () => {
    if (isEmpty(data)) return false
    if (requiredField.some((field) => !data?.[field] && !student?.[field])) return false
    if (!(data?.phoneNumber || student?.phoneNumber)?.includes('+359')) return false
    if ((data?.phoneNumber || student?.phoneNumber)?.length !== 13) return false
    return true
  }

  const [alerts, setAlerts] = useLocalStorage('alerts', {})
  useEffect(() => {
    isCreate &&
      !alerts?.newStudent &&
      alertAddStudent(currentUser?.drivingSchool?.credits, () => setAlerts({ ...alerts, newStudent: true }))
  }, [alerts])

  return (
    <div className="student-form-container col">
      <div className="student-form-main-container">
        {!isCreate && <h2>{translate("studentHeader")}</h2>}
        <div className="row">
          {data?.avatar || student?.avatar ? (
            <div
              className="avatar-container-full row"
              style={{
                backgroundImage: `url(${
                  data?.avatar || student?.avatar || require('../../../assets/images/avatar.png').default
                })`,
              }}>
              <div className="icon icon-camera ripple" onClick={() => uploadBtnRef?.current?.click()} />
            </div>
          ) : (
            <Input.DragAndDrop handleDrop={(files) => handleImage(files)}>
              <div className="avatar-container row">
                <div className="icon icon-image ripple" onClick={() => uploadBtnRef?.current?.click()} />
              </div>
            </Input.DragAndDrop>
          )}
          <input
            type="file"
            ref={uploadBtnRef}
            className="btn-upload"
            onChange={({ target: { files } }) => handleImage(files)}
            style={{ display: 'none' }}
          />
          <div className="col col-big">
            <p className="no-bg input-label">
              {translate("headerStudent")} <span className="icon icon-required" />
            </p>
            <Input.Text
              value={data?.fullName ?? student?.fullName ?? ''}
              onChange={({ target: { value } }) => handleChange({ fullName: value })}
              inputClassName={`${isRequred('fullName')}`}
            />
          </div>
          <div className="col">
            <p className="input-label">
              {translate("EGN")}
              <span className="icon icon-required" />
            </p>
            <Input.Text
              value={data?.pin ?? student?.pin ?? ''}
              onChange={({ target: { value } }) => {
                if (!/^[0-9]{0,10}$/.test(value)) return
                handleChange({ pin: value })
              }}
              inputClassName={`${isRequred('pin')}`}
            />
          </div>
          <div className="col">
            <p>{translate("Firm")}</p>
            <Input.Dropdown
              options={firms?.map(({ _id, name }) => ({
                value: _id,
                label: name,
              }))}
              value={data?.firm ?? student?.firm?._id ?? ''}
              onChange={({ value }) => handleChange({ firm: value })}
            />
          </div>
          <div className="col">
            <p className="input-label">
              {translate("Phone")} <span className="icon icon-required" />
            </p>
            <Input.Text
              value={data?.phoneNumber ?? student?.phoneNumber ?? ''}
              onChange={({ target: { value } }) => {
                if (value.length < 4 || value.length > 13) return
                if (!/^[+]?$|(^[+]{1}[0-9]{1,12}$)/.test(value)) return
                handleChange({ phoneNumber: value })
              }}
              inputClassName={`${isRequredPhoneNumber()}`}
            />
          </div>

          <div className="col">
            <p>{translate("Email")}</p>
            <Input.Text
              value={data?.email ?? student?.email ?? ''}
              onChange={({ target: { value } }) => handleChange({ email: value })}
            />
          </div>
        </div>
      </div>
      {isCreate && data && <Training.Form data={data} showNote handleChange={handleChange} showRequired={showRequired} />}
      <div className="row row-buttons">
        {!isCreate && (
          <Button.Raised
            text={translate("Delete")}
            className="red"
            onClick={() => {
              dispatch(startLoading())
              dispatch(deleteStudent({ _id: student?._id }))
            }}
          />
        )}
        <Button.Raised
          text={isCreate ? translate("Add") : translate("Save")}
          className="blue"
          onClick={() => {
            if (!validate() || (isCreate && !data?.type)) {
              setShowRequired(true)
              return
            }
            dispatch(startLoading())
            if (isCreate)
              dispatch(
                createStudent({
                  payload: pick(data, studentFields),
                  onSuccess: ({ _id }) =>
                    dispatch(
                      createTraining({
                        payload: {
                          ...omit(data, [...studentFields, 'practiceProgress']),
                          student: _id,
                          remainingMinutes: data?.practiceProgress
                            ? ((data?.totalHours ?? student?.totalHours ?? 31) - data?.practiceProgress) * 50
                            : undefined,
                        },
                        onSuccess: ({ _id, student }) => history.push(`/student/${student}?training=${_id}`),
                      })
                    ),
                })
              )
            else {
              dispatch(updateStudent({ _id: student?._id, ...data }))
              setEditable(false)
            }
          }}
        />
      </div>
    </div>
  )
}

export default Form
