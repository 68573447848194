import moment from "moment"

export const scale = (num, in_min, in_max, out_min, out_max) =>
  ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min

export const combineDateAndTime = (date = new Date(), time = new Date()) => {
  const dateString = moment(date).format("DD.MM.YYYY")
  const timeString = moment(time).format("HH:mm")
  return moment(`${dateString} ${timeString}`, "DD.MM.YYYY HH:mm").toDate()
}

export const getRouteColor = (speed) => {
  if (speed > 140) return "#D814A9"
  else if (speed > 89) return "#0D242E"
  else if (speed > 59) return "#FF5800"
  else if (speed > 29) return "#103CB5"
  else return "#0BD84F"
}