import React, { useState, useEffect, useMemo } from 'react'
import Tippy from '@tippyjs/react/headless'
import { Button, Input } from '@makedonski/admin-ui'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import {
  getGroup,
  clearGroups,
  createGroup,
  updateGroup,
  deleteGroup,
  getInstructors,
  getCourses,
  startLoading,
} from '../../actions'
import { Popup, Inputs } from '../../components'
import { translate } from '../../translations/localization'
import './styles.scss'

const Group = ({}) => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const {
    groups: { group },
    instructors: { instructors },
    courses: { courses },
  } = useSelector((state) => state)

  const [data, setData] = useState({})
  const handleChange = (field, value) => setData({ ...data, [field]: value })
  useEffect(() => {
    dispatch(getInstructors())
    dispatch(getCourses())
    id !== 'create' && dispatch(getGroup(id))
    return () => dispatch(clearGroups())
  }, [])

  const selectedInstructors = useMemo(
    () => (data?.instructors ? data?.instructors : group?.instructors?.map(({ _id }) => _id)),
    [data.instructors, group]
  )

  const [showRequired, setShowRequired] = useState(false)
  //prettier-ignore
  const isRequred = (field) => showRequired && !data?.[field] && !group?.[field] && "required"
  //prettier-ignore
  const validate = () => !["name", "year", "start", "end", "course"].some((field) => !data?.[field] && !group?.[field]) && !isEmpty(data?.instructors || group?.instructors)

  const [showPopup, setShowPopup] = useState(false)

  return (
    <div className="screen-group-container">
      <h2>{translate("groupAdd")}</h2>
      <div className="row">
        <div className="col">
          <span className="input-label">
          {translate("groupName")} <span className="icon icon-required" />
          </span>
          <Input.Text
            value={data?.name ?? group?.name ?? ''}
            onChange={({ target: { value } }) => handleChange('name', value)}
            inputClassName={`${isRequred('name')}`}
          />
        </div>
        <div className="col col-medium">
          <span className="input-label">
          {translate("groupEducation")} <span className="icon icon-required" />
          </span>
          <Inputs.Dropdown
            options={courses?.map(({ name, _id }) => ({
              label: name,
              value: _id,
            }))}
            value={data?.course ?? group?.course?._id ?? ''}
            onChange={({ value }) => handleChange('course', value)}
            className={`${isRequred('course')}`}
          />
        </div>
        <div className="col">
          <span className="input-label">
          {translate("Year")} <span className="icon icon-required" />
          </span>
          <Input.Text
            value={data?.year ?? group?.year ?? ''}
            onChange={({ target: { value } }) => handleChange('year', value)}
            inputClassName={`${isRequred('year')}`}
          />
        </div>
        <div className="col">
          <span className="input-label">
          {translate("groupStart")} <span className="icon icon-required" />
          </span>
          <Input.Datepicker
            pickDate={data?.start || (group?.start ? moment(group?.start).toDate() : null)}
            maxDate={data?.end || null}
            onChange={(start) => handleChange('start', start)}
            className={`${isRequred('start')}`}
          />
        </div>
        <div className="col">
          <span className="input-label">
          {translate("groupEnd")} <span className="icon icon-required" />
          </span>
          <Input.Datepicker
            pickDate={data?.end || (group?.end ? moment(group?.end).toDate() : null)}
            minDate={data?.start || null}
            onChange={(date) => handleChange('end', date)}
            className={`${isRequred('end')}`}
          />
        </div>
      </div>
      <div className="row">
        <div className="col col-big">
          <div className="row row-no-margin">
            <div className="col">
              <span className="input-label">
              {translate("Instructors")} <span className="icon icon-required" />
              </span>
              <Tippy
                placement="auto-end"
                interactive
                animation
                onHide={(instance) => requestAnimationFrame(instance.unmount)}
                visible={showPopup}
                onClickOutside={() => setShowPopup(false)}
                render={(attrs) => (
                  <Popup.InstructorsAdd
                    {...attrs}
                    hide={() => setShowPopup(false)}
                    handleApply={(value) => handleChange('instructors', value)}
                    selected={selectedInstructors}
                    options={instructors}
                  />
                )}>
                <span tabIndex="0">
                  <Button.Raised
                    text="Добави"
                    className={`blue ${showRequired && isEmpty(data?.instructors) && 'required'}`}
                    onClick={() => setShowPopup(!showPopup)}
                  />
                </span>
              </Tippy>
            </div>
            <div className="row row-instructors row-no-margin">
              {selectedInstructors?.map((_id, i) => {
                return (
                  <div key={`instructor-${_id}`} className="single-instructor row row-no-margin">
                    <Button.Icon
                      name="plus"
                      color="#ed1d5d"
                      onClick={() => {
                        const newInstructors = selectedInstructors?.slice()
                        newInstructors.splice(i, 1)
                        handleChange('instructors', newInstructors)
                      }}
                    />
                    <p>{instructors?.find((inst) => inst._id === _id)?.fullName}</p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className="row row-no-margin row-buttons">
          <>
            {id !== 'create' && (
              <Button.Raised
                text="Изтрий"
                className="red"
                onClick={() => {
                  dispatch(startLoading())
                  dispatch(deleteGroup({ _id: id }))
                }}
              />
            )}
            <Button.Raised
              text={id === 'create' ? 'Добави' : 'Запази'}
              className="blue"
              onClick={() => {
                if (!validate()) {
                  setShowRequired(true)
                  return
                }
                dispatch(startLoading())
                if (id === 'create') dispatch(createGroup({ instructors: [], ...data }))
                else dispatch(updateGroup({ _id: id, ...data }))
              }}
            />
          </>
        </div>
      </div>
    </div>
  )
}

export default Group
