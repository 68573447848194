import { ofType, ActionsObservable } from "redux-observable"
import { switchMap, catchError } from "rxjs/operators"
import { Api, Alerts } from "@makedonski/socourt-utilities"
import { schoolTypes, stopLoading } from "../actions"
import { URL } from "../config/settings"

export const getSchool = (action$) =>
  action$.pipe(
    ofType(schoolTypes.GET_SCHOOL),
    switchMap(() =>
      Api.get(`${URL}/driving-schools`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: schoolTypes.GET_SCHOOL_SUCCESS,
              payload: response.school,
            })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const updateSchool = (action$) =>
  action$.pipe(
    ofType(schoolTypes.UPDATE_SCHOOL),
    switchMap(({ payload }) =>
      Api.put(`${URL}/driving-schools`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({ type: schoolTypes.GET_SCHOOL })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error("Грешка!", err?.response?.error?.message || "")
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )
