import React, { useState, useEffect, useRef } from 'react'
import { Button, Input } from '@makedonski/admin-ui'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'
import { getSchool, updateSchool, startLoading, stopLoading } from '../../actions'
import { uploadFile } from '../../utilities'
import { translate } from '../../translations/localization'
import './styles.scss'

const SchoolEdit = ({}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { school } = useSelector((state) => state)
  useEffect(() => {
    dispatch(getSchool())
  }, [])

  const [data, setData] = useState({})
  const handleChange = (payload) => setData({ ...data, ...payload })

  const uploadBtnRef = useRef()
  const handleImage = (files) => {
    if (isEmpty(files)) return
    dispatch(startLoading())
    uploadFile(files[0]).then((data) => {
      handleChange({ logo: data?.location })
      dispatch(stopLoading())
    })
  }
  return (
    <div className="screen-school-edit-container">
      <h2>{translate("schoolData")}</h2>
      <div className="row">
        {data?.logo || school?.school?.logo ? (
          <div
            className="avatar-container-full row"
            style={{ backgroundImage: `url(${data?.logo || school?.school?.logo || require('../../assets/images/avatar.png').default})` }}>
            <div className="icon icon-camera ripple" onClick={() => uploadBtnRef?.current?.click()} />
          </div>
        ) : (
          <Input.DragAndDrop handleDrop={(files) => handleImage(files)}>
            <div className="avatar-container row">
              <div className="icon icon-image ripple" onClick={() => uploadBtnRef?.current?.click()} />
            </div>
          </Input.DragAndDrop>
        )}
        <input
          type="file"
          ref={uploadBtnRef}
          className="btn-upload"
          onChange={({ target: { files } }) => handleImage(files)}
          style={{ display: 'none' }}
        />
        <div className="col col-big">
          <span>{translate("schoolName")}</span>
          <Input.Text
            value={data?.school?.name || school?.school?.name}
            onChange={({ target: { value } }) => handleChange({ name: value })}
          />
        </div>
        <div className="col">
          <span>{translate("firmEIK")}</span>
          <Input.Text
            value={data?.eic || school?.eic}
            onChange={({ target: { value } }) => handleChange({ eic: value })}
          />
        </div>
        <div className="col col-big">
          <span>{translate("firmMol")}</span>
          <Input.Text
            value={data?.mol || school?.mol}
            onChange={({ target: { value } }) => handleChange({ mol: value })}
          />
        </div>
        <div className="col col-big">
          <span>{translate("firmAddress")}</span>
          <Input.Text
            value={data?.address || school?.address}
            onChange={({ target: { value } }) => handleChange({ address: value })}
          />
        </div>
      </div>
      <div className="row">
        <div className="col col-big">
          <span>{translate("firmContact")}</span>
          <Input.Text
            value={data?.ownerName || school?.ownerName}
            onChange={({ target: { value } }) => handleChange({ ownerName: value })}
          />
        </div>
        <div className="col">
          <span>{translate("Phone")}</span>
          <Input.Text
            value={data?.phoneNumber || school?.phoneNumber}
            onChange={({ target: { value } }) => handleChange({ phoneNumber: value })}
          />
        </div>
        <div className="col col-big">
          <span>{translate("Email")}</span>
          <Input.Text
            value={data?.email || school?.email}
            onChange={({ target: { value } }) => handleChange({ email: value })}
          />
        </div>
      </div>
      <div className="row row-no-margin row-buttons">
        <Button.Raised
          className="blue"
          text="Запази"
          onClick={() => {
            dispatch(startLoading())
            dispatch(updateSchool({ _id: school, ...data }))
            history.goBack()
          }}
        />
      </div>
    </div>
  )
}

export default SchoolEdit
