import React from "react"
import { Support, useSupportSocket } from '@fidweb/customer-support'
import { useDispatch, useSelector } from "react-redux"
import { updateInstructor } from "actions"
import { SUPPORT_URL } from "config/settings"
import "./styles.scss"

const SupportScreen = ({ }) => {
    const dispatch = useDispatch()
    const { currentUser } = useSelector(({ general }) => general)

    const socket = useSupportSocket({
        URL: SUPPORT_URL,
        platform: 'digidrive',
        currentUser: currentUser,
        handleNewUser: (data) => dispatch(updateInstructor({ data, isCurrent: true }))
    })

    return <div className="screen-customer-support-container row">
        {currentUser && socket && <Support
            {...socket}
            currentUser={currentUser}
            logo={require('../../assets/images/logo-small.png')}
            colors={{
                '--main-color': '#0bd8de',
                '--secondary-color': '#103cb5',
                '--light-color': '#f8f8f8'
            }}
        />}
    </div>
}

export default SupportScreen

