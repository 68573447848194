import React, { useState, useEffect, useMemo } from "react"
import { Button } from "@makedonski/admin-ui"
import { useDispatch, useSelector } from "react-redux"
import {
  getCourses,
  getCoursesTypes,
  updateCourses,
  createCourse,
} from "../../actions"
import { Inputs } from "../../components"
import { translate } from "../../translations/localization"
import "./styles.scss"

const Courses = ({}) => {
  const dispatch = useDispatch()
  const { courses, types } = useSelector((state) => state.courses)
  useEffect(() => {
    dispatch(getCoursesTypes())
  }, [])
  useEffect(() => {
    dispatch(getCourses())
  }, [types])

  //prettier-ignore
  const getCurrent = useMemo(() => courses.reduce((acc, { type }) => ({ ...acc, [type?._id]: true }), {}), [courses])
  const [data, setData] = useState(getCurrent)
  useEffect(() => {
    setData(getCurrent)
  }, [courses])

  const [isCreate, setIsCreate] = useState(false)

  return (
    <div className="screen-courses-container row">
      <div className="screen-courses-inner-container col">
        <h2>{translate("coursesChooseEducation")}</h2>
        <p>{translate("coursesChooseEducationInnerText")}</p>

        <div className="scroll-container">
          {types?.map((type) => (
            <div key={`type-${type?._id}`} className="single-type row">
              <span>{type?.name}</span>
              <Button.Switch
                isOn={data[type?._id]}
                onChange={() =>
                  setData({ ...data, [type?._id]: !data?.[type?._id] })
                }
              />
            </div>
          ))}
          <Inputs.TextEdit
            handleChange={(name) => dispatch(createCourse({ name }))}
            placeholder="Име на обучението"
          >
            <div className="add-new-container">{translate("coursesAddNewEducation")}</div>
          </Inputs.TextEdit>
        </div>

        <Button.Raised
          text={translate("Save")}
          onClick={() => dispatch(updateCourses({ types: data }))}
        />
      </div>
    </div>
  )
}

export default Courses
