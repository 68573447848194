import React, { useState, useEffect } from 'react'
import { Input, Button } from '@makedonski/admin-ui'
import { isEmpty, isNumber } from 'lodash'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getRoles, inviteInstructors } from '../../actions'
import { Inputs } from '../../components'
import { translate } from '../../translations/localization'
import './styles.scss'

const SchoolInvite = ({ }) => {
  const history = useHistory()
  const [showNew, setShowNew] = useState(false)
  const [editIndex, setEditIndix] = useState(false)

  const initialData = { phoneNumber: '+359' }
  const [data, setData] = useState(initialData)
  const handleChange = (field, value) => setData({ ...data, [field]: value })
  const [invites, setInvites] = useState([])

  const isValid = () => !['firstName', 'lastName', 'email', 'phoneNumber', 'role'].some((field) => !data?.[field])

  const dispatch = useDispatch()
  const [roles, setRoles] = useState([])
  useEffect(() => {
    dispatch(getRoles((value) => setRoles(value)))
  }, [])

  return (
    <div className="school-invite-container row">
      <div className="school-invite-inner-container col">
        <h2>{translate("schoolInviteUser")}</h2>
        <p>{translate("schoolInviteUserInnerText")}</p>
        <div className={`invitation-form ${!isEmpty(invites) && !showNew && 'collapse'}`}>
          {isEmpty(invites) || showNew ? (
            <>
              <span>{translate("User")}</span>
              <div className="form-container">
                <div className="row row-name">
                  <div className="col">
                    <span>{translate("Name")}</span>
                    <Input.Text
                      value={data?.firstName || ''}
                      onChange={({ target: { value } }) => handleChange('firstName', value)}
                    />
                  </div>
                  <div className="col">
                    <span>{translate("lastName")}</span>
                    <Input.Text
                      value={data?.lastName || ''}
                      onChange={({ target: { value } }) => handleChange('lastName', value)}
                    />
                  </div>
                </div>

                <span>{translate("Email")}</span>
                <Input.Text
                  value={data?.email || ''}
                  onChange={({ target: { value } }) => handleChange('email', value)}
                />
                <span>{translate("Phone")}</span>
                <Input.Text
                  value={data?.phoneNumber || ''}
                  onChange={({ target: { value } }) => {
                    if (value.length < 4 || value.length > 13) return
                    if (!/^[+]?$|(^[+]{1}[0-9]{1,12}$)/.test(value)) return
                    handleChange('phoneNumber', value)
                  }}
                />
                <span>{translate("Role")}</span>
                <Inputs.CheckBoxes
                  buttons={roles
                    ?.filter(({ permissions }) => ['admin', 'instructor'].includes(permissions))
                    ?.map(({ name, _id }) => ({
                      label: name,
                      value: _id,
                    }))}
                  value={data?.role}
                  onClick={(value) => handleChange('role', value)}
                />
                <div className="row">
                  {isNumber(editIndex) && (
                    <p
                      className="btn-delete"
                      onClick={() => {
                        const newInvites = invites.slice()
                        newInvites.splice(editIndex, 1)
                        setInvites(newInvites)

                        setData(initialData)
                        setShowNew(false)
                        setEditIndix(false)
                      }}>
                      {translate("Delete")}
                    </p>
                  )}
                  <p
                    className={`btn-apply ${isValid() && 'active'}`}
                    onClick={() => {
                      if (isValid()) {
                        if (isNumber(editIndex)) {
                          const newInvites = invites.slice()
                          newInvites.splice(editIndex, 1, data)
                          setInvites(newInvites)
                        } else setInvites([...(invites || []), data])

                        setData(initialData)
                        setShowNew(false)
                        setEditIndix(false)
                      }
                    }}>
                    {translate("Save")}
                  </p>
                </div>
              </div>
            </>
          ) : (
            <h3 className="add-session" onClick={() => setShowNew(true)}>
              {translate("Add")}
            </h3>
          )}
        </div>
        <div className="invitations-list">
          {invites?.map((invite, i) => (
            <div key={`invitation-${i}`} className="single-invitation-container row">
              <p>
                {translate("Instructor")}: <span>{[invite?.firstName, invite?.lastName]?.join(' ')}</span>
              </p>
              <div
                className="icon icon-edit"
                onClick={() => {
                  setShowNew(true)
                  setEditIndix(i)
                  setData(invite)
                }}
              />
            </div>
          ))}
        </div>
        <Button.Raised
          text="Завърши"
          disabled={isEmpty(invites)}
          onClick={() => dispatch(inviteInstructors(invites))}
        />
      </div>
    </div>
  )
}

export default SchoolInvite
