import React, { useRef } from "react"
import { Button } from "@makedonski/admin-ui"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import { isEmpty } from "lodash"
import { startLoading, stopLoading } from "../../../actions"
import { uploadFile } from "../../../utilities"
import "./styles.scss"
import { translate } from "../../../translations/localization"

const Documents = ({ files, handleChange }) => {
  const uploadBtnRef = useRef()
  const { currentUser } = useSelector((state) => state.general)

  const dispatch = useDispatch()
  const handleAdd = (filesToUpload) => {
    if (isEmpty(filesToUpload)) return
    dispatch(startLoading())
    uploadFile(filesToUpload[0]).then((data) => {
      handleChange &&
        handleChange([
          {
            url: data?.location,
            name: filesToUpload[0]?.name,
            uploadedBy: currentUser?._id,
          },
          ...files,
        ])
      dispatch(stopLoading())
    })
  }

  return (
    <div className="shared-documents-container">
      <div className="documents-content">
        {!isEmpty(files) && (
          <div className="documents-content-header row">
            <span className="big">{translate("fileName")}</span>
            <span className="big">{translate("Add")}</span>
            <span className="small"></span>
          </div>
        )}
        <div className="documents-content-files">
          {files?.map((file, i) => (
            <div className="file-container row" key={`document-${i}`}>
              <span className="big">{file?.name || "—"}</span>
              <span className="big">
                {(file?.date && moment(file?.date).format("DD.MM.YYY")) || "—"}{" "}
                | {file?.uploadedBy?.fullName || "—"}
              </span>
              <span className="small row">
                <a
                  href={file?.url || ""}
                  target="_blank"
                  referrerPolicy="no-referrer"
                >
                  <div className="icon icon-download-custom" />
                </a>
                <div
                  className="icon icon-delete"
                  onClick={() => {
                    const newFiles = files.slice()
                    newFiles.splice(i, 1)
                    handleChange(newFiles)
                  }}
                />
              </span>
            </div>
          ))}
        </div>
      </div>
      <Button.Raised
        className="blue"
        text="Добави файлове"
        onClick={() => uploadBtnRef?.current?.click()}
      />
      <input
        type="file"
        ref={uploadBtnRef}
        className="btn-upload"
        onChange={({ target: { files } }) => handleAdd(files)}
        style={{ display: "none" }}
        accept="*"
      />
    </div>
  )
}

export default Documents
