import moment from 'moment'
import { store, history } from '../../config/store'
import { translate } from "../../translations/localization"

export const students = (student, value) => {
  const { currentUser } = store.getState().general
  // if ([null, undefined, ""].includes(student[value])) return "-"
  switch (value) {
    case 'remainingMinutes':
      return <span>
        {student.totalHours - ~~(student?.remainingMinutes / 50)}/{student.totalHours} часа
        {student?.remainingMinutes === 0 && student.status !== 'finished' && <span style={{ color: '#0bd8de', fontWeight: 700, marginLeft: 15 }}>МОЛЯ ОДОБРЕТЕ</span>}
      </span>
    case 'fullName':
      return (
        <div
          className="row row-student"
          style={{ cursor: 'pointer' }}
          onClick={() => history.push(`/student/${student?._id}?training=${student?.training}`)}>
          {student?.[value]}
          <div
            style={{ margin: '0 20px 0 auto' }}
            className={`icon ${student?.instructorPractice?.deletedAt || student?.instructorTheory?.deletedAt
              ? 'icon-profile-red'
              : 'icon-profile'
              }`}
          />
        </div>
      )
    case 'type':
      return student?.type?.name
    case 'theoryStart':
      return student?.theoryStart && student?.practiceEnd
        ? `${moment(student?.theoryStart).format('DD.MM')} - ${moment(student?.practiceEnd).format('DD.MM')}`
        : '-'
    case 'instructorTheory':
      return student?.instructorTheory?.deletedAt ? (
        <div style={{ color: '#ed1d5d' }}>{translate("pleaseAdd")}</div>
      ) : (
        student?.[value]?.fullName || '-'
      )
    case 'theoryGroup':
    case 'firm':
      return student?.[value]?.name || '-'
    case 'instructorPractice':
      return student?.instructorPractice?.deletedAt ? (
        <div style={{ color: '#ed1d5d' }}>{translate("pleaseAdd")}</div>
      ) : student?.[value]?.fullName ? (
        <div
          className="row row-student"
          style={{ cursor: 'pointer' }}
          onClick={() => history.push(`/instructor/${student?.[value]?._id}`)}>
          {student?.[value]?.fullName}
          {currentUser?.role?.map(({ permissions }) => permissions)?.includes('admin') && (
            <div style={{ margin: '0 20px 0 auto' }} className="icon icon-profile" />
          )}
        </div>
      ) : (
        '-'
      )
    case 'defaultCar':
      return student?.[value]?.license || '-'
    default:
      return student?.[value] || '-'
  }
}

export const instructors = (instructor, value) => {
  if ([null, ''].includes(instructor[value])) return '-'
  switch (value) {
    // case "theory":
    // case "practice":
    // case "adr":
    // case "cpo":
    // case "theory41":
    // case "practice41":
    // case "points":
    //   return instructor?.details?.qualifications?.[value] ? "Да" : "Не"
    case 'isActiveUser':
      return instructor[value] ? translate("Yes") : translate("No")
    case 'defaultCar':
      return instructor?.details?.defaultCar?.license
    case 'employeeFrom':
      return instructor?.details?.employeeFrom ? moment(instructor?.details?.employeeFrom).format('DD.MM.YYYY') : '-'
    case 'fullName':
      return (
        <div className="row row-fullName">
          {instructor?.[value]}
          <div
            onClick={() => history.push(`/instructor/${instructor?._id}`)}
            style={{ margin: '0 20px 0 auto' }}
            className={`icon icon-profile`}
          />
        </div>
      )

    default:
      return instructor?.[value] || '-'
  }
}

export const vehicles = (vehicle, value) => {
  if ([null, undefined, ''].includes(vehicle[value])) return '-'
  switch (value) {
    case 'instructors':
      return (
        <div className="row row-instructors">
          {vehicle?.[value]?.map(({ user }) => user?.fullName)?.join(', ')}
          <div
            onClick={() => history.push(`/vehicle/${vehicle?._id}`)}
            style={{ margin: '0 20px 0 auto' }}
            className={`icon icon-edit`}
          />
        </div>
      )

    default:
      return vehicle?.[value] || '-'
  }
}

export const groups = (group, value) => {
  if ([null, ''].includes(group[value])) return '-'
  switch (value) {
    case 'start':
    case 'end':
      return group[value] ? moment(group[value]).format('DD.MM.YYYY') : '-'
    case 'course':
      return group[value]?.name
    case 'instructors':
      return (
        <div className="row row-instructors">
          <div className="row row-names">{group?.[value]?.map(({ fullName }) => fullName)?.join(', ')}</div>
          <div
            onClick={() => history.push(`/group/${group?._id}`)}
            style={{ margin: '0 20px 0 auto' }}
            className={`icon icon-edit`}
          />
        </div>
      )
    default:
      return group?.[value] || '-'
  }
}

export const firms = (firm, value) => {
  if ([null, ''].includes(firm[value])) return '-'
  switch (value) {
    case 'activeStudents':
      return firm?.students?.reduce((acc, { remainingMinutes }) => (remainingMinutes ? acc + 1 : 0), 0)
    case 'totalStudents':
      return (
        <div className="row row-totalStudents">
          {firm?.students?.length}
          <div
            onClick={() => history.push(`/firm/${firm?._id}`)}
            style={{ margin: '0 20px 0 auto' }}
            className={`icon icon-edit`}
          />
        </div>
      )
    default:
      return firm?.[value] || '-'
  }
}
