import moment from 'moment'
import { history } from '../../config/store'
import { translate } from "../../translations/localization"

export const newStudents = (training, value) => {
  // if ([null, undefined, ""].includes(student[value])) return "-"
  switch (value) {
    case 'pin':
      return training?.student?.[value] || '-'
    case 'firm':
      return training?.student?.[value]?.name || '-'
    case 'remainingMinutes':
      return `${~~(training?.remainingMinutes / 50)} ${translate("Hours")}`
    case 'fullName':
      return (
        <div
          className="row row-student"
          style={{ cursor: 'pointer' }}
          onClick={() => history.push(`/student/${training?.student?._id}?training=${training?._id}`)}>
          {training?.student?.[value]}
          <div style={{ margin: '0 20px 0 auto' }} className={`icon icon-profile`} />
        </div>
      )
    case 'theoryStart':
      return training?.theoryStart && training?.practiceEnd
        ? `${moment(training?.theoryStart).format('DD.MM')} - ${moment(training?.practiceEnd).format('DD.MM')}`
        : '-'
    case 'instructorTheory':
      return training?.instructorTheory?.deletedAt ? (
        <div style={{ color: '#ed1d5d' }}>{translate("pleaseAdd")}</div>
      ) : (
        training?.[value]?.fullName || '-'
      )
    case 'instructorPractice':
      return training?.instructorPractice?.deletedAt ? (
        <div style={{ color: '#ed1d5d' }}>{translate("pleaseAdd")}</div>
      ) : (
        <div
          className="row row-student"
          style={{ cursor: 'pointer' }}
          onClick={() => history.push(`/instructor/${training?.[value]?._id}`)}>
          {training?.[value]?.fullName}
          <div style={{ margin: '0 20px 0 auto' }} className="icon icon-profile" />
        </div>
      )
    case 'defaultCar':
      return training?.[value]?.license
    case 'coursePrice':
      return (training?.coursePrice || 0) - (training?.paidPrice || 0)
    case 'theoryGroup':
    case 'type':
      return training?.[value]?.name || '-'
    default:
      return training?.[value] || '-'
  }
}
